/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { isPhone } from '../../components/common/devices';

import RequestWithdrawWeb from './Request-withdrawWeb';
import WithdrawMobile from './Request-withdrawMobile';
import Navbar from '../../components/header/Navbar';

function Withdraw({ navigation }: any) {
  return (
    <>
      {!isPhone() ? (
        <WithdrawMobile />
      ) : (
        <>
          <Navbar navigation={navigation} />
          <RequestWithdrawWeb navigation={navigation} />
        </>
      )}
    </>
  );
}

export default Withdraw;
