/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import LoanMobile from './LoanMobile';
import LoanWeb from './LoanWeb';
import Navbar from '../../components/header/Navbar';
import { isPhone } from '../../components/common/devices';

export default function Loan({ navigation }: any) {
  return (
    <>
      {!isPhone() ? (
        <LoanMobile navigation={navigation} />
      ) : (
        <>
          <Navbar navigation={navigation} />

          <LoanWeb navigation={navigation} />
        </>
      )}
    </>
  );
}
