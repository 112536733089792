import { StyleSheet, Text, View, Platform, ImageBackground, Image, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Button, Card, DataTable, Title } from 'react-native-paper';

import { AccountService } from '../../components/common/account.service';
import moment from 'moment';
import { numberWithCommas } from '../../components/common/common';
import Row, { Col } from '../../components/Row';
import Navbar from '../../components/header/Navbar';
import { Provider } from 'react-redux';

import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import Footer from '../../components/footer/footer';
import { store } from '../../redux';
import SideMenu from '../../components/SideMenu/SideMenu';

function ShareAccountDetails({ navigation, route }: any) {
    const accountService = new (AccountService as any)();
    const { accountNo } = route.params;

    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [mode, setMode] = useState('transaction overview');


    const getShareAccountDetails = async () => {
        setLoader(true);
        await accountService.getShareDetails(accountNo).then(async (res: any) => {
            setLoader(false);
            const response = res.data;
            setData(response);
        });
    }

    useEffect(() => {
        getShareAccountDetails();
    }, []);

    return (
        <>
            <View>
                <Navbar navigation={navigation} />
            </View>
            <View style={[DashboardStyles.web]}>
                <Row>

                    <Col customStyles={{ flex: 1.2, alignItems: 'center' }}>
                        <Provider store={store}>
                            <SideMenu navigation={navigation} style={{ height: '30px' }} />
                        </Provider>
                    </Col>

                    <Col customStyles={{ flex: 4, paddingTop: '10px' }}>
                        <ImageBackground
                            source={require('../../assets/kb-blue-background.png')}
                            style={[
                                {
                                    flex: 1,
                                    width: 200,
                                    marginTop: 10,
                                    paddingVertical: 100,
                                    height: '90%',
                                    position: 'absolute',
                                    right: 0,
                                },
                            ]}
                        />
                        {loader ? (
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                                <ActivityIndicator size="large" color="blue" />
                            </View>
                        ) : (
                            <View style={{ flexDirection: 'column', flex: 2.3 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Card style={[styles.card, { paddingLeft: 20, height: '200px' }]}>
                                        <Card.Content>
                                            <Row>
                                                <Col customStyles={{ flex: 1 }}>
                                                    <View style={{ width: '90%' }}>
                                                        <Row>
                                                            <Col customStyles={{ flex: 1 }}>
                                                                <Image
                                                                    style={[styles.dashboardIcons]}
                                                                    source={require('../../assets/total_savings.png')}
                                                                />
                                                            </Col>

                                                            <Col customStyles={{ flex: 2 }}>
                                                                <View style={{ marginLeft: '6%' }}>
                                                                    <Title style={[styles.savingsText]}>Total Approved Shares</Title>
                                                                    <Text
                                                                        style={[
                                                                            styles.currencyText,
                                                                            { color: '#5eb158', marginTop: '6px', marginBottom: '6px' },
                                                                        ]}
                                                                    >
                                                                        {numberWithCommas((data as any)?.summary?.totalApprovedShares || 0)}
                                                                    </Text>
                                                                    <Text style={styles.versionSubtitle}>Balance</Text>
                                                                </View>
                                                            </Col>
                                                            <Col>
                                                                <View style={[styles.verticalDivider]} />
                                                            </Col>
                                                        </Row>
                                                    </View>
                                                </Col>
                                                <Col customStyles={{ flex: 1.4 }}>
                                                    <View style={{ width: '90%' }}>
                                                        <Row>
                                                            <Col customStyles={{ flex: 2 }}>
                                                                <Text style={styles.modeText}>
                                                                    Account: {(data as any)?.accountNo}
                                                                </Text>
                                                                <Text style={styles.modeText}>
                                                                    Currenty: {(data as any)?.currency?.name}
                                                                </Text>
                                                                <Text style={styles.modeText}>
                                                                    Total Shares pending approval :{' '}
                                                                    {numberWithCommas((data as any)?.summary?.totalPendingForApprovalShares)}
                                                                </Text>
                                                                <Text style={styles.modeText}>
                                                                    Submitted on:{' '}
                                                                    {`${(data as any)?.timeline?.submittedOnDate[2]} / ${(data as any)?.timeline?.submittedOnDate[1]
                                                                        } / ${(data as any)?.timeline?.submittedOnDate[0]}`}
                                                                </Text>
                                                                <Text style={styles.modeText}>
                                                                    Activated on:{' '}
                                                                    {`${(data as any)?.timeline?.activatedDate[2]} / ${(data as any)?.timeline?.activatedDate[1]
                                                                        } / ${(data as any)?.timeline?.activatedDate[0]}`}
                                                                </Text>
                                                            </Col>

                                                        </Row>
                                                    </View>
                                                </Col>
                                            </Row>
                                        </Card.Content>
                                    </Card>
                                </View>

                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ marginTop: '25px', marginBottom: '25px', width: '100%' }}>
                                        <Col
                                            customStyles={{
                                                flex: 1,
                                                marginBottom: '-14px',
                                            }}
                                        >
                                            <Row>
                                                <Col
                                                    customStyles={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Button
                                                        onPress={() => setMode('transaction overview')}
                                                        style={[
                                                            mode === 'transaction overview' ? styles.active : null,
                                                            { height: '50px', paddingTop: '5px' },
                                                        ]}
                                                        color={mode === 'transaction overview' ? '#fff' : 'rgb(41, 113, 182)'}
                                                    >
                                                        Overview
                                                    </Button>
                                                </Col>

                                                <Col
                                                    customStyles={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Button
                                                        onPress={() => setMode('transactions')}
                                                        style={[
                                                            mode === 'transactions' ? styles.active : null,
                                                            {
                                                                height: '50px',
                                                                paddingTop: '5px',
                                                                borderBottomWidth: 2,
                                                                borderBottomColor: 'lightgrey',
                                                            },
                                                        ]}
                                                        color={mode === 'transactions' ? '#fff' : 'rgb(41, 113, 182)'}
                                                    >
                                                        Transactions
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <View style={[styles.horizontalDivider]} />
                                    </View>
                                </View>


                                {mode === 'transaction overview' ? (
                                    <Card style={[styles.card, { paddingLeft: 20 }]}>
                                        <Card.Content>
                                            <Row>
                                                <Col customStyles={{ flex: 1 }}>
                                                    <View>
                                                        <View style={[styles.details]}>
                                                            <Row>
                                                                <Col customStyles={{ flex: 1.7 }}>
                                                                    <Text style={[styles.detailText]}>
                                                                        Name Of share Product
                                                                    </Text>
                                                                </Col>
                                                                <Col customStyles={{ flex: 1 }}>
                                                                    <Text style={[styles.detailText]}>
                                                                    {(data as any)?.productName}
                                                                    </Text>
                                                                </Col>
                                                            </Row>
                                                        </View>
                                                        <View style={[styles.horizontalDivider, { width: '95%' }]} />
                                                    </View>
                                                    <View>
                                                        <View style={[styles.details]}>
                                                            <Row>
                                                                <Col customStyles={{ flex: 1.7 }}>
                                                                    <Text style={[styles.detailText]}>
                                                                        Price Per Share
                                                                    </Text>
                                                                </Col>
                                                                <Col customStyles={{ flex: 1 }}>
                                                                    <Text style={[styles.detailText]}>
                                                                        {(data as any)?.summary?.unitPrice}
                                                                    </Text>
                                                                </Col>
                                                            </Row>
                                                        </View>
                                                        <View style={[styles.horizontalDivider, { width: '95%' }]} />
                                                    </View>
                                                    <View>
                                                        <View style={[styles.details]}>
                                                            <Row>
                                                                <Col customStyles={{ flex: 1.7 }}>
                                                                    <Text style={[styles.detailText]}>
                                                                        Current Market Price
                                                                    </Text>
                                                                </Col>
                                                                <Col customStyles={{ flex: 1 }}>
                                                                    <Text style={[styles.detailText]}>
                                                                        {(data as any)?.currentMarketPrice}
                                                                    </Text>
                                                                </Col>
                                                            </Row>
                                                        </View>
                                                        <View style={[styles.horizontalDivider, { width: '95%' }]} />
                                                    </View>
                                                    <View>
                                                        <View style={[styles.details]}>
                                                            <Row>
                                                                <Col customStyles={{ flex: 1.7 }}>
                                                                    <Text style={[styles.detailText]}>Savings Account Attached</Text>
                                                                </Col>
                                                                <Col customStyles={{ flex: 1 }}>
                                                                    <Text style={[styles.detailText]}>{(data as any)?.savingsAccountNumber}</Text>
                                                                </Col>
                                                            </Row>
                                                        </View>
                                                        <View style={[styles.horizontalDivider, { width: '95%' }]} />
                                                    </View>
                                                </Col>

                                            </Row>
                                        </Card.Content>
                                    </Card>
                                ) : mode === 'transactions' ? (
                                    <Card style={[styles.card, { paddingLeft: 20 }]}>
                                        <Card.Content>
                                        <DataTable>
                                            <DataTable.Header style={{ backgroundColor: '#f2f7fb' }}>
                                            <DataTable.Title>
                                                <Text style={styles.title}>Type</Text>
                                            </DataTable.Title>
                                            <DataTable.Title>
                                                <Text style={styles.title}>Amount</Text>
                                            </DataTable.Title>
                                            <DataTable.Title>
                                                <Text style={styles.title}>Date</Text>
                                            </DataTable.Title>
                                            </DataTable.Header>
                
                                            {(data as any)?.purchasedShares?.map((value: any, key: any) => (
                                            <DataTable.Row>
                                                <DataTable.Cell>
                                                <Text style={styles.detailText}>{`${value?.type?.value}`}</Text>
                                                </DataTable.Cell>
                                                <DataTable.Cell>
                                                <Text style={styles.detailText}>{`${
                                                    numberWithCommas(value?.amount) || 0
                                                }`}</Text>
                                                </DataTable.Cell>
                                                <DataTable.Cell>
                                                <Text style={styles.detailText}>
                                                    {`${value?.purchasedDate[2]} / ${value?.purchasedDate[1]} / ${value?.purchasedDate[0]}`}
                                                </Text>
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                            ))}
                                        </DataTable>
                                        </Card.Content>
                                    </Card>
                                ) : null}

                            </View>
                        )}
                    </Col>
                </Row>

                <Footer />
            </View>
        </>
    );
}

export default ShareAccountDetails;

const styles = StyleSheet.create({
    header: {
        fontSize: 18,
        fontWeight: '500',
    },
    active: {
        backgroundColor: 'rgb(41, 113, 182)',
    },
    title: {
        color: 'grey',
        fontSize: 14,
    },
    detailText: {
        color: 'grey',
        fontSize: 13,
    },
    currencyText: {
        fontWeight: 'bold',
        color: '#606060',
        fontSize: 23,
    },
    details: {
        width: '95%',
        paddingBottom: '20px',
        paddingTop: '20px',
        fontSize: 14,
    },
    horizontalDivider: {
        borderBottomWidth: 2,
        borderBottomColor: 'lightgrey',
        marginTop: 5,
        width: '97%',
    },
    verticalDivider: {
        borderLeftWidth: 2,
        borderLeftColor: 'lightgrey',
        marginRight: '5px',
        height: '120px',
    },
    versionSubtitle: {
        color: '#a0a0a0',
        marginTop: 5,
        fontSize: 10,
        fontWeight: 'bold',
    },
    modeText: {
        color: '#a0a0a0',
        marginTop: 5,
        fontSize: 11,
        fontWeight: 'bold',
    },
    cardHeader: {
        fontSize: 30,
        color: '#2679b0',
        fontWeight: 'bold',
        marginTop: 20,
    },
    savingsText: {
        fontWeight: 'bold',
        color: 'grey',
        fontSize: 16,
    },
    dashboardIcons: {
        width: 70, //width: '95%': savings
        height: 70, //height: 90,: savings
        marginTop: 20,
    },
    card: {
        overflow: 'hidden',
        shadowColor: '#000000',
        shadowRadius: 10,
        shadowOpacity: 0.1,
        width: '98%',
    },
});
