/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import Navbar from '../../components/header/Navbar';
import { isPhone } from '../../components/common/devices';
import UserProfileMobile from './UserProfileMobile';
import UserProfileWeb from './UserProfileWeb';

export default function UserProfile({ navigation }: any) {
  return (
    <>
      {!isPhone() ? (
        <UserProfileMobile navigation={navigation} />
      ) : (
        <>
          <Navbar navigation={navigation} />

          <UserProfileWeb navigation={navigation} />
        </>
      )}
    </>
  );
}
