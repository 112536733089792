import React from 'react';
import { View } from 'react-native';
import Navbar from '../../components/header/Navbar';
import DashboardMobile from './DashboardMobile';
import DashboardWeb from './DashboardWeb';
import { styles } from './DashboardStyles';
import { isPhone } from '../../components/common/devices';

export default function Dashboard({ navigation }: any) {
  return (
    <View>
      {!isPhone() ? (
        <DashboardMobile navigation={navigation} />
      ) : (
        <View>
          <View style={styles.web}>
            <Navbar navigation={navigation} />
          </View>
          <DashboardWeb navigation={navigation} />
        </View>
      )}
    </View>
  );
}
