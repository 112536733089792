
import { StyleSheet, Platform } from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';

export const styles = StyleSheet.create({
  feel: {
    borderWidth: 2,
    borderColor: 'yellow',
  },
  container: {
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    padding: 30,
    height: '100%',
    backgroundColor: 'white',
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 20,
    marginLeft: 5,
    marginBottom: 20,
    width: '96%',
  },
  mobilemoneyImages: {
    width: '90%',
    height: 80,
    borderRadius: 20,
  },
  depositButton: {
    alignItems: 'center',
    backgroundColor: '#62c341',
    padding: 10,
    borderRadius: 25,
    marginTop: 20,
  },
  depositLabels: {
    fontSize: 20,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  depositsubtitleLabels: {
    fontSize: 13,
    color: 'grey',
    fontWeight: 'bold',
  },
  amountView: {
    backgroundColor: 'white',
  },
  selac: {
    fontSize: 16,
    color: '#000',
    fontWeight: 'bold',
  },
  dropdownContainerStyle: {
    position: 'absolute',
    backgroundColor: "white"
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
});

export default { styles };
