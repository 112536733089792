/* eslint-disable react/prop-types */
import React from 'react';
import { View, Platform } from 'react-native';

import ForgotPassword from './ForgotPassword';

export default function ForgotPswd({ navigation }: any) {
  return (
    <View>
      {Platform.OS !== 'web' ? (
        <ForgotPassword navigation={navigation} />
      ) : (
        <ForgotPassword navigation={navigation} />
      )}
    </View>
  );
}
