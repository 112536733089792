/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */

import React, { useState } from 'react';

import { Alert, Modal, StyleSheet, Text, View, Pressable, TextInput } from 'react-native';
import { Button } from 'react-native-paper';

import { AccountService } from '../../components/common/account.service';

export default function Changepassword() {
  const accountService = new (AccountService as any)();

  const [modalVisible, setModalVisible] = useState(false);
  const [password, setPassword] = useState<{
    isValidPassword: boolean;
  }>({
    isValidPassword: true,
  });
  const [repeatPassword, setConfirmPassword] = useState<{
    repeatPassword: boolean;
  }>({
    repeatPassword: true,
  });

  const onSubmit = async (e: any) => {
    console.log("submit button reached")
    e.preventDefault();
    const data = { password, repeatPassword };

    accountService.changePassword(data).then(() => {
      setModalVisible(!modalVisible);
    });
  };

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="fade"
        // eslint-disable-next-line react/jsx-boolean-value
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View>
              <Text style={[styles.header, { color: 'red' }]}>Change Password</Text>
            </View>

            <View style={[{ alignItems: 'center', justifyContent: 'center' }]}>
              <TextInput
                placeholder="Enter New Password"
                secureTextEntry
                style={styles.inputs}
                onChangeText={(e) => setPassword(e as unknown as { isValidPassword: boolean })}
              />

              {password.isValidPassword ? null : (
                <Text style={styles.errorMsg}>Password must at least 6 characters</Text>
              )}

              <TextInput
                placeholder="Confirm Password"
                secureTextEntry
                style={styles.inputs}
                onChangeText={(e) => setConfirmPassword(e as unknown as { repeatPassword: boolean })}
              />

              {password.isValidPassword ? null : (
                <Text style={styles.errorMsg}>Password did not match</Text>
              )}
            </View>

            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Pressable style={[styles.button, styles.buttonClose]} onPress={onSubmit}>
                <Text style={styles.textStyle}>Change Password</Text>
              </Pressable>

              <Pressable
                style={[styles.button, styles.cancel]}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <Text style={styles.textStyle}>Cancel</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Pressable style={[styles.show, styles.buttonOpen]} onPress={() => setModalVisible(true)}>
        <Text style={styles.textStyle}>Change Password</Text>
      </Pressable> */}
      <View style={{ width: '100%', marginTop: 40 }}>
        <Button
          color="#2679b0"
          mode="contained"
          onPress={() => setModalVisible(true)}
        >
          Change Password
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  modalView: {
    margin: 10,
    width: 300,

    backgroundColor: 'white',
    borderRadius: 10,
    padding: 15,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1.5,
    shadowRadius: 7,
    elevation: 10,
  },
  button: {
    borderRadius: 5,
    padding: 10,
    elevation: 2,
    margin: 8,
    width: 200,
  },
  show: {
    borderRadius: 5,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#6495ED',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  inputs: {
    borderWidth: 1,
    width: 250,
    height: 50,
    padding: 10,
    margin: 10,
    color: '#000',
    fontSize: 16,
  },
  header: {
    fontSize: 18,
    padding: 10,
  },
  cancel: {
    backgroundColor: 'gray',
  },
  btnStyle: {
    flex: 2,
    flexDirection: 'column',
  },
  errorMsg: {
    color: '#FF6347',
  },
});
