/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import { Text, View } from 'react-native';
import React from 'react';
import moment from 'moment';
import { Divider } from 'react-native-paper';

import { useSelector } from 'react-redux';
import Row, { Col } from '../../../components/Row';
import { numberWithCommas } from '../../../components/common/common';

function Transactions({ route }: any) {
  const { accountNo } = route.params;

  const account = useSelector((state) => (state as any).loanAccounts);

  return (
    <View style={{ margin: 20 }}>
      {account.map((x: any, i: any) => {
        if (x.accountNo === accountNo) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <View key={i}>
              <View>
                <Row>
                  <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold', paddingBottom: 5 }}>Repayment</Text>

                    <Text>{numberWithCommas(x.amountPaid)}</Text>
                  </Col>

                  <Col customStyles={{ flex: 6, alignItems: 'flex-end' }}>
                    <Text>{moment(x.timeline.actualDisbursementDate).format('DD/MM/YYYY')}</Text>
                  </Col>
                </Row>

                <Divider style={{ borderBottomWidth: 1 }} />

                <Row>
                  <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold', paddingBottom: 5 }}>Disbursement</Text>

                    <Text>{numberWithCommas(x.originalLoan)}</Text>
                  </Col>

                  <Col customStyles={{ flex: 6, alignItems: 'flex-end' }}>
                    <Text>{moment(x.timeline.actualDisbursementDate).format('DD/MM/YYYY')}</Text>
                  </Col>
                </Row>

                <Divider style={{ borderBottomWidth: 1 }} />

                <Row>
                  <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold', paddingBottom: 5 }}>Accrual</Text>

                    <Text>{numberWithCommas(x.originalLoan * 0.1)}</Text>
                  </Col>

                  <Col customStyles={{ flex: 4, alignItems: 'flex-end' }}>
                    <Text>{moment(x.timeline.actualDisbursementDate).format('DD/MM/YYYY')}</Text>
                  </Col>
                </Row>

                <Divider style={{ borderBottomWidth: 1 }} />
              </View>
            </View>
          );
        }
      })}
    </View>
  );
}

export default Transactions;
