/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import {
  StyleSheet,
  Text,
  View,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Image,
  ActivityIndicator,
} from 'react-native';
import { Card } from 'react-native-paper';
import React, { useEffect, useState } from 'react';
import Row, { Col } from '../../../components/Row';
import { numberWithCommas } from '../../../components/common/common';
import { AccountService } from '../../../components/common/account.service';

function ShareDetail({ navigation, route }: any) {
  const accountService = new (AccountService as any)();
  const { accountNo } = route.params;

  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const getShareAccountDetails = async () => {
    setLoader(true);
    await accountService.getShareDetails(accountNo).then(async (res: any) => {
      setLoader(false);
      const response = res.data;
      setData(response);
    });
  }

  useEffect(() => {
    getShareAccountDetails();
  }, []);

  return (
    <ScrollView>
      <View >
        <View>
          {loader ? (
            <View style={{ margin: 10, padding: 10 }}>
              <ActivityIndicator size="large" color="blue" />
            </View>
          ) : (
            <View style={{ margin: 10, padding: 10 }}>
              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <Text style={styles.text}>Name Of Share Product</Text>

                  <Text style={styles.compoText} >{(data as any)?.productName}</Text>
                </Col>
              </Row>
              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <Text style={styles.text}>Total Approved Shares</Text>

                  <Text style={styles.compoText} >{numberWithCommas((data as any)?.summary?.totalApprovedShares || 0)}</Text>
                </Col>
              </Row>

              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <Text style={styles.text}>Account Number</Text>

                  <Text style={styles.compoText} >{(data as any)?.accountNo}</Text>
                </Col>
              </Row>

              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <Text style={styles.text}>Currency</Text>

                  <Text style={styles.compoText} >{(data as any)?.currency?.name}</Text>
                </Col>
              </Row>

              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <Text style={styles.text}>Total Shares pending approval</Text>

                  <Text style={styles.compoText} >{numberWithCommas((data as any)?.summary?.totalPendingForApprovalShares)}</Text>
                </Col>
              </Row>

              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <Text style={styles.text}>Submitted On</Text>

                  <Text style={styles.compoText} >{`${(data as any)?.timeline?.submittedOnDate[2]} / ${(data as any)?.timeline?.submittedOnDate[1]} / ${(data as any)?.timeline?.submittedOnDate[0]}`}</Text>
                </Col>
              </Row>

            </View>
          )}
        </View>
      </View>
    </ScrollView>
  );
}

export default ShareDetail;

const styles = StyleSheet.create({
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
  text: {
    fontSize: 18,
    letterSpacing: 1,
    fontWeight: '800',
    color: 'rgb(30,136,229)'
  },
  compoText: {
    fontSize: 14,
    paddingTop: 5,
    fontWeight: '500',
  },
});
