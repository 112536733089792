/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useState, useEffect} from 'react';
import { View, Text, StyleSheet, ImageBackground, ActivityIndicator } from 'react-native';
import { Card, TextInput, Button } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';
import Table from '../../components/dataTable/RecentTransactionTable';
import TrackScreenHit from "../../utils/analytics"

import Row, { Col } from '../../components/Row';

export default function RecentTransactionsWeb({ navigation, route }: any) {
 const {tabName} = route.params
  const [mode, setMode] = useState('Recent Transactions');

  const transactionHeadings = [
    'Account Number',
    'Account Type',
    'Amount',
    'Transaction Type',
    'Transaction Date',
  ];
  const transactions = useSelector((state) => (state as any).transactions);
  const selfReportedTransactions = useSelector((state) => (state as any).selfReportedTransactions);
  const selfReportedHeadings = ['Status', 'Amount', 'Transaction Date'];
  TrackScreenHit("Recent Transactions Page")

  useEffect(() => {
    setMode(tabName)
  }, [tabName])

  return (
    <View style={[DashboardStyles.web]}>
      <Row>
        <Col customStyles={{ flex: 1.2, alignItems: 'center' }}>
          <SideMenu navigation={navigation} page={tabName} style={{ height: '30px' }} />
        </Col>

        <Col customStyles={{ flex: 4, paddingTop: '10px' }}>
          <View style={{ flexDirection: 'column', flex: 2.3 }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '100%' }}>
                <Col
                  customStyles={{
                    flex: 1,
                    marginBottom: '-14px',
                  }}
                >
                  <Row>
                    <Col
                      customStyles={{
                        flex: 1,
                      }}
                    >
                      <Button
                        onPress={() => setMode('Recent Transactions')}
                        style={[
                          mode === 'Recent Transactions' ? styles.active : null,
                          { height: '50px', paddingTop: '5px' },
                        ]}
                        color={mode === 'Recent Transactions' ? '#fff' : 'rgb(41, 113, 182)'}
                      >
                        Recent Transactions
                      </Button>
                    </Col>
                    <Col
                      customStyles={{
                        flex: 1,
                      }}
                    >
                      <Button
                        onPress={() => setMode('Self Reported Transactions')}
                        style={[
                          mode === 'Self Reported Transactions' ? styles.active : null,
                          { height: '50px', paddingTop: '5px' },
                        ]}
                        color={mode === 'Self Reported Transactions' ? '#fff' : 'rgb(41, 113, 182)'}
                      >
                        Self Reported Transactions
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <View style={[styles.horizontalDivider]} />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '15px',
                width: '100%',
                height: '600px',
              }}
            >
              <Card style={[DashboardStyles.card, { paddingLeft: 20 }]}>
                <Card.Content>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      {mode == 'Recent Transactions' ? (
                        <Table
                          titles={transactionHeadings}
                          data={transactions}
                          mode={'Recent Transactions'}
                          navigation={navigation}
                        />
                      ) : mode == 'Self Reported Transactions' ? (
                        <Table
                          titles={selfReportedHeadings}
                          data={selfReportedTransactions}
                          mode={'Self Reported Transactions'}
                          navigation={navigation}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Card.Content>
              </Card>
            </View>
          </View>
        </Col>
      </Row>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
  },
  card: {
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.2,
    width: '98%',
  },
  active: {
    backgroundColor: 'rgb(41, 113, 182)',
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 20,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  horizontalDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 5,
    width: '97%',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#62c341',
    padding: 10,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: 'grey',
    fontWeight: 'bold',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 80,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
});
