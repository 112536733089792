import React from 'react';
import PaymentMobile from './PaymentMobile';
import Navbar from '../../components/header/Navbar';
import { isPhone } from '../../components/common/devices';
import PaymentWeb from './PaymentWeb';

export default function Payment({ navigation , route }: any) {
  return (
    <>
      {!isPhone() ? (
        <PaymentMobile navigation={navigation} route={route} />
      ) : (
        <>
          <Navbar navigation={navigation} />

          <PaymentWeb navigation={navigation} route={route} />
        </>
      )}
    </>
  );
}
