import React from 'react';

import { View, ActivityIndicator } from 'react-native';

function MyActivityIndicator() {
  return (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <ActivityIndicator size="large" color="grey" />
    </View>
  );
}

export default MyActivityIndicator;
