import { useDispatch } from 'react-redux';
import { AccountService } from '../../components/common/account.service';

function ProfileCtrl(this: any) {
  
  const accountService = new (AccountService as any)();
  const dispatch = useDispatch();

  const vm = this;

  vm.getImage = getImage;

  async function getImage() {
    const clientId = await accountService.getClientId();

    await accountService.getImage(clientId).then(async (response: any) => {
      vm.image = response.data;
      dispatch({
        type: 'SET_IMAGE',
        image: vm.image,
      });
    });
  }
  getImage();
}

export default ProfileCtrl;
