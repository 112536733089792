import { AccountService } from '../../components/common/account.service';

function AccountCtrl(this: any) {
  const vm = this;

  const accountService = new (AccountService as any)();

  vm.selected = [];
  //vm.getAccounts = getAccounts;
  // vm.onPaginate = onPaginate;
  // vm.onReorder = onReorder;
  // vm.routeTo = routeTo;
  // vm.userData = AuthService.getUser();
  vm.clientId = getClient();
  vm.accounts = [];
  vm.loanAccounts = [];
  vm.savingsAccounts = [];
  vm.shareAccounts = [];
  vm.loadingAccountInfo = true;

  vm.query = {
    limit: 5,
    offset: 1,
  };

  async function getClient() {
    vm.clientId = await accountService.getClientId();
    //getAccounts(vm.clientId);
  }

  function getAccounts(accountNo: any) {
    accountService
      .getAllAccounts(accountNo)
      .then(async (response: any) => {
        const res = response.data;
        vm.loanAccounts = res.loanAccounts;
        vm.savingsAccounts = res.savingsAccounts;
        vm.shareAccounts = res.shareAccounts;
        vm.loadingAccountInfo = false;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
}

export default AccountCtrl;
