import { View, Text, ScrollView, StyleSheet, Pressable, Platform } from 'react-native';
import React, { useState } from 'react';
import { TextInput, Button, RadioButton } from 'react-native-paper';

import { AccountService } from '../../components/common/account.service';

import Row, { Col } from '../../components/Row';

export default function SelfRegisterMobile({ navigation }: any) {
  const [fsp, setFsp] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [authenticationMode, setAuthenticationMode] = useState();
  // const [checked, setChecked] = useState(email);

  const accountService = new (AccountService as any)();

  const handleSubmit = async () => {
    const data = {
      username,
      password,
      firstName,
      lastName,
      email,
      accountNumber,
      phoneNumber,
      authenticationMode,
    };

    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': fsp,
      },
    };

    await accountService
      .register(data, apiHeaders)
      .then((res: any) => {
        console.log('data submitted', res.data);
        return res.data;
      })
      .catch((err: any) => {
        console.log('registration failed', err);
      });
  };
  return (
    <ScrollView>
      <View style={styles.mobile}>
        <View>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              {/* <Image source={require('../../assets/KBLogo.png')} style={styles.kbl} /> */}

              <Text style={styles.text}>Create an account</Text>
            </Col>
          </Row>

          <View style={styles.inputView}>
            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Financial Institution"
                  placeholder="Financial Institution"
                  style={styles.input}
                  onChangeText={(e: any) => setFsp(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Account Number"
                  placeholder="Account Number"
                  style={styles.input}
                  onChangeText={(e: any) => setAccountNumber(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Username"
                  placeholder="Username"
                  style={styles.input}
                  onChangeText={(e: any) => setUsername(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="First Name"
                  placeholder="First Name"
                  style={styles.input}
                  onChangeText={(e: any) => setFirstName(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Last Name"
                  placeholder="Last Name"
                  style={styles.input}
                  onChangeText={(e: any) => setLastName(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Phone Number"
                  placeholder="Phone Number"
                  style={styles.input}
                  onChangeText={(e: any) => setPhoneNumber(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  style={styles.input}
                  onChangeText={(e: any) => setEmail(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Password"
                  placeholder="Password"
                  style={styles.input}
                  onChangeText={(e: any) => setPassword(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  style={styles.input}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={{ flex: 1, fontSize: 16 }}>Choose a verification Mode</Text>

                <View style={{ flexDirection: 'row', margin: 5 }}>
                  <RadioButton
                    value="email"
                    status={authenticationMode === 'email' ? 'checked' : 'unchecked'}
                    // @ts-expect-error TS(2345): Argument of type '"email"' is not assignable to pa... Remove this comment to see the full error message
                    onPress={() => setAuthenticationMode('email')}
                  />

                  <Text style={{ margin: 5 }}>email</Text>

                  <RadioButton
                    value="mobile"
                    status={authenticationMode === 'mobile' ? 'checked' : 'unchecked'}
                    // @ts-expect-error TS(2345): Argument of type '"mobile"' is not assignable to p... Remove this comment to see the full error message
                    onPress={() => setAuthenticationMode('mobile')}
                  />

                  <Text style={{ margin: 5 }}>mobile</Text>
                </View>
              </Col>
            </Row>

            <Pressable style={styles.btn} onPress={handleSubmit}>
              <Text style={styles.reg}>REGISTER</Text>
            </Pressable>

            <Button
              mode="text"
              uppercase={false}
              style={{ margin: 10 }}
              onPress={() => navigation.navigate('Login')}
            >
              Already have an account ?{' '}
              <Text style={{ color: '#2196F3', marginBottom: 10, fontSize: 16 }}>Login</Text>
            </Button>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  inputView: {
    flex: 1,
    margin: 20,
  },
  input: {
    backgroundColor: 'transparent',
  },
  kbl: {
    height: 80,
    width: '80%',
    marginTop: 5,
  },
  text: {
    margin: 20,
    fontSize: 20,
    fontWeight: '300',
    color: '#2196F3',
  },
  btn: {
    borderRadius: 4,
    padding: 10,
    elevation: 2,
    backgroundColor: '#2196F3',
    width: '100%',
    alignItems: 'center',
  },
  reg: { color: 'white', fontSize: 16, letterSpacing: 5 },
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
});
