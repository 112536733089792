/* eslint-disable func-names */
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  SafeAreaView,
  TouchableHighlight,
  Image,
} from 'react-native';
import { Card, Title, TextInput } from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useToast } from 'react-native-paper-toast';
import { useSelector } from 'react-redux';
import { store } from '../../redux/index';

import SideMenu from '../../components/SideMenu/SideMenu';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import { styles as loanStyles} from '../Loans/LoanStyles'

import { AccountService } from '../../components/common/account.service';

import Footer from '../../components/footer/footer';

import Row, { Col } from '../../components/Row';

import Activity from '../../components/activityIndicator/ActivityIndicator';
import TrackScreenHit from "../../utils/analytics"
import RequiredTextInput from '../../components/RequiredTextInput';

function PageBody({ navigation }: any) {
  const accountService = new (AccountService as any)();
  const toaster = useToast();
  TrackScreenHit("Request  For Withdraw Page")

  const [loader, setLoader] = useState(false);
  const [account, setAccount] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountNumber, setAccountNumber] = useState('');
  const [clientId, setClientId] = useState();

  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [paymentOption, setPaymentOption] = useState(false);

  const [paymentType, setPaymentType] = useState('');
  const [transferType, setTransferType] = useState('');
  const [paymentTypeOption, setPaymentTypeOption] = useState(false);

  // PaymentChannelDetails
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');
  const [accountBalance, setAccountBalance] = useState(0);
  const [fsp, setFsp] = useState();
  const [tempObj, setTempObj] = useState({})

  const [PaymentChannelDetails] = useState({});

  let savingsAccounts = useSelector((state) => (state as any).savingsAccounts);

  // Required Error checking
  const [amountError, setAmountError] = useState('');
  const [reasonError, setReasonError] = useState('');

  const transactionDate = new Date();

  const choice: any = [];
  const option = [
    { label: 'Mobile Money', value: 'Mobile Money' },
    { label: 'Bank Transfer', value: 'Bank Transfer' },
  ];
  const changePaymentType = (e: any) => {
    setPaymentTypeOption(true);
    setPaymentType(e);
  };

  useEffect(() => {
    setTempObj({
      locale: 'en',
      accountType: 'saving',
      accountId: accountNumber,
      amount: amount,
    });
  }, [accountNumber, amount])

  console.log(tempObj)

  const onChangeTransfertype = (e: any) => {
    setTransferType(e);
  };

  const bankTransferType = [
    { label: 'Housing Finance Bank', value: 1 },
    { label: 'EFT Payment', value: 2 },
    { label: 'RTGS Payment', value: 3 },
  ];

  async function checkPaymentOption() {
    const id = 1;

    accountService.getAvailablepayment(id).then((response: any) => {
      const paymentChannel = response.data.canUse;
      setPaymentOption(paymentChannel);
    });
  }

  async function getSavingAccount() {
    const client = await accountService.getClientId();
    setClientId(client);

    let myResponse: any = [];
    savingsAccounts.map((x: any) => {
      const tempObj = {};
      (tempObj as any).label = x.accountNo;
      (tempObj as any).value = x.accountNo;

      myResponse.push(tempObj);
      setAccountBalance(x.accountBalance);

      return myResponse;
    });

    setAccount(myResponse);

  }

  async function validateAccount() {
    const channelId = 5;

    const data = {
      channelId,
      accountNo,
    };

    // if (accountNo === parseInt(10, 10)) {
    await accountService.selfCheck(data).then(function (res: any) {
      return res.data;
    });
    //}
  }

  useEffect(() => {
    getSavingAccount();
    checkPaymentOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle submit
  const submit = () => {
    if (paymentType === 'Mobile Money') {
      (PaymentChannelDetails as any).channelId = 5;
    }

    if (paymentType === 'Bank Transfer') {
      (PaymentChannelDetails as any).bankName = bankName;
    }
    if (paymentType === 'Bank Transfer') {
      (PaymentChannelDetails as any).branchName = branchName;
    }

    if (paymentType === 'Bank Transfer') {
      (PaymentChannelDetails as any).accountName = accountName;
    }

    if (paymentType === 'Bank Transfer') {
      (PaymentChannelDetails as any).accountType = accountType;
    }

    if (paymentType === 'Bank Transfer') {
      (PaymentChannelDetails as any).swiftCode = swiftCode;
    }

    if (transferType === 'Housing Finance Bank') {
      (PaymentChannelDetails as any).channelId = 1;
    }

    if (transferType === 'EFT Payment') {
      (PaymentChannelDetails as any).channelId = 2;
    }

    if (transferType === 'RTGS Payment') {
      (PaymentChannelDetails as any).channelId = 3;
    }

    (PaymentChannelDetails as any).accountNo = accountNo;
    (PaymentChannelDetails as any).transactionAmount = amount;

    if (paymentOption) {
      (tempObj as any).PaymentChannelDetails = PaymentChannelDetails;
    }
    choice.push(tempObj);
    handleSubmit();
  };

  // eslint-disable-next-line no-shadow
  function reset() {
    setAmount('');
    setAccountNumber('');
    setReason('');
    setAccountType('');
    setAccountNo('');
    setLoader(false);
  }

  const handleSubmit = async () => {
    setLoader(true);

    if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
      setAmountError('Please enter a valid amount');
      setLoader(false);
      return;
    }

    if (reason == "") {
      setReasonError('Please enter a valid document name');
      setLoader(false);
      return;
    }

    reset();
    // eslint-disable-next-line array-callback-return
    accounts.map((x) => {
      if ((x as any).currency.code === 'UGX' && (x as any).id === accountType) {
        // eslint-disable-next-line radix
        if (parseInt(amount) > (x as any).accountBalance) {
          toaster.show({
            message: 'Amount requested cannot be more than the account balance.',
            duration: 5000,
            type: 'error',
            position: 'middle',
          });
        }
        setAccountNumber((x as any).accountNo);
      }
    });

    // const tenantId = await accountService.getTenantId();
    const userProfile = await accountService.getClient(clientId).then(async (response: any) => {
      return response.data;
    });

    (PaymentChannelDetails as any).transactionComment = userProfile.displayName;
    const data = {
      accountBalance,
      clientName: userProfile.displayName,
      reason: reason,
      note: JSON.stringify(PaymentChannelDetails),
      transactionDate: moment(transactionDate).format(moment.HTML5_FMT.DATE),
      split: choice,
    };

    await accountService
      .requestWithdraw(clientId, data)
      .then(function (res: any) {
        toaster.show({
          message: 'Your Request to withdraw has been sent',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });
        return reset();
      })
      .catch(() => {
        setLoader(false);
        toaster.show({
          message: 'Request has not been sent please try again',
          duration: 7000,
          type: 'error',
          position: 'middle',
        });
      });
  };

  const getTenant = async () => {
    const myFsp = await accountService.getTenantId();
    setFsp(myFsp)
  }

  useEffect(() => {
    getTenant()
  },[])

  // eslint-disable-next-line consistent-return
  const renderOption = () => {

      return (
        <View>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>
                Select Account Type
                <Text style={{color: 'red'}}>*</Text>
              </Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <SafeAreaView style={{ width: '50%' }}>
                    <DropDown
                      placeholder="Select Payment Type"
                      visible={showOption}
                      showDropDown={() => setShowOption(true)}
                      onDismiss={() => setShowOption(false)}
                      value={paymentType}
                      setValue={(value: any) => changePaymentType(value)}
                      list={option}
                      dropDownStyle={styles.dropdownContainerStyle}
                    />
                  </SafeAreaView>
                </Col>
              </Row>
            </Col>
          </Row>
        </View>
      );
    
  };

  // eslint-disable-next-line consistent-return
  const renderPaymentType = () => {
    if (paymentType === 'Bank Transfer') {
      return (
        <View>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Bank Name</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Enter bank name"
                onChangeText={(e: any) => setBankName(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={styles.amountView}
              />
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Branch Name</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Enter branch name"
                onChangeText={(e: any) => setBranchName(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={styles.amountView}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Account Number</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Enter account number"
                onChangeText={(e: any) => setAccountNo(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={styles.amountView}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Select Bank Transfer Type</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <SafeAreaView style={{ width: '50%' }}>
                    <DropDown
                      mode="outlined"
                      placeholder="Select Bank Transfer Type"
                      visible={showDropDown1}
                      showDropDown={() => setShowDropDown1(true)}
                      onDismiss={() => setShowDropDown1(false)}
                      value={transferType}
                      setValue={(value: any) => onChangeTransfertype(value)}
                      list={bankTransferType}
                      dropDownStyle={styles.dropdownContainerStyle}
                    />
                  </SafeAreaView>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Account Name</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Enter Account Name"
                onChangeText={(e: any) => setAccountName(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={styles.amountView}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Account Type</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Enter account type"
                onChangeText={(e: any) => setAccountType(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={styles.amountView}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Swift Code</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Enter Swift Code"
                onChangeText={(e: any) => setSwiftCode(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={styles.amountView}
              />
            </Col>
          </Row>
        </View>
      );
    }
    if (paymentType === 'Mobile Money') {
      return (
        <View>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.label}>Phone Number</Text>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Enter Phone Number"
                onChangeText={(e: any) => setAccountNo(e)}
                onChange={() => validateAccount()}
                style={styles.inputs}
              />
            </Col>
          </Row>
        </View>
      );
    }
  };

  return (
    <View style={[loanStyles.container]}>
      <Row>
        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <SideMenu navigation={navigation} page="Withdraw Request" />
        </Col>
        <Col customStyles={{ flex: 4 }}>
          {/* <View> */}

          <Row>
            <Col
              customStyles={{
                flex: 1,
              }}
            >
              <Card style={[DashboardStyles.card, { paddingLeft: 20 }]}>
                <ImageBackground
                  source={require('../../assets/kb-blue-background.png')}
                  style={[
                    {
                      flex: 1,
                      width: 200,
                      marginTop: 10,
                      paddingVertical: 100,
                      height: '90%',
                      position: 'absolute',
                      right: 0,
                    },
                  ]}
                />
                <Card.Content>
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '100%', marginTop: '44px', marginBottom: '45px' }}>
                      <Row>
                        <Col customStyles={{ flex: 0.168 }}>
                          <Image
                            style={[
                              styles.dashboardIcons,
                              { height: '70px', width: 70, position: 'absolute' },
                            ]}
                            source={require('../../assets/total_savings.png')}
                          />
                        </Col>

                        <Col customStyles={{ flex: 1 }}>
                          <View>
                            <Title style={[styles.savingsText, { paddingTop: '14px' }]}>
                              Request Withdraw
                            </Title>
                          </View>
                        </Col>
                      </Row>
                    </View>
                  </View>
                  <View style={[styles.horizontalDivider]} />

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={styles.label}>
                        Select Account Type
                        <Text style={{color: 'red'}}>*</Text>
                       </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Row>
                        <Col customStyles={{ flex: 1 }}>
                          <SafeAreaView style={{ width: '50%' }}>
                            <DropDown
                              placeholder="Select Saving Account"
                              visible={showDropDown}
                              showDropDown={() => setShowDropDown(true)}
                              onDismiss={() => setShowDropDown(false)}
                              value={accountNumber}
                              setValue={(value: any) => setAccountNumber(value)}
                              list={account}
                            />
                          </SafeAreaView>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 0.5}}>
                      <RequiredTextInput
                        label="Amount"
                        value={amount}
                        onChange={(e: any) => setAmount(e)}
                        required
                        error={amountError}
                        mode="outlined"
                        outlineColor="#2679b0"
                        activeOutlineColor="#2679b0"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 0.5}}>
                      <RequiredTextInput
                        label="Attach Reason"
                        value={reason}
                        onChange={(e: any) => setReason(e)}
                        required
                        error={reasonError}
                        mode="outlined"
                        outlineColor="#2679b0"
                        activeOutlineColor="#2679b0"
                      />
                    </Col>
                  </Row>
                  {renderOption()}
                  {renderPaymentType()}

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TouchableHighlight onPress={() => submit()} disabled={loader}>
                        <View style={[styles.depositButton, loader && styles.disabledButton]}>
                          <Text style={{ color: 'white', fontWeight: 'bold' }}>
                            {loader ? 'Processing...' : 'Submit For Review'}
                          </Text>
                        </View>
                      </TouchableHighlight>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      {loader && <Activity />}
                    </Col>
                  </Row>

                </Card.Content>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </View>
  );
}

export default function RequestWithdrawWeb({ navigation }: any) {
  return <PageBody navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  amountView: {
    backgroundColor: 'white',
  },
  disabledButton: {
    backgroundColor: '#a9a9a9',
  },
  dropdown: {
    margin: 16,
    height: 50,
    borderBottomColor: 'gray',
    borderBottomWidth: 0.5,
  },
  savingsText: {
    fontWeight: 'bold',
    color: '#2679b0',
    fontSize: 30,
  },
  horizontalDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 5,
    width: '100%',
    marginBottom: '30px',
  },
  inputs: {
    backgroundColor: 'transparent',
    color: 'grey',
    width: '50%',
  },
  dropdownContainerStyle: {
    position: 'absolute',
  },
  dashboardIcons: {
    width: 100,
    height: 40,
    marginLeft: '15%',
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 20,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#2679b0',
    padding: 10,
    marginTop: 20,
    width: 200,
  },
  label: {
    fontSize: 16,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  selac: {
    fontSize: 16,
    color: '#000',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 80,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
});

