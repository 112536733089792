/* eslint-disable consistent-return */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Constants } from '../../Constants/Constants';

const { BASE_URL, MOMO_BASE_URL, USSD_BASE_URL } = Constants;

const axios = require('axios');

export function AccountService(this: any) {
  /**
   * Get the clients associated with the current user's account.
   *
   */
  const vm = this;

  vm.setUser = async (value: any) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('userData', jsonValue);
    } catch (e) {
      // saving error
    }
  };
  vm.setIsSignedIn = async (value: any) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('isSignedIn', jsonValue);
    } catch (e) {
      // saving error
    }
  };

  vm.getIsSignedIn = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('isSignedIn');
      return jsonValue != null ? JSON.parse(jsonValue) : false;
    } catch (e) {
      // error reading value
      return false;
    }
  };

  vm.getUser = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('userData');
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      // error reading value
      return null;
    }
  };

  vm.setHeaders = async (value: any) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('apiHeaders', jsonValue);
    } catch (e) {
      // saving error
    }
  };
  vm.getHeaders = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('apiHeaders');
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      // error reading value
    }
  };
  vm.setDocHeaders = async (value: any) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('apiDocHeaders', jsonValue);
    } catch (e) {
      // saving error
    }
  };

  vm.setProfHeaders = async (value: any) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('apiDocHeaders', jsonValue);
    } catch (e) {
      // saving error
    }
  };

  vm.getDocHeaders = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('apiDocHeaders');
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      // error reading value
    }
  };

  vm.getProfHeaders = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('apiDocHeaders');
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      // error reading value
    }
  };

  const apiHeaders = {
    auth: {
      username: '',
      password: '',
    },

    headers: {
      'Content-Type': 'application/json',
      'Fineract-Platform-TenantId': '',
    },
  };

  const resetApiHeaders = {
    headers: {
      'Content-Type': 'application/json',
      'Fineract-Platform-TenantId': '',
    },
  };

  const apiDocUploadHeaders = {
    headers: {
      'Fineract-Platform-TenantId': '',
    },
    auth: {
      username: '',
      password: '',
    },
  };

  vm.getClients = async function () {
    return axios.get(`${BASE_URL}self/clients/`, await vm.getHeaders());
  };

  vm.setAuth = function (username: any, password: any, fsp: any) {
    apiHeaders.auth.username = username;
    apiHeaders.auth.password = password;
    apiDocUploadHeaders.auth.username = username;
    apiDocUploadHeaders.auth.password = password;
    apiHeaders.headers['Fineract-Platform-TenantId'] = fsp;
    apiDocUploadHeaders.headers['Fineract-Platform-TenantId'] = fsp;
    vm.setHeaders(apiHeaders);
    vm.setDocHeaders(apiDocUploadHeaders);
  };

  vm.getAllAccounts = async function (clientId: any) {
    return axios.get(`${BASE_URL}self/clients/${clientId}/accounts/`, await vm.getHeaders());
  };

  vm.getSelfReportedTransactions = async function (clientId: any) {
    return axios.get(`${BASE_URL}self/reporting/${clientId}`, await vm.getHeaders());
  };
  vm.getRecentTransactions = async function (clientId: any) {
    return axios.get(`${BASE_URL}self/clients/${clientId}/transactions/`, await vm.getHeaders());
  };

  vm.getClient = async function (id: any) {
    return axios.get(`${BASE_URL}self/clients/${id}`, await vm.getHeaders());
  };

  vm.getClientCharges = async function (id: any) {
    return axios(`${BASE_URL}/self/clients/${id}/charges?pendingPayment=true`);
  };

  vm.getClientAccounts = async function (id: any) {
    return axios(`${BASE_URL}/self/clients/${id}/accounts/`, await vm.getHeaders());
  };

  vm.getLoanAccount = async function (id: any) {
    return axios(`${BASE_URL}/self/loans/${id}/`, await vm.getHeaders());
  };
  vm.getAccountStatistics = async function () {
    return axios(
      `${BASE_URL}self/runreports/runallreports?genericResultSet=false`,
      await vm.getHeaders()
    );
  };

  vm.getLoanId = async function (loanId: any) {
    return axios(`${BASE_URL}api/v1/loans/${loanId}`, await vm.getHeaders());
  };

  vm.getLoanDetails = async function (id: any) {
    return axios(
      `${BASE_URL}self/loans/${id}?associations=repaymentSchedule,transactions`,
      await vm.getHeaders()
    );
  };
  vm.getAvailablepayment = async function (channelId: any) {
    return axios(
      `${BASE_URL}extensions/payment/self/available/${channelId}`,
      await vm.getHeaders()
    );
  };

  vm.getSavingDetails = async function (id: any) {
    return axios(
      `${BASE_URL}self/savingsaccounts/${id}?associations=transactions,charges`,
      await vm.getHeaders()
    );
  };

  vm.getShareDetails = async function (id: any) {
    return axios(`${BASE_URL}self/shareaccounts/${id}?associations=transactions,charges`, await vm.getHeaders());
  };

  vm.setClientId = async function (id: any) {
    try {
      await AsyncStorage.setItem('clientId', JSON.stringify(id));
    } catch (e) {
      // saving error
    }
  };
  vm.getClientId = async function () {
    try {
      const jsonValue = await AsyncStorage.getItem('clientId');
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      // error reading value
    }
  };

  vm.setTenantId = async (value: any) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('tenant-id', jsonValue);
    } catch (e) {
      // saving error
    }
  };

  vm.getTenantId = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('tenant-id');
      return jsonValue != null ? formatTenantId(JSON.parse(jsonValue)) : null;
    } catch (e) {
      // error reading value
    }
  };

  vm.docUpload = async function (clientId: any, data: any) {
    return axios.post(`${BASE_URL}self/clients/${clientId}/upload`, data, await vm.getDocHeaders());
  };

  vm.profileUpload = async function (clientId: any, data: any) {
    return axios.post(
      `${BASE_URL}self/clients/${clientId}/images`,
      data,
      await vm.getProfHeaders()
    );
  };

  vm.requestWithdraw = async function (clientId: any, data: any) {
    return axios.post(`${BASE_URL}self/withdraw-request/${clientId}`, data, await vm.getHeaders());
  };

  vm.getLoanProducts = async function (clientId: any) {
    return axios.get(`${BASE_URL}self/loanproducts?clientId=${clientId}`, await vm.getHeaders());
  };

  vm.getShareProducts = async function (clientId: any) {
    return axios.get(`${BASE_URL}self/products/share?clientId=${clientId}`, await vm.getHeaders());
  };

  // sending share products data
  vm.shares = async function (data: any) {
    return axios.post(`${BASE_URL}self/shareaccounts`, data, await vm.getHeaders());
  };

  vm.getImage = async function (clientId: any) {
    return axios.get(`${BASE_URL}self/clients/${clientId}/images`, await vm.getHeaders());
  };

  vm.selfReporting = async function (clientId: any, data: any) {
    return axios.post(`${BASE_URL}self/reporting/${clientId}`, data, await vm.getHeaders());
  };

  vm.template = async function (template: any) {
    return axios.get(
      `${BASE_URL}self/loans/template?templateType=${template.templateType}&clientId=${template.clientId}&productId=${template.productId}`,
      await vm.getHeaders()
    );
  };

  vm.SharesTemplate = async function (template: any) {
    return axios.get(
      `${BASE_URL}self/shareaccounts/template?clientId=${template.clientId}&productId=${template.productId}`,
      await vm.getHeaders()
    );
  };

  vm.loan = async function (data: any) {
    return axios.post(`${BASE_URL}self/loans`, data, await vm.getHeaders());
  };

  vm.notes = async function (loanId: any, data: any) {
    return axios.post(`${BASE_URL}self/loans/${loanId}/notes`, data, await vm.getHeaders());
  };

  vm.whatsapp = async function (data: any) {
    return axios.post(`${USSD_BASE_URL}/api/whatsapp/user/create`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  };

  vm.resetPassword = async function (data: any) {
    return axios.post(`${BASE_URL}forgot-password`, data, await vm.getHeaders());
  };

  vm.loanDocUpload = async function (loanId: any, data: any) {
    return axios.post(`${BASE_URL}self/loans/${loanId}/upload`, data, await vm.getDocHeaders());
  };
  // this.getMOMOEndpoint = async function (data) {
  //   const momoEndpoint = MOMO_BASE_URL + '/api/loan/';
  //   if (true === isSavingsTransfer && !isWithdraw ){
  //       momoEndpoint = MOMO_BASE_URL + '/api/saving/';
  //   }
  //   if(true === isSavingsTransfer && isWithdraw) {
  //       momoEndpoint = MOMO_BASE_URL + '/api/withdraw/';
  //   }
  //   return axios.post(`${MOMO_BASE_URL}/api/loan/`, data, await vm.getHeaders());
  //   // saving
  // };

  /**
 momo api for savings and laon transfer
 */
  vm.LoanTransfer = async function (data: any) {
    return axios.post(`${MOMO_BASE_URL}/api/loan`, data, await vm.getHeaders());
  };
  vm.SavingTransfer = async function (data: any) {
    return axios.post(`${MOMO_BASE_URL}/api/saving`, data, await vm.getHeaders());
  };

  vm.changePassword = async function (data: any) {
    return axios.put(`${BASE_URL}self/user`, data, await vm.getHeaders());
  };
  vm.updateProfile = async function (data: any) {
    return axios.put(`${BASE_URL}self/user`, data, await vm.getHeaders());
  };
  vm.register = async function (data: any) {
    return axios.post(`${BASE_URL}self/registration`, data, await vm.getHeaders());
  };

  vm.validate = async function (data: any) {
    return axios.post(`${BASE_URL}self/registration/user`, data, await vm.getHeaders());
  };

  vm.selfCheck = async function (data: any) {
    return axios.post(`${BASE_URL}extensions/payment/self/check`, data, await vm.getHeaders());
  };

  vm.ResetPwd = async function (token: any, fsp: any, data: any) {
    resetApiHeaders.headers['Fineract-Platform-TenantId'] = fsp;
    await vm.setHeaders(resetApiHeaders);
    return axios.post(`${BASE_URL}reset-password?token=${token}`, data, await vm.getHeaders());
  };
}

function formatTenantId (tenantId: string) {
  const words = tenantId.toLowerCase().split(' ');
  if (words.length === 1) {
    return words[0].toUpperCase();
  }
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};


export default { AccountService };

/**
port

9002 or 3000
*/


