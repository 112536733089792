/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Appbar, Menu } from 'react-native-paper';
import { AccountService } from '../common/account.service';

export default function Header(props: any) {
  const { navigation, back, route } = props;
  // const _goBack = () => console.log('Went back');

  const [visible, setVisible] = React.useState(true);
  const openMenu = () => {
    setVisible(true);
    navigation.openDrawer();
  };
  const closeMenu = () => {
    setVisible(false);
    // navigation.closeDrawer();
  };

  const accountService = new (AccountService as any)();
  const [fsp, setFsp] = useState('');

  const getFsp = async () => {
    const tenantId = await accountService.getTenantId();
    setFsp(tenantId);
  };
  getFsp();

  return (
    <Appbar.Header style={{ backgroundColor: '#2679b0' }}>
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      {!back ? (
        //

        <Menu
          children={<></>}
          visible={visible}
          onDismiss={closeMenu}
          anchor={<Appbar.Action icon="menu" color="white" onPress={openMenu} />}
        ></Menu>
      ) : null}
      {route.name !== 'DashBoard' ? (
        <Appbar.Content title={route.name} style={{ alignItems: 'flex-start' }} />
      ) : (
        <>
          <Appbar.Content title={`${fsp}   `} style={{ alignItems: 'flex-end' }} />
        </>
      )}
    </Appbar.Header>
  );
}
