/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { Card } from 'react-native-paper';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Activity from '../../../components/activityIndicator/ActivityIndicator';
import { AccountService } from '../../../components/common/account.service';
import { numberWithCommas } from '../../../components/common/common';

import Row, { Col } from '../../../components/Row';

function LoanSummary({ route }: any) {
  const LoanAccounts = useSelector((state) => (state as any).loanAccounts);

  const [summary, setSummary] = useState([]);
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const accountService = new (AccountService as any)();
  const { accountNo } = route.params;

  // console.log('accountNo',accountNo);
  const displayData = async () => {
    setLoader(true);
    await accountService.getLoanDetails(accountNo).then(async (res: any) => {
      const response = res.data;
      setData(response);
      setSummary(response.summary);
      setLoader(true);
    });
  };

  useEffect(() => {
    displayData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSummary = () => {
    return LoanAccounts.map((x: any, i: any) => {
      if (x.id === accountNo) {
        return (
          <View key={i}>
            <View style={{ marginLeft: 10 }}>
              <Card style={{ marginTop: 10 }}>
                <View style={{ marginLeft: 10 }}>
                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>A/C</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text>{x.accountNo}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Loan Product</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text>{x.productName}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Loan Purpose</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text>{(Data as any).loanPurposeName}</Text>
                    </Col>
                  </Row>
                </View>
              </Card>

              <Card style={styles.card}>
                <View style={styles.cardContent}>
                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Principal</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {numberWithCommas((Data as any).principal | 0)}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Interest</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {numberWithCommas((summary as any).interestCharged | 0)}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Fees</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {numberWithCommas(x.amountPaid)}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>penalties</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {(summary as any).penaltyChargesWaived}</Text>
                    </Col>
                  </Row>
                </View>
              </Card>

              <Card style={styles.card}>
                <View style={styles.cardContent}>
                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Total Expected Repayments</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {numberWithCommas(x.loanBalance)}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Total Paid</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {numberWithCommas(x.amountPaid)}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Interest Waive</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {(summary as any).interestWaived}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Penalties Waived</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {(summary as any).penaltyChargesWaived}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Fee Waived</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {(summary as any).feeChargesWaived}</Text>
                    </Col>
                  </Row>
                </View>
              </Card>

              <Card style={styles.card}>
                <View style={styles.cardContent}>
                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Outstanding Balance</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {numberWithCommas(x.loanBalance)}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 6, alignItems: 'flex-start' }}>
                      <Text style={styles.textStyle}>Account Status</Text>
                    </Col>

                    <Col customStyles={{ flex: 6 }}>
                      <Text> {x.status.value}</Text>
                    </Col>
                  </Row>
                </View>
              </Card>
            </View>
          </View>
        );
      }
    });
  };

  return (
    <ScrollView>
      {renderSummary()}
      {loader && (
        <View style={{ marginTop: '50%' }}>
          <Activity />
        </View>
      )}
    </ScrollView>
  );
}

export default LoanSummary;

const styles = StyleSheet.create({
  card: {
    marginTop: 20,
  },
  cardContent: {
    margin: 10,
  },
  textStyle: {
    fontWeight: 'bold',
  },
});
