
import { StyleSheet, Platform } from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';

export const styles = StyleSheet.create({
  buttonSize: {
    height: 55,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    padding: 30,
    height: '100%',
    backgroundColor: 'white',
  },
  disabledButton: {
    backgroundColor: '#a9a9a9',
  },
  amountView: {
    backgroundColor: 'white',
  },
  dropdownContainerStyle: {
    // borderWidth: 2,
    // borderColor: 'yellow',
    marginTop: -30,
  },
  dropdownSelectedStyle: {
    color: 'black',
  },
  loanButton: {
    alignItems: 'center',
    backgroundColor: '#62c341',
    padding: 10,
    borderRadius: 25,
    marginTop: 40,
    width: '95%',
  },

  headerLabel: {
    fontSize: 20,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  labels: {
    color: '#2679b0',
    fontSize: 15,
    fontWeight: 'bold',
  },
});

export default { styles };
