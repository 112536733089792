import React, { useState } from 'react';

import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import { MaterialIcons, Ionicons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';


import Row, { Col } from '../Row';
import { styles as DashboardStyles } from '../../screens/dashboard/DashboardStyles';
import { AccountService } from '../common/account.service';

export default function Navbar({ navigation }: any) {
  const accountService = new (AccountService as any)();
  const [fsp, setFsp] = useState('');
  const user = useSelector((state) => (state as any).loggedInUser);

  const getTenantIdentifier = async () => {
    const tenantId = await accountService.getTenantId();
    setFsp(tenantId);
  };
  getTenantIdentifier();
  return (
    <View style={[DashboardStyles.web, navStyles.container]}>
      <Row>
        <Col customStyles={{ flex: 2 }}>
          <Image style={[navStyles.logo]} source={require('../../assets/KBLogo.png')} />
        </Col>

        <Col
          customStyles={{
            flex: 4,
            alignItems: 'flex-end',
            paddingRight: 20,
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text>
                Financial Services Provider<Text style={{ fontWeight: 'bold' }}> : {fsp}</Text> |{' '}
                {'   '}
                <Text style={{ fontWeight: 'bold' }}>{user?.displayName}</Text>
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => {
                navigation.navigate('Profile');
              }}
            >
              <View>
                <MaterialIcons name="person" size={20} color="grey" style={navStyles.expoIcons} />
              </View>
            </TouchableOpacity>
          </View>
        </Col>
      </Row>
    </View>
  );
}

const navStyles = StyleSheet.create({
  container: {
    height: 60,
    backgroundColor: 'white',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.1,
    paddingTop: 10,
  },
  logo: {
    marginLeft: 20,
    marginTop: -7,
    width: 100,
    height: 30,
  },
  expoIcons: {
    marginTop: -3,
    marginLeft: 10,
  },
});
