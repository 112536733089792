/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import AccountsMobile from './AccountsMobile';
import AccountsWeb from './AccountsWeb';
import Navbar from '../../components/header/Navbar';
import { isPhone } from '../../components/common/devices';

export default function Accounts({ navigation, route }: any) {
  return (
    <>
      {!isPhone() ? (
        <AccountsMobile navigation={navigation} />
      ) : (
        <>
          <Navbar />

          <AccountsWeb navigation={navigation} route={route} />
        </>
      )}
    </>
  );
}
