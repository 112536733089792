import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Image,
  Button,
  Platform,
  TouchableOpacity,
  SafeAreaView,
  Pressable,
} from 'react-native';
import { DataTable, Card, TextInput } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import { Entypo } from '@expo/vector-icons';
import moment from 'moment';
import { useToast } from 'react-native-paper-toast';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Activity from '../../components/activityIndicator/ActivityIndicator';
// import { BottomSheet } from 'react-native-btr';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Row, { Col } from '../../components/Row';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';
import ChangePasswordModal from '../changePassword/ChangePasswordWeb';
import TrackScreenHit from "../../utils/analytics"


import { AccountService } from '../../components/common/account.service';
import ProfileCtrl from './Profile.component';

export default function UserProfile({ navigation }: any) {
  const toaster = useToast();
  const [fsp, setFsp] = useState('');
  const [loader, setLoader] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const profileImage = useSelector((state) => (state as any).image);
  const user = useSelector((state) => (state as any).loggedInUser);
  const profileCtrl = new (ProfileCtrl as any)();
  TrackScreenHit("Profile Page")


  const accountService = new (AccountService as any)();

  useEffect(() => {
    getTenantIdentifier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false]);

  const getTenantIdentifier = async () => {
    const tenantId = await accountService.getTenantId();
    setFsp(tenantId);
  };

  const apiHeaders = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const [image, setProf] = useState();
  const pickDocument = async () => {
    await DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    }).then((response: any) => {
      if (response.type === 'success') {
        const { file } = response;
        let { uri } = response;

        if (Platform.OS === 'android' && uri[0] === '/') {
          uri = `file://${uri}`;
          uri = uri.replace(/%/g, '%25');
        }

        setProf(file);
        
      }
    });
  };

  // New code for handling the form submission
  const handleSubmit = async () => {
    setLoader(true);
    try {
      const userdetails = await AsyncStorage.getItem('apiDocHeaders');
      let finalUserDetails = userdetails != null ? JSON.parse(userdetails) : null;

      if(user?.mobileNo){
        let mobileNumber = user?.mobileNo;
        if (mobileNumber.startsWith('0')) {
          mobileNumber = '256' + mobileNumber.substring(1);
        }

        const payload = {
          fsp: fsp,
          username: finalUserDetails.auth.username,
          password: finalUserDetails.auth.password,
          phoneNumber: mobileNumber,
        };

        accountService
          .whatsapp(payload, apiHeaders)
          .then((response: any) => {
            setLoader(false);
            toaster.show({
              message: 'Success: Whatsapp Enabled.',
              duration: 5000,
              type: 'success',
              position: 'middle',
            });

          })
          .catch((error: any) => {
            setLoader(false);
            toaster.show({
              message: 'User already registered.',
              duration: 5000,
              type: 'error',
              position: 'middle',
            });
            console.log(error, 'User not registered');
          });
      } else {
        setLoader(false);
        toaster.show({
          message: 'No phone number attached to user',
          duration: 5000,
          type: 'error',
          position: 'middle',
        });
      }

    } catch (error) {
      setLoader(false);
      console.error('Error sending payload:', error);
    }
  };

  const uploadFile = async () => {

    setFileLoader(true);

    const formData = new FormData();
    formData.append('file', image);

    // eslint-disable-next-line no-shadow
    const clientId = await accountService.getClientId();

    toaster.show({
      message: 'Uploading Profile Picture In Progress',
      duration: 5000,
      type: 'warning',
      position: 'middle',
    });

    accountService
      .profileUpload(clientId, formData, apiHeaders)
      .then((response: any) => {
        toaster.show({
          message: 'Your profile picture has been changed successfully.',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });
        setFileLoader(false);
        profileCtrl.getImage();
      })
      .catch((error: any) => {
        setFileLoader(false);
        console.log(error, 'Photo was not uploaded');
      });
  };

  return (
    <View style={[DashboardStyles.web]}>
      <Row>
        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <SideMenu navigation={navigation} page="Profile" />
        </Col>

        <Col customStyles={{ flex: 4 }}>
          <Row>
            <Col
              customStyles={{
                flex: 1,
              }}
            >
              <Card style={[DashboardStyles.card, { paddingLeft: 20 }]}>
                <ImageBackground
                  source={require('../../assets/kb-blue-background.png')}
                  style={[
                    {
                      flex: 1,
                      width: 200,
                      marginTop: 10,
                      paddingVertical: 100,
                      height: '90%',
                      position: 'absolute',
                      right: 0,
                    },
                  ]}
                />

                <Card.Content>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={styles.cardHeader}>Profile </Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <View style={styles.btnContainer}>
                        <ChangePasswordModal />
                      </View>
                    </Col>
                  </Row>

                  <SafeAreaView style={styles.image}>
                    <Image
                      source={{
                        uri: profileImage,
                      }}
                      style={styles.img}
                    />
                  </SafeAreaView>
                  <View style={styles.container}>
                    <DataTable style={{ width: 500 }}>
                      <DataTable.Row>
                        <DataTable.Cell>
                          <Text style={styles.profileName}>First Name :</Text>
                        </DataTable.Cell>
                        <DataTable.Cell>
                          <Text style={styles.innerText}>{user?.firstname}</Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell>
                          <Text style={styles.profileName}>Last Name :</Text>
                        </DataTable.Cell>
                        <DataTable.Cell>
                          <Text style={styles.innerText}>{user?.lastname}</Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell>
                          <Text style={styles.profileName}>SACCO/Investment Club :</Text>
                        </DataTable.Cell>
                        <DataTable.Cell>
                          <Text style={styles.innerText}>{fsp}</Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell>
                          <Text style={styles.profileName}>Activation Date :</Text>
                        </DataTable.Cell>
                        <DataTable.Cell>
                          <Text style={styles.innerText}>{moment(user.activationDate).format('DD/MM/YYYY')}</Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                    </DataTable>
                  </View>

                  <View>
                    <Pressable style={styles.SelectImagebutton} onPress={pickDocument}>
                      <Text style={styles.SelectImagetextStyle}>Select Image</Text>
                    </Pressable>
                    {fileLoader && <Activity />}
                    {image  && (<View style={{ flexDirection: 'row' }}>
                    <Pressable style={styles.SelectImagebutton} onPress={uploadFile}>
                      <Text style={styles.SelectImagetextStyle}>Upload Image</Text>
                    </Pressable>
                    </View>) }
                  </View>


                  <View>
                    {loader && <Activity />}
                    { !loader ?
                      <Pressable style={[styles.SelectImagebutton, {width:'30%'}]} onPress={handleSubmit}>
                        <Text style={styles.SelectImagetextStyle}>Enable Whatsapp ChatBot</Text>
                      </Pressable>
                    :null}
                  </View>

                </Card.Content>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  img: {
    width: 150,
    height: 150,
    borderWidth: 1,
    borderRadius: 150 / 2,
    borderColor: '#000',
    // backgroundColor: 'gray',
    //resizeMode: 'cover',
  },
  image: {
    flex: 1,
    alignItems: 'center',
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  btnContainer: {
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: 50,
  },
  btn: {
    backgroundColor: '#0782F9',
    width: '20%',
    textAlign: 'center',
    padding: 15,
    borderRadius: 5,
    margin: 10,
  },
  profileName: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  innerText: {
    color: 'grey',
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 20,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // display: 'none',
    // marginTop: 0,
    // marginBottom: 0,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    padding: 40,
  },

  ProfileText: {
    color: '#2679b0',
    fontWeight: 'bold',
    fontSize: 15,
  },
  choosePhoto: {
    width: '20%',
    padding: 20,
  },
  upload: {
    backgroundColor: '#0782F9',
    width: '17%',
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    borderRadius: 4,
  },
  text: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
    margin: 5,
  },
  inputs: {
    borderWidth: 1,
    width: 300,
    height: 40,
    padding: 10,
    margin: 10,
    color: '#000',
    fontSize: 16,
  },
  SelectImagebutton: {
    borderRadius: 4,
    padding: 10,
    width: '20%',
    elevation: 2,
    marginTop: 5,
    backgroundColor: '#2196F3',
  },
  SelectImagetextStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
