/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */

import { Text, View, ImageBackground, StyleSheet, Image, ActivityIndicator } from 'react-native';
import { Button, Card, Title, DataTable } from 'react-native-paper';

import React, { useState, useEffect } from 'react';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';
import Navbar from '../../components/header/Navbar';
import { Provider } from 'react-redux';
import { store } from '../../redux/index';

import { numberWithCommas } from '../../components/common/common';

import Row, { Col } from '../../components/Row';
import { AccountService } from '../../components/common/account.service';

function AccountLoanDetail({ navigation, route }: any) {
  const { loanId } = route?.params;
  //const loanId = 141;

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const [mode, setMode] = useState('transactions');

  const accountService = new (AccountService as any)();

  const getDisplayAcountDetail = async () => {
    setLoader(true);
    setLoader(true);
    await accountService.getLoanDetails(loanId).then(async (res: any) => {
      setLoader(false);
      const response = res.data;
      setData(response);
    });
  };

  useEffect(() => {
    getDisplayAcountDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <View>
        <Navbar navigation={navigation} />
      </View>
      <View style={[DashboardStyles.web]}>
        <Row>
          <Col customStyles={{ flex: 1.2, alignItems: 'center' }}>
            <Provider store={store}>
              <SideMenu navigation={navigation} style={{ height: '30px' }} />
            </Provider>
          </Col>

          <Col customStyles={{ flex: 4, paddingTop: '10px' }}>
            <ImageBackground
              source={require('../../assets/kb-blue-background.png')}
              style={[
                {
                  flex: 1,
                  width: 200,
                  marginTop: 10,
                  paddingVertical: 100,
                  height: '90%',
                  position: 'absolute',
                  right: 0,
                },
              ]}
            />
            {loader ? (
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <ActivityIndicator size="large" color="blue" />
              </View>
            ) : (
              <View style={{ flexDirection: 'column', flex: 2.3 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Card style={[styles.card, { paddingLeft: 20, height: '200px' }]}>
                    <Card.Content>
                      <Row>
                        <Col customStyles={{ flex: 1 }}>
                          <View style={{ width: '90%' }}>
                            <Row>
                              <Col customStyles={{ flex: 1 }}>
                                <Image
                                  style={[styles.dashboardIcons]}
                                  source={require('../../assets/apply_loan.png')}
                                />
                              </Col>

                              <Col customStyles={{ flex: 2 }}>
                                <View style={{ marginLeft: '6%' }}>
                                  <Title style={[styles.savingsText]}>Total Loan</Title>
                                  <Text
                                    style={[
                                      styles.currencyText,
                                      { color: '#5eb158', marginTop: '6px', marginBottom: '6px' },
                                    ]}
                                  >
                                    {numberWithCommas(
                                      (data as any)?.repaymentSchedule?.totalOutstanding || 0
                                    )}
                                  </Text>
                                  <Text style={styles.versionSubtitle}>Outstanding Balance</Text>
                                </View>
                              </Col>
                              <Col>
                                <View style={[styles.verticalDivider]} />
                              </Col>
                            </Row>
                          </View>
                        </Col>
                        <Col customStyles={{ flex: 1.4 }}>
                          <View style={{ width: '90%' }}>
                            <Row>
                              <Col customStyles={{ flex: 2 }}>
                                <Text style={styles.modeText}>
                                  Amount: {numberWithCommas((data as any)?.approvedPrincipal || 0)}
                                </Text>
                                <Text style={styles.modeText}>
                                  Currenty: {(data as any)?.currency?.name}
                                </Text>
                                <Text style={styles.modeText}>
                                  Proposed Amount:{' '}
                                  {numberWithCommas((data as any)?.proposedPrincipal || 0)}
                                </Text>
                                <Text style={styles.modeText}>
                                  Approved Amount:{' '}
                                  {numberWithCommas((data as any)?.approvedPrincipal || 0)}
                                </Text>
                                <Text style={styles.modeText}>
                                  Disbursement Amount:{' '}
                                  {numberWithCommas((data as any)?.netDisbursalAmount || 0)}
                                </Text>
                                <Text style={styles.modeText}>
                                  Submitted on:{' '}
                                  {`${(data as any)?.timeline?.submittedOnDate[2]} / ${
                                    (data as any)?.timeline?.submittedOnDate[1]
                                  } / ${(data as any)?.timeline?.submittedOnDate[0]}`}
                                </Text>
                              </Col>

                              <Col customStyles={{ flex: 2 }}>
                                <Text style={styles.modeText}>
                                  Approved on:{' '}
                                  {`${(data as any)?.timeline?.approvedOnDate[2]} / ${
                                    (data as any)?.timeline?.approvedOnDate[1]
                                  } / ${(data as any)?.timeline?.approvedOnDate[0]}`}
                                </Text>
                                <Text style={styles.modeText}>
                                  Disbursed on:{' '}
                                  {`${(data as any)?.timeline?.expectedDisbursementDate[2]} / ${
                                    (data as any)?.timeline?.expectedDisbursementDate[1]
                                  } / ${
                                    (data as any as any)?.timeline?.expectedDisbursementDate[0]
                                  }`}
                                </Text>
                                <Text style={styles.modeText}>
                                  Matures on:{' '}
                                  {`${(data as any as any)?.timeline?.expectedMaturityDate[2]} / ${
                                    (data as any as any)?.timeline?.expectedMaturityDate[1]
                                  } / ${(data as any as any)?.timeline?.expectedMaturityDate[0]}`}
                                </Text>
                                <Text style={styles.modeText}>Installment Amount: </Text>
                                <Text style={styles.modeText}>
                                  Recalculate Interest:{' '}
                                  {`${(data as any)?.isInterestRecalculationEnabled}`}
                                </Text>
                              </Col>
                            </Row>
                          </View>
                        </Col>
                        <Col customStyles={{ flex: 0.6 }}>
                          <View style={{ paddingLeft: '5%', paddingTop: '50px' }}>
                            <Button
                              style={{ shadowColor: '#009900', shadowRadius: 10, shadowOpacity: 1 }}
                              mode="contained"
                              color="#009900"
                              onPress={() =>
                                navigation.navigate('Transfer Deposit', {
                                  accountId: (data as any)?.id,
                                  accountType: 'Loans',
                                })
                              }
                            >
                              Make Payment
                            </Button>
                          </View>
                        </Col>
                      </Row>
                    </Card.Content>
                  </Card>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <View style={{ marginTop: '25px', marginBottom: '25px', width: '100%' }}>
                    <Col
                      customStyles={{
                        flex: 1,
                        marginBottom: '-14px',
                      }}
                    >
                      <Row>
                        <Col
                          customStyles={{
                            flex: 1,
                          }}
                        >
                          <Button
                            onPress={() => setMode('transactions')}
                            style={[
                              mode === 'transactions' ? styles.active : null,
                              { height: '50px', paddingTop: '5px' },
                            ]}
                            color={mode === 'transactions' ? '#fff' : 'rgb(41, 113, 182)'}
                          >
                            Transactions
                          </Button>
                        </Col>
                        <Col
                          customStyles={{
                            flex: 1,
                          }}
                        >
                          <Button
                            onPress={() => setMode('account_details')}
                            style={[
                              mode === 'account_details' ? styles.active : null,
                              {
                                height: '50px',
                                paddingTop: '5px',
                                borderBottomWidth: 2,
                                borderBottomColor: 'lightgrey',
                              },
                            ]}
                            color={mode === 'account_details' ? '#fff' : 'rgb(41, 113, 182)'}
                          >
                            Account Details
                          </Button>
                        </Col>

                        <Col
                          customStyles={{
                            flex: 1,
                          }}
                        >
                          <Button
                            onPress={() => setMode('repayment_schedule')}
                            style={[
                              mode === 'repayment_schedule' ? styles.active : null,
                              { height: '50px', paddingTop: '5px' },
                            ]}
                            color={mode === 'repayment_schedule' ? '#fff' : 'rgb(41, 113, 182)'}
                          >
                            Repayment Schedule
                          </Button>
                        </Col>

                        <Col
                          customStyles={{
                            flex: 1,
                          }}
                        >
                          
                        </Col>
                      </Row>
                    </Col>
                    <View style={[styles.horizontalDivider]} />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: '15px',
                    width: '100%',
                    height: '340px',
                  }}
                >
                  {mode === 'account_details' ? (
                    <Card style={[styles.card, { paddingLeft: 20 }]}>
                      <Card.Content>
                        <Row>
                          <Col customStyles={{ flex: 1 }}>
                            <View>
                              <View style={[styles.details]}>
                                <Row>
                                  <Col customStyles={{ flex: 0.7 }}>
                                    <Text style={[styles.detailText]}>Repayment Strategy</Text>
                                  </Col>
                                  <Col customStyles={{ flex: 1 }}>
                                    <Text style={[styles.detailText]}>
                                      {(data as any)?.transactionProcessingStrategyName}
                                    </Text>
                                  </Col>
                                </Row>
                              </View>
                              <View style={[styles.horizontalDivider, { width: '95%' }]} />
                            </View>
                            <View>
                              <View style={[styles.details]}>
                                <Row>
                                  <Col customStyles={{ flex: 0.7 }}>
                                    <Text style={[styles.detailText]}>Amortization</Text>
                                  </Col>
                                  <Col customStyles={{ flex: 1 }}>
                                    <Text style={[styles.detailText]}>
                                      {(data as any)?.amortizationType?.value}
                                    </Text>
                                  </Col>
                                </Row>
                              </View>
                              <View style={[styles.horizontalDivider, { width: '95%' }]} />
                            </View>
                            <View>
                              <View style={[styles.details]}>
                                <Row>
                                  <Col customStyles={{ flex: 0.7 }}>
                                    <Text style={[styles.detailText]}>
                                      Interest Calculation Period
                                    </Text>
                                  </Col>
                                  <Col customStyles={{ flex: 1 }}>
                                    <Text style={[styles.detailText]}>
                                      {(data as any)?.interestCalculationPeriodType?.value}
                                    </Text>
                                  </Col>
                                </Row>
                              </View>
                              <View style={[styles.horizontalDivider, { width: '95%' }]} />
                            </View>
                          </Col>

                          <Col customStyles={{ flex: 1 }}>
                            <View>
                              <View style={[styles.details]}>
                                <Row>
                                  <Col customStyles={{ flex: 0.7 }}>
                                    <Text style={[styles.detailText]}>Repayments</Text>
                                  </Col>
                                  <Col customStyles={{ flex: 1 }}>
                                    <Text style={[styles.detailText]}>{`${
                                      (data as any)?.numberOfRepayments
                                    } every ${(data as any)?.repaymentEvery} ${
                                      (data as any)?.repaymentFrequencyType?.value
                                    } on `}</Text>
                                  </Col>
                                </Row>
                              </View>
                              <View style={[styles.horizontalDivider, { width: '95%' }]} />
                            </View>
                            <View>
                              <View style={[styles.details]}>
                                <Row>
                                  <Col customStyles={{ flex: 0.7 }}>
                                    <Text style={[styles.detailText]}>Interest</Text>
                                  </Col>
                                  <Col customStyles={{ flex: 1 }}>
                                    <Text style={[styles.detailText]}>{`${
                                      (data as any)?.annualInterestRate
                                    } per annum (${
                                      (data as any)?.interestRatePerPeriod
                                    } Per month) - ${(data as any)?.interestType?.value}`}</Text>
                                  </Col>
                                </Row>
                              </View>
                              <View style={[styles.horizontalDivider, { width: '95%' }]} />
                            </View>
                            <View>
                              <View
                                style={[
                                  styles.details,
                                  { paddingBottom: '12px', paddingTop: '12px' },
                                ]}
                              >
                                <Row>
                                  <Col customStyles={{ flex: 1 }}>
                                    <Text style={[styles.detailText]}>
                                      Allow Partial Interest Calcualtion with same as repayment
                                    </Text>
                                  </Col>
                                  <Col customStyles={{ flex: 1 }}>
                                    <Text style={[styles.detailText]}>
                                      {`${(data as any)?.allowPartialPeriodInterestCalcualtion}`}
                                    </Text>
                                  </Col>
                                </Row>
                              </View>
                              <View style={[styles.horizontalDivider, { width: '95%' }]} />
                            </View>
                          </Col>
                        </Row>
                      </Card.Content>
                    </Card>
                  ) : mode === 'repayment_schedule' ? (
                    <Card style={[styles.card, { paddingLeft: 20 }]}>
                      <Card.Content>
                        <DataTable>
                          <DataTable.Header style={{ backgroundColor: '#f2f7fb' }}>
                            <DataTable.Title>
                              <Text style={styles.title}>Due Date</Text>
                            </DataTable.Title>
                            <DataTable.Title>
                              <Text style={styles.title}>Principal Due</Text>
                            </DataTable.Title>
                            <DataTable.Title>
                              <Text style={styles.title}>Principal Loan Balance Outstanding</Text>
                            </DataTable.Title>
                          </DataTable.Header>

                          {(data as any)?.repaymentSchedule?.periods.map((value: any, key: any) => (
                            <DataTable.Row>
                              <DataTable.Cell>
                                <Text style={styles.text}>
                                  {`${value?.dueDate[2]} / ${value?.dueDate[1]} / ${value?.dueDate[0]}`}
                                </Text>
                              </DataTable.Cell>
                              <DataTable.Cell>
                                <Text style={styles.text}>{`${
                                  value?.principalOriginalDue || 0
                                }`}</Text>
                              </DataTable.Cell>
                              <DataTable.Cell>
                                <Text style={styles.text}>
                                  {`${value?.principalLoanBalanceOutstanding || 0}`}
                                </Text>
                              </DataTable.Cell>
                            </DataTable.Row>
                          ))}
                        </DataTable>
                      </Card.Content>
                    </Card>
                  ) : mode === 'transactions' ? (
                    <Card style={[styles.card, { paddingLeft: 20 }]}>
                      <Card.Content>
                        <DataTable>
                          <DataTable.Header style={{ backgroundColor: '#f2f7fb' }}>
                            <DataTable.Title>
                              <Text style={styles.title}>Type</Text>
                            </DataTable.Title>
                            <DataTable.Title>
                              <Text style={styles.title}>Amount</Text>
                            </DataTable.Title>
                            <DataTable.Title>
                              <Text style={styles.title}>Date</Text>
                            </DataTable.Title>
                          </DataTable.Header>

                          {(data as any)?.transactions?.map((value: any, key: any) => (
                            <DataTable.Row>
                              <DataTable.Cell>
                                <Text style={styles.text}>{`${value?.type?.value}`}</Text>
                              </DataTable.Cell>
                              <DataTable.Cell>
                                <Text style={styles.text}>{`${
                                  numberWithCommas(value?.amount) || 0
                                }`}</Text>
                              </DataTable.Cell>
                              <DataTable.Cell>
                                <Text style={styles.text}>
                                  {`${value?.date[2]} / ${value?.date[1]} / ${value?.date[0]}`}
                                </Text>
                              </DataTable.Cell>
                            </DataTable.Row>
                          ))}
                        </DataTable>
                      </Card.Content>
                    </Card>
                  ) : null}
                </View>
              </View>
            )}
          </Col>
        </Row>

        <Footer />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  active: {
    backgroundColor: 'rgb(41, 113, 182)',
  },
  title: {
    color: 'grey',
    fontSize: 14,
  },
  text: {
    fontSize: 13,
    padding: 10,
  },
  detailText: {
    color: 'grey',
    fontSize: 13,
  },
  details: {
    width: '95%',
    paddingBottom: '20px',
    paddingTop: '20px',
    fontSize: 14,
  },
  horizontalDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 5,
    width: '97%',
  },
  verticalDivider: {
    borderLeftWidth: 2,
    borderLeftColor: 'lightgrey',
    marginRight: '5px',
    height: '120px',
  },
  versionSubtitle: {
    color: '#a0a0a0',
    marginTop: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  modeText: {
    color: '#a0a0a0',
    marginTop: 5,
    fontSize: 11,
    fontWeight: 'bold',
  },
  currencyText: {
    fontWeight: 'bold',
    color: '#606060',
    fontSize: 23,
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
  },
  savingsText: {
    fontWeight: 'bold',
    color: 'grey',
    fontSize: 16,
  },
  dashboardIcons: {
    width: 100, //width: '95%': savings
    height: 40, //height: 90,: savings
    marginTop: 20,
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 20,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#62c341',
    padding: 10,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: 'grey',
    fontWeight: 'bold',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 80,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
  card: {
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.1,
    width: '98%',
  },
});

export default AccountLoanDetail;
