import { View, Text, ScrollView, StyleSheet, Pressable, Platform } from 'react-native';
import React, { useState } from 'react';
import { TextInput } from 'react-native-paper';

import { AccountService } from '../../components/common/account.service';

import Row, { Col } from '../../components/Row';

export default function ValidateRegisterMobile({ navigation }: any) {
  const [fsp, setFsp] = useState('');
  const [authenticationToken, setAuthenticationToken] = useState('');
  const [requestId, setRequestId] = useState('');

  const accountService = new (AccountService as any)();

  const handleSubmit = async () => {
    const data = {
      authenticationToken,
      requestId
    };

    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': fsp,
      },
    };

    await accountService
      .validate(data, apiHeaders)
      .then((res: any) => {
        console.log('data submitted', res.data);
        return res.data;
      })
      .catch((err: any) => {
        console.log('registration failed', err);
      });
  };
  return (
    <ScrollView>
      <View style={[styles.mobile, {height:'100%'}]}>
        <View>
          <Row>
            <Col customStyles={{ flex: 1, height:'100%' }}>
              {/* <Image source={require('../../assets/KBLogo.png')} style={styles.kbl} /> */}

              <Text style={styles.text}>Please enter details provided in email sent</Text>
            </Col>
          </Row>

          <View style={styles.inputView}>
            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Financial Institution"
                  placeholder="Financial Institution"
                  style={styles.input}
                  onChangeText={(e: any) => setFsp(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Request Id"
                  placeholder="Request Id"
                  style={styles.input}
                  onChangeText={(e: any) => setRequestId(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <TextInput
                  label="Authentication Token"
                  placeholder="Authentication Token"
                  style={styles.input}
                  onChangeText={(e: any) => setAuthenticationToken(e)}
                />
              </Col>
            </Row>

            <Pressable style={styles.btn} onPress={handleSubmit}>
              <Text style={styles.reg}>CREATE ACCOUNT</Text>
            </Pressable>

          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  inputView: {
    flex: 1,
    margin: 20,
  },
  input: {
    backgroundColor: 'transparent',
  },
  kbl: {
    height: 80,
    width: '80%',
    marginTop: 5,
  },
  text: {
    margin: 20,
    fontSize: 20,
    fontWeight: '300',
    color: '#2196F3',
  },
  btn: {
    borderRadius: 4,
    padding: 10,
    elevation: 2,
    backgroundColor: '#2196F3',
    width: '100%',
    alignItems: 'center',
  },
  reg: { color: 'white', fontSize: 16, letterSpacing: 5 },
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
});
