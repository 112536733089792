/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  SafeAreaView,
  TouchableHighlight,
  ScrollView,
} from 'react-native';

import moment from 'moment';
import { Card, TextInput } from 'react-native-paper';
import { useToast } from 'react-native-paper-toast';
// import * as FileSystem from 'expo-file-system';

import DropDown from 'react-native-paper-dropdown';
// import { numberWithCommas } from '../../components/common/common';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import {styles} from './ShareStyles';

import Activity from '../../components/activityIndicator/ActivityIndicator';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';
import { AccountService } from '../../components/common/account.service';
import TrackScreenHit from '../../utils/analytics';

import Row, { Col } from '../../components/Row';

export default function ShareWeb({ navigation }: any) {
  const SavingsAccounts = useSelector((state) => (state as any).savingsAccounts);
  const toaster = useToast();
  const productOptions = useSelector((state) => (state as any).shareProductList.pageItems);
  const accountService = new (AccountService as any)();
  const [loader, setLoader] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [numberOfShares, setNumberOfShares] = useState(0);

  TrackScreenHit('Shares Page');

  const [shareProducts, setShareProducts] = useState([]);
  const [savingsAccounts, setSavingsAccounts] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showAccountsDropDown, setShowAccountsDropDown] = useState(false);
  const [shareProduct, setShareProduct] = useState('');
  const [savingsAccount, setSavingsAccount] = useState('');
  const [finalSavingsAccounts, setFinalSavingsAccounts] = useState([])
  
  useEffect(() => {

    const tempSavingsAccounts: any = []; 

    if (savingsAccounts) {
      savingsAccounts.forEach((i: any) => {
        tempSavingsAccounts.push({ label: i.accountNo, value: i.id });
      });
    }

    setFinalSavingsAccounts(tempSavingsAccounts)
    
  }, [savingsAccounts])

  useEffect(() => {
    const tempProducts: any = [];

    if (productOptions) {
      productOptions.forEach((i: any) => {
        tempProducts.push({ label: i.name, value: i.id });
      });
    }
    setShareProducts(tempProducts);

  }, []);


  const onChangeShareProducts = async (productId: any) => {
    setIsDone(false);
    const clientId = await accountService.getClientId();
    getShareTemplate(clientId, productId);
    setShareProduct(productId);
  };

  const onChangeSavingsAccount = async (productId: any) => {
    setIsDone(false);
    const clientId = await accountService.getClientId();
    getShareTemplate(clientId, productId);
    setSavingsAccount(productId);
  };

  const onSubmit = async () => {
    setLoader(true);

    const clientId = await accountService.getClientId();

    const shareApplicationData = {
      "clientId":clientId,
      "productId": shareProduct,
      "requestedShares": numberOfShares,
      "submittedDate": moment(new Date()).format('DD MMMM YYYY'),
      "applicationDate": moment(new Date()).format('DD MMMM YYYY'),
      "savingsAccountId": parseInt(savingsAccount),
      "locale":"en",
      "dateFormat":"dd MMMM yyyy"
    };
    
    accountService
      .shares(shareApplicationData)
      .then((resp: any) => {
        // Handling success
        const shareResponse = resp.data;

        toaster.show({
          message: 'Share Application Submitted Successfully.',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });

        setLoader(false);
      })
      .catch((error: any) => {
        // Handling error
        setLoader(false);
        const text = 'Share Application Not Submitted Kindly contact the Maintainer ';
        console.log(error, text);
      });
  };

  function getShareTemplate(clientId: any, productId: any) {
    const individualTemplate = {
      clientId,
      productId,
    };

    accountService
      .SharesTemplate(individualTemplate)
      .then((res: any) => {
        setNumberOfShares(res.data.productOptions[0].nominalShares);
        setIsDone(true);
      })
      .catch((error: any) => {
        const text = 'Share Application template Not Found ';
        console.log(text)
      });

      setSavingsAccounts(SavingsAccounts)
  }
  
  return (
    <View style={[DashboardStyles.web]}>
      <Row>
        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <SideMenu navigation={navigation} page="Share" />
        </Col>

        <Col customStyles={{ flex: 4 }}>
          {/* <View> */}

          <Row>
            <Col
              customStyles={{
                flex: 1,
              }}
            >
              <Card style={[DashboardStyles.card, { paddingLeft: 20 }]}>
                <ImageBackground
                  source={require('../../assets/kb-blue-background.png')}
                  style={[
                    {
                      flex: 1,
                      width: 200,
                      marginTop: 10,
                      paddingVertical: 100,
                      height: '90%',
                      position: 'absolute',
                      right: 0,
                    },
                  ]}
                />

                <Card.Content>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={styles.cardHeader}>Apply For Shares</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '60%' }}>
                      <Row>
                        <Col customStyles={{ flex: 1 }}>
                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>
                                Share Product
                                {<Text style={{color: 'red'}}>*</Text>}
                              </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Row>
                                <Col customStyles={{ flex: 1 }}>
                                  <SafeAreaView>
                                    <DropDown
                                      placeholder="Select Share Product"
                                      mode="outlined"
                                      visible={showDropDown}
                                      showDropDown={() => setShowDropDown(true)}
                                      onDismiss={() => setShowDropDown(false)}
                                      value={shareProduct}
                                      setValue={(value) => onChangeShareProducts(value)}
                                      list={shareProducts}
                                    />
                                  </SafeAreaView>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>
                                Number of Shares
                                {<Text style={{color: 'red'}}>*</Text>}
                              </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <TextInput
                                placeholder="Number Of Shares"
                                value={numberOfShares.toString()}
                                onChangeText={(e: any) => setNumberOfShares(parseInt(e, 10))}
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>
                                Savings Account
                                {<Text style={{color: 'red'}}>*</Text>}
                              </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Row>
                                <Col customStyles={{ flex: 1 }}>
                                  <SafeAreaView>
                                    <DropDown
                                      placeholder="Select Savings Account"
                                      mode="outlined"
                                      visible={showAccountsDropDown}
                                      showDropDown={() => setShowAccountsDropDown(true)}
                                      onDismiss={() => setShowAccountsDropDown(false)}
                                      value={savingsAccount}
                                      setValue={(value) => onChangeSavingsAccount(value)}
                                      list={finalSavingsAccounts}
                                    />
                                  </SafeAreaView>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '75%' }}>
                      <View style={styles.divider} />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      {loader && <Activity />}

                      <TouchableHighlight onPress={() => onSubmit()} disabled={loader}>
                        <View style={[styles.depositButton, loader && styles.disabledButton]}>
                          <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Send'}</Text>
                        </View>
                      </TouchableHighlight>
                    </Col>
                  </Row>
                </Card.Content>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </View>
  );
}


