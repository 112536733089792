/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React from 'react';

import { View, Text, StyleSheet, SafeAreaView, ScrollView } from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { Card } from 'react-native-paper';
import { useSelector } from 'react-redux';

import { Col } from '../../components/Row';
import { numberWithCommas } from '../../components/common/common';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';

export default function RecentTransactionsMobile({ navigation }: any) {
  const transactions = useSelector((state) => (state as any).transactions);

  const recentTxt = () => {
    const dates: any = [];
    transactions.map((x: any) => {
      if (dates.includes(x.transactionDate) === false) dates.push(x.transactionDate);
    });

    // @ts-expect-error TS(7006): Parameter 'x' implicitly has an 'any' type.
    const renderView = dates.map((x, i) => {
      return (
        <View key={i} style={{ padding: 10, paddingTop: 5 }}>
          <View style={[{ marginTop: 0 }]}>
            <Text style={DashboardStyles.dateLabel}>{x}</Text>
          </View>

          {transactions.map((j: any, k: any) => {
            if (j.transactionDate === x) {
              return (
                <View key={k}>
                  <Card>
                    <Card.Content>
                      <View
                        style={[styles.accountCard, j.plusMinus ? { borderColor: 'green' } : null]}
                      >
                        <Col
                          customStyles={{
                            flex: 1,
                            paddingLeft: 10,
                          }}
                        >
                          <Text>{j.transactionType}</Text>

                          <Text>{j.accountType}</Text>

                          <Text>17 Mar 2020</Text>
                        </Col>

                        <Col
                          customStyles={{
                            flex: 1,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                          }}
                        >
                          <Text style={[styles.amount]}>
                            {j.plusMinus ? '+' : '-'} UGX {numberWithCommas(j.amount)}
                          </Text>
                        </Col>
                      </View>
                    </Card.Content>
                  </Card>
                </View>
              );
            }
          })}
        </View>
      );
    });
    return renderView;
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View>
          {transactions.length > 0 ? (
            recentTxt()
          ) : (
            <Text style={{ fontWeight: 'bold', marginLeft: 10, paddingLeft: 10 }}>
              No Recent Transactions{' '}
            </Text>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingLeft: 5,
    paddingRight: 5,
  },

  accountCard: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    borderLeftWidth: 4,
    borderColor: 'red',
  },
  amount: {
    color: '#2679b0',
  },
});
