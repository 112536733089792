/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import {
  StyleSheet,
  Text,
  View,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Image,
} from 'react-native';
import { Card } from 'react-native-paper';
import React from 'react';
import { useSelector } from 'react-redux';
import Row, { Col } from '../../../components/Row';
import { numberWithCommas } from '../../../components/common/common';

function LoanDetail({ navigation, route }: any) {
  const { accountNo } = route.params;
  const LoanAccount = useSelector((state) => (state as any).loanAccounts);

  return (
    <ScrollView>
      <View >
        {LoanAccount.map((p: any, k: any) => {
          if (p.accountNo === accountNo) {
            return (
              <View key={k}>
                <Card>
                  <View style={{ margin: 10, padding: 10 }} key={k}>
                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <Text style={styles.text}>OutStanding Balance</Text>

                        <Text style={styles.compoText}>{numberWithCommas(p.loanBalance)}</Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <Text style={styles.text}>Account Number</Text>

                        <Text style={styles.compoText}>{p.accountNo}</Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <Text style={styles.text}>Loan Type</Text>

                        <Text style={styles.compoText}>{p.loanType.value}</Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <Text style={styles.text}>Currency</Text>

                        <Text style={styles.compoText}>UGX</Text>
                      </Col>
                    </Row>

                    <View style={{ alignItems: 'flex-end' }}>
                      <View>
                        <Button
                          title="Deposit"
                          onPress={() => navigation.navigate(
                            'Make Deposit', 
                            { 
                              accountId: p.id,
                              accountType:'Loans' 
                            })}
                        />
                      </View>
                    </View>
                  </View>
                </Card>

                <Text style={{ paddingLeft: 10, marginTop: 10, fontSize: 16 }}>Monitors</Text>

                <Card>
                  <View style={{ margin: 10 }}>
                    <Row>
                      <Col customStyles={{ flex: 2, alignItems: 'flex-start' }}>
                        <Image
                          style={{ height: 40, width: 40 }}
                          source={require('../../../assets/apply_loan.png')}
                        />
                      </Col>

                      <Col customStyles={{ flex: 12, paddingTop: 5 }}>
                        <TouchableWithoutFeedback
                          onPress={() => navigation.navigate('Loan Summary', { accountNo: p.id })}
                        >
                          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Loan Summary</Text>
                        </TouchableWithoutFeedback>

                        <Text>View loan summary</Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col customStyles={{ flex: 2, alignItems: 'flex-start' }}>
                        <Image
                          style={{ height: 40, width: 40 }}
                          source={require('../../../assets/apply_loan.png')}
                        />
                      </Col>

                      <Col customStyles={{ flex: 12, paddingTop: 5 }}>
                        <TouchableWithoutFeedback
                          onPress={() => navigation.navigate('Loan Charges', { accountNo: p.id })}
                        >
                          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Loan Charges</Text>
                        </TouchableWithoutFeedback>

                        <Text>View Charges</Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col customStyles={{ flex: 2, alignItems: 'flex-start' }}>
                        <Image
                          style={{ height: 40, width: 40 }}
                          source={require('../../../assets/schedule.png')}
                        />
                      </Col>

                      <Col customStyles={{ flex: 12, paddingTop: 5 }}>
                        <TouchableWithoutFeedback
                          onPress={() => navigation.navigate('Loan Repayment', { loanId: p.id })}
                        >
                          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                            Repayment Schedule
                          </Text>
                        </TouchableWithoutFeedback>

                        <Text>View repayment Schedule</Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col customStyles={{ flex: 2, alignItems: 'flex-start' }}>
                        <Image
                          style={{ height: 40, width: 40 }}
                          source={require('../../../assets/accounts.png')}
                        />
                      </Col>

                      <Col customStyles={{ flex: 12, paddingTop: 5 }}>
                        <TouchableWithoutFeedback
                          onPress={() =>
                            navigation.navigate('Loan Transaction', { accountNo: p.accountNo })
                          }
                        >
                          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Transactions</Text>
                        </TouchableWithoutFeedback>

                        <Text>View transactions</Text>
                      </Col>
                    </Row>
                  </View>
                </Card>
              </View>
            );
          }
        })}
      </View>
    </ScrollView>
  );
}

export default LoanDetail;

const styles = StyleSheet.create({
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
  text: {
    fontSize: 18,
    letterSpacing: 1,
    fontWeight: '800',
    color: 'rgb(30,136,229)'
  },
  compoText: {
    fontSize: 14,
    paddingTop: 5,
    fontWeight: '500',
  },
});
