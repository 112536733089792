/* eslint-disable default-param-last */
import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { Constants } from '../Constants/Constants';

const { LOADING_TEXT } = Constants;

const initialState = {
  savingsAccounts: [],
  image: 'https://pbs.twimg.com/profile_images/952545910990495744/b59hSXUd_400x400.jpg',
  loanAccounts: [],
  shareAccounts: [],
  transactions: [],
  totalSavings: LOADING_TEXT,
  totalLoan: LOADING_TEXT,
  Loggedin: false,
  selfReportedTransactions: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_LOAN_ACCOUNTS':
      return { ...state, loanAccounts: action.loanAccounts };

    case 'SET_SAVINGS_ACCOUNTS':
      return { ...state, savingsAccounts: action.savingsAccounts };
    case 'SET_SHARE_ACCOUNTS':
      return { ...state, shareAccounts: action.shareAccounts };
    case 'SET_TOTAL_SAVINGS':
      return { ...state, totalSavings: action.totalSavings };

    case 'SET_TOTAL_LOAN':
      return { ...state, totalLoan: action.totalLoan };

    case 'SET_TRANSACTIONS':
      return { ...state, transactions: action.transactions };

    case 'SET_IMAGE':
      return { ...state, image: action.image.replace(/(\r\n|\n|\r)/gm, "") };

    case 'SET_LOGIN_USER':
      return { ...state, loggedInUser: action.loggedInUser };

    case 'SET_LOGIN_TENANT':
      return { ...state, loggedInTenant: action.loggedInTenant };

    case 'SET_PRODUCT_LIST':
      return { ...state, productList: action.productList };

    case 'SET_SHARE_PRODUCT_LIST':
      return { ...state, shareProductList: action.shareProductList };

    case 'SET_SELF_REPORTED_TRANSACTIONS':
      return { ...state, selfReportedTransactions: action.selfReportedTransactions };

    // case 'SET_LOGOUT':
    //   return { ...state, Loggedin: false };

    // no default
  }
  return state;
};

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: [
    'savingsAccounts',
    'image',
    'loanAccounts',
    'shareAccounts',
    'transactions',
    'Loggedin',
    'totalSavings',
    'totalLoan',
  ],
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, reducer as any);
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
