import {
  Modal,
  StyleSheet,
  Text,
  Pressable,
  View,
  Image,
  Dimensions,
  ImageBackground,
  ActivityIndicator,
} from 'react-native';
import { TextInput, Button, Card } from 'react-native-paper';
import React, { useState } from 'react';
import { useToast } from 'react-native-paper-toast';

import { Constants } from '../../Constants/Constants';

import Row, { Col } from '../../components/Row';

const { BASE_URL } = Constants;

const axios = require('axios');

const ScreenHeight = Dimensions.get('window').height;

export default function ForgotPassword({ navigation }: any) {
  const toaster = useToast();

  const [loader, setLoader] = useState(false);
  const [fsp, setFsp] = useState();
  const [email, setEmail] = useState();

  const fontColor = 'rgb(30,136,229)';

  const onSubmit = async () => {
    setLoader(true)
    const data = { email };

    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': fsp,
      },
    };

    await axios
      .post(`${BASE_URL}self/forgot-password`, data, apiHeaders)
      .then(async (response: any) => {
        toaster.show({
          message: 'Reset password Link Sent',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });
        navigation.navigate('Login')
        setLoader(false)
        return response.data;
      })
      .catch((error: any) => {
        toaster.show({
          message: 'Invalid Financial Service Provider or Email',
          duration: 5000,
          type: 'error',
          position: 'middle',
        });
        setLoader(false)
      });
  };

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          height: ScreenHeight,
        }}
      >
        <Col
          customStyles={{ flex: 2, shadowColor: '#000000', shadowRadius: 10, shadowOpacity: 0.1 }}
        >
          <ImageBackground
            source={require('../../assets/cover.jpg')}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <View style={styles.view}>
              <Card style={styles.card}>
                <Row>
                  <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                    <Image source={require('../../assets/kanzubanking_logo.png')} style={styles.logo} />
                    <Text style={styles.header}>Recover your Password</Text>
                  </Col>
                </Row>

                {loader && (
                  <View style={{ flex: 1, justifyContent: 'center' }}>
                    <ActivityIndicator size="large" color="blue" />
                  </View>
                )}
                <TextInput
                  placeholder="Financial Services Provider"
                  label="Financial Services Provider"
                  style={styles.textInput}
                  onChangeText={(e: any) => setFsp(e)}
                />

                <TextInput
                  placeholder="Email Address"
                  label="Email Address"
                  style={styles.textInput}
                  onChangeText={(e: any) => setEmail(e)}
                />

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <View style={{ margin: 12 }}>
                      <Button mode="contained" onPress={() => onSubmit()}>
                      SEND RESET LINK
                      </Button>
                      <Button
                      mode="text"
                      uppercase={false}
                      color={fontColor}
                      onPress={() => navigation.navigate('Login')}
                    >
                     Go Back To Login
                    </Button>
                    </View>
                  </Col>
                </Row>
              </Card>
            </View>
          </ImageBackground>
        </Col>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: 500,
    height: 700,
  },
  logo: {
    height: 200,
    width: 200,
    marginTop: 10,
  },
  header: {
    marginTop: 15,
    fontSize: 22,
  },
  textInput: {
    margin: 12,
  },
});
