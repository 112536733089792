/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import {  View, ScrollView } from 'react-native';
import { DataTable } from 'react-native-paper';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { numberWithCommas } from '../../../../components/common/common';

function Transaction({ route }: any) {
  const { accountNo } = route.params;
  const account = useSelector((state) => (state as any).savingsAccounts);

  return (
    <ScrollView>
      {account.map((x: any, i: any) => {
        if (x.accountNo === accountNo) {
          return (
            <View key={i}>
              <DataTable.Row>
                <DataTable.Cell>
                  {moment(x.lastActiveTransactionDate).format('DD/MM/YYYY')}
                </DataTable.Cell>

                <DataTable.Cell>Deposit</DataTable.Cell>

                <DataTable.Cell>
                  {x.currency.displaySymbol} {numberWithCommas(x.accountBalance)}
                </DataTable.Cell>
              </DataTable.Row>
            </View>
          );
        }
      })}
    </ScrollView>
  );
}

export default Transaction;
