import { View, Text, StyleSheet, Image } from 'react-native';
import React from 'react';

export default function AboutUs() {
  return (
    <View style={styles.container}>
      <Text style={styles.companyName}>Kanzu Banking </Text>

      <Text style={styles.version}>Version 0.0.4 </Text>

      <Image source={require('../../assets/kanzubanking_logo.png')} style={styles.logo} />

      <Text style={styles.copyright}>
        {' '}
        © {new Date().getFullYear()} Kanzu Code{' '}
      </Text>

      <Text>All rights reserved </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  companyName: {
    fontSize: 23,
  },
  version: {
    fontSize: 16,
  },
  logo: {
    width: 50,
    height: 50,
    marginTop: 20,
  },
  copyright: {
    marginTop: 25,
  },
});
