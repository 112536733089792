/* eslint-disable no-multi-assign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable no-else-return */

import { AccountService } from '../../components/common/account.service';
import { store } from '../../redux/index';

export function DashboardCtrl(this: any) {
  
  const vm = this;

  const accountService = new (AccountService as any)();

  vm.dashboardData = {};
  vm.isStatisticsEnabled = false;
  vm.statistics = {};
  vm.options = {
    chart: {
      type: 'pieChart',
      height: 300,
      showLabels: false,
      x(d: any) {
        return d.key;
      },
      y(d: any) {
        return d.y;
      },
      duration: 500,
      labelSunbeamLayout: true,
    },
  };
  vm.transactions = [];


  vm.getDashboardData = async () => {
    const clientId = await accountService.getClientId();
   
    await accountService.getClient(clientId).then(async (response: any) => {
    store.dispatch({ type: 'SET_LOGIN_USER', loggedInUser: response.data });
    }); // @TODO Do we need this? Is there any information being returned by getClient which isn't returned by the auth login response?
   
    await accountService.getTenantId().then(async (response: any) => {
      store.dispatch({ type: 'SET_LOGIN_TENANT', loggedInTenant: response });
    });  
    // await accountService.getSelfReportedTransactions(clientId).then(async (response: any) => {
    //   const { data } = response;
    //   store.dispatch({ type: 'SET_SELF_REPORTED_TRANSACTIONS', selfReportedTransactions: data });
    // }); //@TODO We don't need this here. This can be done on the self-reported transactions page. Add a loading dialogue on that component while the data is being retrieved
    await accountService.getAccountStatistics().then(async (response: any) => {
      const { data } = response;

      if (data.statusCode == 'OK') {
        vm.isStatisticsEnabled = true;
        vm.statistics = data.body;
      }
    }); // @TODO Are these stats used on the homepage? Confirm
    // Yes the statistics are used on the dashboard

    await accountService.getLoanProducts(clientId).then((response: any) => {
      const { data } = response;
      store.dispatch({type: 'SET_PRODUCT_LIST', productList: data });
    })

    await accountService.getShareProducts(clientId).then((response: any) => {
      const { data } = response;
      
      store.dispatch({type: 'SET_SHARE_PRODUCT_LIST',shareProductList: data });
    })

    await accountService.getAllAccounts(clientId).then(async (response: any) => {
      const { data } = response;

      vm.dashboardData.loanAccountsCount =
        vm.dashboardData.savingsAccountsCount =
        vm.dashboardData.shareAccountsCount =
          0;
      vm.dashboardData.totalSavings =
        vm.dashboardData.totalLoan =
        vm.dashboardData.loanAccountsOverview =
        vm.dashboardData.savingsAccountsOverview =
        vm.dashboardData.shareAccountsOverview =
          0;

      if (typeof data.loanAccounts !== 'undefined') {
        vm.dashboardData.loanAccounts = data.loanAccounts;
        store.dispatch({ type: 'SET_LOAN_ACCOUNTS', loanAccounts: vm.dashboardData.loanAccounts });
        vm.dashboardData.loanAccountsCount = data.loanAccounts.length;
        vm.dashboardData.totalLoan = data.loanAccounts.reduce(getTotalLoan, 0).toFixed(2);
        vm.dashboardData.loanAccountsOverview = getChartData(data.loanAccounts);
        store.dispatch({ type: 'SET_TOTAL_LOAN', totalLoan: vm.dashboardData.totalLoan });
      } else {
        store.dispatch({ type: 'SET_TOTAL_LOAN', totalLoan: 0 });
      }

      if (typeof data.savingsAccounts !== 'undefined') {
        vm.dashboardData.savingsAccounts = data.savingsAccounts;
        store.dispatch({
          type: 'SET_SAVINGS_ACCOUNTS',
          savingsAccounts: vm.dashboardData.savingsAccounts,
        });

        vm.dashboardData.savingsAccountsCount = data.savingsAccounts.length;
        vm.dashboardData.totalSavings = data.savingsAccounts.reduce(getTotalSavings, 0).toFixed(2);
        store.dispatch({ type: 'SET_TOTAL_SAVINGS', totalSavings: vm.dashboardData.totalSavings });
      } else {
        store.dispatch({ type: 'SET_TOTAL_SAVINGS', totalSavings: 0 });
      }

      if (typeof data.shareAccounts !== 'undefined') {
        vm.dashboardData.shareAccounts = data.shareAccounts;
        store.dispatch({
          type: 'SET_SHARE_ACCOUNTS',
          shareAccounts: vm.dashboardData.shareAccounts,
        });
        vm.dashboardData.shareAccountsCount = data.shareAccounts.length;
        vm.dashboardData.shareAccountsOverview = getChartData(data.shareAccounts);
      }

      vm.dashboardData.totalAccounts =
        vm.dashboardData.loanAccountsCount +
        vm.dashboardData.savingsAccountsCount +
        vm.dashboardData.shareAccountsCount;
    });

    await accountService.getRecentTransactions(clientId).then(async (response: any) => {
      const { data } = response;
      if (data.loanTransactions) {
        vm.transactions.push(...data.loanTransactions);
      }
      if (data.savingsTransactions) {
        vm.transactions.push(...data.savingsTransactions);
      }
      store.dispatch({ type: 'SET_TRANSACTIONS', transactions: vm.transactions });
      return vm.transactions;
    });
  }

  function getTotalSavings(total: any, acc: any) {
    if (acc.accountBalance && acc.status.active) {
      return total + acc.accountBalance;
    } else {
      return total;
    }
  }


  function getTotalLoan(total: any, acc: any) {
    if (acc.loanBalance && acc.status.active) {
      return total + acc.loanBalance;
    } else {
      return total;
    }
  }

  function getChartData(accounts: any) {
    const chartObj = {};
    accounts.map(function (acc: any) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      chartObj[acc.status.value] = chartObj[acc.status.value] ? chartObj[acc.status.value] + 1 : 1;
    });
    const chartData = [];
    const keys = Object.keys(chartObj);
    for (const i in keys) {
      chartData.push({
        key: keys[i],
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        y: chartObj[keys[i]],
      });
    }
    return chartData;
  }
}

export default DashboardCtrl;
