/* eslint-disable react/prop-types */
import React from 'react';
import { View, Platform } from 'react-native';

import ResetPassword from './ResetPassword';

export default function Reset({ navigation }:any) {
  return (
    <View>
      {Platform.OS !== 'web' ? (
        <ResetPassword  />
      ) : (
        <ResetPassword  />
      )}
    </View>
  );
}
