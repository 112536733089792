/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import RecentTransactionsWeb from './RecentTransactionsWeb';
import RecentTransactionsMobile from './RecentTransactionsMobile';
import Navbar from '../../components/header/Navbar';
import { isPhone } from '../../components/common/devices';

export default function RecentTransaction({ navigation , route }: any) {
  return (
    <>
      {!isPhone() ? (
        <RecentTransactionsMobile navigation={navigation} route={route} />
      ) : (
        <>
          <Navbar navigation={navigation} />

          <RecentTransactionsWeb navigation={navigation} route={route} />
        </>
      )}
    </>
  );
}
