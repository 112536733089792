/* eslint-disable no-multi-assign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable no-else-return */

import { useNavigation } from '@react-navigation/native';
import { AccountService } from '../../components/common/account.service';
import { Constants } from '../../Constants/Constants';
import DashboardCtrl from '../dashboard/dashboard.component';

const { BASE_URL } = Constants;

const axios = require('axios');

function LoginCtrl(this: any) {
  const vm = this;
  
  const accountService = new (AccountService as any)();
  const navigation = useNavigation();
  const dashboardCtrl = new (DashboardCtrl as any)();
  vm.isAuthenticated = false;

  vm.doLogin = doLogin;

  vm.correctCredentials = false;

  async function doLogin(data: any, alertMsg: any, setLoader: (value: boolean) => void, setAlertMsg: (value: string) => void) {
    const { fsp, username, password } = data;
  
    setLoader(true); // Set loader to true when starting login
  
    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': fsp,
      },
    };
    const params = {
      username,
      password,
    };
  
    try {
      const res = await axios.post(`${BASE_URL}self/authentication`, params, apiHeaders);
      accountService.setTenantId(fsp);
      accountService.setAuth(username, password, fsp); //@TODO This login process doesn't check the API response
      accountService.setIsSignedIn(true);
      const resp = await accountService.getClients();
      if (resp.data.pageItems.length !== 0) {
        const clientId = resp.data.pageItems[0].id;
        accountService.setClientId(clientId);
        dashboardCtrl.getDashboardData();
        (navigation as any).replace('Home', { screen: 'DashBoard' });
      }
    } catch (error) {
      console.error(error);
      vm.correctCredentials = true;
      setLoader(false); // Set loader to false on error
      setAlertMsg('Please try again, your credentials are not valid.'); // Set alert message on error
      throw new Error('Incorrect username or password');
    }
  }
  
}

export default LoginCtrl;
