/* eslint-disable react/prop-types */
import React from 'react';

import { View, Platform } from 'react-native';

import LoginMobile from './loginMobile';
import LoginWeb from './loginWeb';

export default function Login({ navigation }: any) {
  return (
    <View>
      {Platform.OS !== 'web' ? (
        <LoginMobile navigation={navigation} />
      ) : (
        <LoginWeb navigation={navigation} />
      )}
    </View>
  );
}
