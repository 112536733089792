/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */

import { StyleSheet, Text, View, Platform, Button, ScrollView, Pressable } from 'react-native';
import { Card } from 'react-native-paper';
import moment from 'moment';
import React from 'react';

import { useSelector } from 'react-redux';
import Row, { Col } from '../../../components/Row';
import { numberWithCommas } from '../../../components/common/common';

export default function AccountDetailMob({ navigation, route }: any) {
  const { accountNo } = route.params;

  const account = useSelector((state) => (state as any).savingsAccounts);

  const renderScreen = () => {
    return account.map((x: any, i: any) => {
      if (x.accountNo === accountNo) {
        return (
          <View key={i}>
            <Card>
              <View style={{ marginLeft: 10, padding: 5 }}>
                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <Text style={styles.text}>Account Balance</Text>

                    <Text style={styles.compoText}>
                      {x.currency.displaySymbol} {numberWithCommas(x.accountBalance)}
                    </Text>
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <Text style={styles.text}>Account Status</Text>

                    <Text style={styles.compoText}>{x.status.value}</Text>
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <Text style={styles.text}>Account Number</Text>

                    <Text style={styles.compoText}>{x.accountNo}</Text>
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <Text style={styles.text}>Total Deposit</Text>

                    <Text style={styles.compoText}>
                      {x.currency.displaySymbol} {numberWithCommas(x.accountBalance)}
                    </Text>
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <Text style={styles.text}>Total Withdrawals</Text>

                    <Text style={styles.compoText}>No withdraw made</Text>
                  </Col>
                </Row>

                <View style={{ alignItems: 'flex-end' }}>
                  <View style={styles.btn}>
                    <Button
                      title="Deposit"
                      onPress={() => navigation.navigate(
                        'Make Deposit',
                       { 
                        accountId: x.id,
                        accountType:'Savings'
                       })}
                    />
                  </View>
                </View>
              </View>
            </Card>

            <Text style={{ marginLeft: 20, marginTop: 10, fontSize: 16 }}>
              Last Transaction Details
            </Text>

            <Card>
              <View style={{ margin: 5, padding: 10 }}>
                <Text style={{ fontSize: 18, color: 'black' }}> Last Transaction</Text>

                <Text style={{ fontSize: 14, padding: 5, color: 'gray' }}>
                  {x.currency.displaySymbol} {numberWithCommas(x.accountBalance)}
                </Text>

                <Text style={{ fontSize: 18, color: 'black' }}> Made on</Text>

                <Text style={{ fontSize: 14, padding: 5, color: 'gray' }}>
                  {moment(x.lastActiveTransactionDate).format('DD/MM/YYYY')}
                </Text>
              </View>
            </Card>

            <Text style={{ marginLeft: 20, marginTop: 10, fontSize: 16 }}>Monitors</Text>

            <Card>
              <View style={{ margin: 5, padding: 10 }}>
                <View style={{ marginBottom: 10 }}>
                  <Pressable
                    onPress={() =>
                      navigation.navigate('Saving Transactions', { accountNo: x.accountNo })
                    }
                  >
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Transactions</Text>
                  </Pressable>

                  <Text>View Transactions</Text>
                </View>

                <View style={{ marginBottom: 10 }}>
                  <Pressable
                    onPress={() => navigation.navigate('Charges', { accountNo: x.accountNo })}
                  >
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Saving Charges</Text>
                  </Pressable>

                  <Text>View Charges</Text>
                </View>
              </View>
            </Card>
          </View>
        );
      }
    });
  };

  return (
    <ScrollView>
      <View >{renderScreen()}</View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
  btn: {
    width: '30%',
    paddingRight: 10,
    marginTop: 10,
  },
  text: {
    fontSize: 18,
    letterSpacing: 1,
    fontWeight: '800',
    color: 'rgb(30,136,229)'
  },
  compoText: {
    fontSize: 14,
    paddingTop: 5,
    fontWeight: '500',
  },
});
