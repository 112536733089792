/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { View, Text, Image, TouchableHighlight, ScrollView, SafeAreaView } from 'react-native';
import { TextInput } from 'react-native-paper';

import PhoneInput from 'react-native-phone-number-input';

import Row, { Col } from '../../components/Row';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import { styles } from './DepositStyles';
import Activity from '../../components/activityIndicator/ActivityIndicator';
import DropDown from 'react-native-paper-dropdown';
import { useToast } from 'react-native-paper-toast';

import { AccountService } from '../../components/common/account.service';
import { Constants } from '../../Constants/Constants';
import PaymentCtrl from '../MakePayment/Payment.component';

export default function DepositMobile({ navigation,route }: any) {
  const accountService = new (AccountService as any)();
  const paymentCtrl = new (PaymentCtrl as any)();
  const { accountId } = route.params;
  const {accountType} = route.params;
  const toaster = useToast();

  const [amount, setAmount] = useState('');
  const [value, setValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loader, setLoader] = useState(false);

  const [transactionFees, setTransactionFees] = useState({ max: 0, min: 0, amount: 0 });

  const [provider, setProvider] = useState('');
  const [showOption, setShowOption] = useState(false);
  const option = [
    { label: 'MTN_UG', value: 'MTN_UG' },
    { label: 'AIRTEL_UG', value: 'AIRTEL_UG' },
    // { label: 'FlexiPay', value: 'FlexiPay' }
  ]

  useEffect(() => {
    if (provider && amount) {
      if (provider === 'MTN_UG') {
        let rangeCharge = paymentCtrl.transactionalCharges(amount, Constants.MTN_CHARGES);
        setTransactionFees(rangeCharge);
        console.log("MTN Charges:", Constants.MTN_CHARGES);
      } else if (provider === 'AIRTEL_UG') {
        let rangeCharge = paymentCtrl.transactionalCharges(amount, Constants.AIRTEL_CHARGES);
        let percentageCharge = paymentCtrl.airtelPercentage(amount);
        
        // Combine the percentage charge with the range-based charge
        const totalCharge = rangeCharge ? rangeCharge.amount + percentageCharge : percentageCharge;
  
        setTransactionFees({ ...rangeCharge, amount: totalCharge });
      }
    }
  }, [provider, amount]);

  const makeTransfer = async () => {
    const tenantId = await accountService.getTenantId();
  
    setLoader(true);
    // Removing + from phone number
    const phone = phoneNumber.replace('+', '');
    const mobileMoneyPaymentTypeId = 4;
    const transferData = {
      tenantId: tenantId,
      paymentTypeId: mobileMoneyPaymentTypeId,
      accountNumber: accountId,
      mobileNumber: phone,
      transactionAmount: amount,
      provider: provider
    };
    if (accountType === 'Savings') {
      await accountService
        .SavingTransfer(transferData)
        .then((res: any) => {
          setLoader(false);
          return res.data;
        })
        .catch((err: any) => {
          setLoader(false);
          console.log('deposit ', err);
        });
    } else {
      await accountService
        .LoanTransfer(transferData)
        .then((res: any) => {
          setLoader(false);
          toaster.show({
            message: 'Transfer Completed Successfully.',
            duration: 5000,
            type: 'success',
            position: 'middle',
          });
          return res.data;
        })
        .catch((err: any) => {
          setLoader(false);
          toaster.show({
            message: 'Transfer not Completed.',
            duration: 5000,
            type: 'error',
            position: 'middle',
          });
          console.log('deposit ', err);
        });
    }
  };

  return (
    <ScrollView>
      <View style={{padding:20}}>
        <Row>
          <Col customStyles={{ flex: 1 }}>
            <Text style={styles.depositLabels}>Amount</Text>
          </Col>
        </Row>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <TextInput
              placeholder="Principal Amount"
              value={amount}
              onChangeText={(e: any) => setAmount(e)}
              mode="outlined"
              outlineColor="#2679b0"
              activeOutlineColor="#2679b0"
              style={styles.amountView}
            />
          </Col>
        </Row>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <Text>Account No: {accountId}</Text>
          </Col>
        </Row>

        <View style={styles.divider} />

        <View>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositLabels}>Mobile Money</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositsubtitleLabels}>Network</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Image style={[styles.mobilemoneyImages]} source={require('../../assets/mtn.png')} />
            </Col>
            <Col customStyles={{ flex: 1 }}>
              <Image style={[styles.mobilemoneyImages]} source={require('../../assets/airtel.png')} />
            </Col>
            {/* <Col customStyles={{ flex: 1 }}>
              <Image style={[styles.mobilemoneyImages]} source={require('../../assets/flexipaylogo.png')} />
            </Col> */}
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositsubtitleLabels}>Choose Provider</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <SafeAreaView style={{ width: '92%' }}>
                <DropDown
                  placeholder="Select Provider"
                  visible={showOption}
                  showDropDown={() => setShowOption(true)}
                  onDismiss={() => setShowOption(false)}
                  value={provider}
                  setValue={(value: any) => setProvider(value)}
                  list={option}
                  dropDownStyle={styles.dropdownContainerStyle}
                />
              </SafeAreaView>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositsubtitleLabels}>Number</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <PhoneInput
                //   ref={phoneInput}
                defaultValue={value}
                defaultCode="UG"
                layout="first"
                onChangeText={(e:any) => {
                  setValue(e);
                }}
                onChangeFormattedText={(e:any) => {
                  setPhoneNumber(e);
                }}
                withDarkTheme
                withShadow
                //   autoFocus
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 , flexDirection:'row'}}>
                <Text style={[styles.label, {fontSize: 14}]}> Transactional Fees</Text> : <span style={{fontSize: 14}}>UGX</span>{transactionFees.amount}
            </Col>
          </Row>

          <View style={styles.divider} />

          {loader && <Activity />}

          <TouchableHighlight onPress={makeTransfer} disabled={loader}>
            <View style={[styles.depositButton, loader && styles.disabledButton]}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Deposit'}</Text>
            </View>
          </TouchableHighlight>
        </View>
      </View>
      </ScrollView>
  );
}
