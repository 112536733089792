import { Constants } from "../../Constants/Constants";

function PaymentCtrl(this: any){

    const vm = this;

    vm.transactionalCharges = (amount: any, charges: any) => {
        return charges.find((item : any) => item.max > parseInt(amount) && item.min < parseInt(amount));
      };
  
    vm.airtelPercentage = (amount: any) => {
      return parseInt(amount) * Constants.AIRTEL_PERCENTAGE;
    };

}
export default PaymentCtrl