/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

import { StyleSheet, View, Platform } from 'react-native';
import { DataTable } from 'react-native-paper';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { AccountService } from '../../../components/common/account.service';
import Activity from '../../../components/activityIndicator/ActivityIndicator';
import { numberWithCommas } from '../../../components/common/common';

function RepaymentSchedule({ route }: any) {
  const accountService = new (AccountService as any)();
  const [loader, setLoader] = useState(false);
  const { loanId } = route.params;
  const [repaymentSchedule, setRepaymentSchedule] = useState([]);

  const getDisplayAcountDetail = async () => {
    setLoader(true);

    await accountService.getLoanDetails(loanId).then(async (res: any) => {
      const response = res.data;
      setLoader(false);

      setRepaymentSchedule(response.repaymentSchedule.periods);
    });
  };
  useEffect(() => {
    getDisplayAcountDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.mobile}>
      <View>
        <DataTable.Header style={{ backgroundColor: '#add8e6' }}>
          <DataTable.Title>Date</DataTable.Title>

          <DataTable.Title>Loan Balance</DataTable.Title>

          <DataTable.Title>Repayment</DataTable.Title>
        </DataTable.Header>
        {repaymentSchedule.map((x, i) => {
          return (
            <DataTable.Row key={i}>
              <DataTable.Cell>{moment((x as any).dueDate).format('DD/MM/YYYY')}</DataTable.Cell>

              <DataTable.Cell>{numberWithCommas((x as any).principalDue | 0)}</DataTable.Cell>

              <DataTable.Cell>
                {numberWithCommas((x as any).principalLoanBalanceOutstanding | 0)}
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
        {loader && (
          <View style={{ marginTop: '50%' }}>
            <Activity />
          </View>
        )}
      </View>
    </View>
  );
}

export default RepaymentSchedule;

const styles = StyleSheet.create({
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
});
