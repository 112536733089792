import React, { Dispatch, SetStateAction } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { TextInput, TextInputProps } from 'react-native-paper';
import Row, { Col } from './Row';

interface RequiredTextInputBaseProps {
  label: string;
  value?: string;
  onChange: Dispatch<SetStateAction<string>>;
  required?: boolean;
  error?: string;
  mode?: "outlined" | "flat";
  outlineColor?: string;
  activeOutlineColor?: string;
  style?: any;
}

interface RequiredTextInputProps extends RequiredTextInputBaseProps {
  placeholder?: string;
}

const RequiredTextInput: React.FC<RequiredTextInputProps> = ({ label, value, onChange, required, error, mode, outlineColor, activeOutlineColor, placeholder }) => {
  const handleInputChange = (text: string) => {
    onChange(text);
  };

  return (
    <View>
      <Row>
        <Col customStyles={{ flex: 1 }}>
          <Text style={styles.label}>
            {label}
            {required && <Text style={styles.requiredAsterisk}>*</Text>}
          </Text>
        </Col>
      </Row>
      <TextInput
        style={[error ? styles.errorInput : null]}
        placeholder={placeholder || `Enter ${label.toLowerCase()}`}
        value={value}
        onChangeText={handleInputChange}
        mode={mode}
        outlineColor={outlineColor}
        activeOutlineColor={activeOutlineColor}
      />
      {required && error ? <Text style={styles.errorText}>{error}</Text> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  requiredAsterisk: {
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  },
  errorText: {
    color: 'red',
    marginTop: 5,
  },
});

export default RequiredTextInput;

