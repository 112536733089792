/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { View, Text, Image, TouchableHighlight, ScrollView, Alert, SafeAreaView } from 'react-native';
import { TextInput } from 'react-native-paper';

import PhoneInput from 'react-native-phone-number-input';

import Row, { Col } from '../../components/Row';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import { styles } from './PaymentStyles';
import Activity from '../../components/activityIndicator/ActivityIndicator';
import DropDown from 'react-native-paper-dropdown';
import { useSelector, Provider } from 'react-redux';

import { AccountService } from '../../components/common/account.service';
import TrackScreenHit from '../../utils/analytics';
import { Constants } from '../../Constants/Constants';
import PaymentCtrl from './Payment.component';

export default function PaymentMobile({ navigation,route }: any) {
  const accountService = new (AccountService as any)();
  const paymentCtrl = new (PaymentCtrl as any)();
  // const { accountNumber } = route.params;
  // const { accountType } = route.params;
  // const { accountNumber, accountType } = route.params || {};
  TrackScreenHit("Make Deposit")

  const [loader, setLoader] = useState(false);
  const [amount, setAmount] = useState('');
  const [value, setValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [account, setAccount] = useState('');
  const [transactionFees, setTransactionFees] = useState({ max: 0, min: 0, amount: 0 });

    let loanAccounts = useSelector((state: any) => (state as any).loanAccounts);
    let savingsAccounts = useSelector((state: any) => (state as any).savingsAccounts);

    const [filteredSavingsAccounts, setFilteredSavingsAccounts] = useState<any[]>([]);
    const [filteredLoanAccounts, setFilteredLoanAccounts] = useState<any[]>([]);
    const [accounts, setAccounts] = useState<any[]>([]);
    const [showDropDown1, setShowDropDown1] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [accountNumber, setAccountNumber] = useState('');
    const [accounttype, setAccountType] = useState<AccountType>('Savings');
    const [provider, setProvider] = useState('');
    const [showOption, setShowOption] = useState(false);
    const option = [
      { label: 'MTN_UG', value: 'MTN_UG' },
      { label: 'AIRTEL_UG', value: 'AIRTEL_UG' },
      // { label: 'FlexiPay', value: 'FlexiPay' }
    ];

    type AccountType = 'Savings' | 'Loan';


    const accountsMap: { Savings: any[]; Loan: any[] } = {
      Savings: filteredSavingsAccounts,
      Loan: filteredLoanAccounts,
    };

    useEffect(() => {
      if (accounttype) {
        getAccountNumber(accounttype);
      }

    }, [amount]);

    useEffect(() => {
      if (provider && amount) {
        if (provider === 'MTN_UG') {
          let rangeCharge = paymentCtrl.transactionalCharges(amount, Constants.MTN_CHARGES);
          setTransactionFees(rangeCharge);
          console.log("MTN Charges:", Constants.MTN_CHARGES);
        } else if (provider === 'AIRTEL_UG') {
          let rangeCharge = paymentCtrl.transactionalCharges(amount, Constants.AIRTEL_CHARGES);
          let percentageCharge = paymentCtrl.airtelPercentage(amount);
          
          // Combine the percentage charge with the range-based charge
          const totalCharge = rangeCharge ? rangeCharge.amount + percentageCharge : percentageCharge;
    
          setTransactionFees({ ...rangeCharge, amount: totalCharge });
        }
      }
    }, [provider, amount]);

    useEffect(() => {
      if (!savingsAccounts.length && !loanAccounts.length) {
        console.log('Accounts not loaded yet');
        return;
      }
    
      const savings = savingsAccounts
        .filter((acc: { status: { active: boolean } }) => acc.status.active === true)
        .map((x: any) => ({
          label: x.accountNo,
          value: x.accountNo,
        }));
    
      const loans = loanAccounts
        .filter((acc: { status: { active: boolean } }) => acc.status.active === true)
        .map((x: any) => ({
          label: x.accountNo,
          value: x.accountNo,
        }));
    
      setFilteredSavingsAccounts(savings);
      setFilteredLoanAccounts(loans);
    
      if (savings.length > 0) {
        setAccountNumber(savings[0].value);
      } else if (loans.length > 0) {
        setAccountNumber(loans[0].value);
      }
    
      console.log('Filtered Savings Accounts:', savings);
      console.log('Filtered Loan Accounts:', loans);
    }, [savingsAccounts, loanAccounts]);

    const onChangeAccountType = (e: string) => {
      if (e === 'Savings' || e === 'Loan') {
        setAccountType(e);
        setAccounts(accountsMap[e]);
      }
    };

  const onChangeAccountNumber = (e: any) => {
    setAccountNumber(e);
  };

  const accountType = [
    {
      label: 'Loan',
      value: 'Loan',
    },
    {
      label: 'Savings',
      value: 'Savings',
    },
  ];

  // get account type from accounts
  const getAccountNumber = async (accountType: AccountType) => {
    const clientId = await accountService.getClientId();
    accountService.getAllAccounts(clientId).then((response: any) => {
      const res = response.data;
  
      if (accountType === 'Savings') {
        const accounts = res.savingsAccounts;
        setFilteredSavingsAccounts(accounts);
        accounts.forEach((x: any) => {
          if (x.accountNo === accountNumber) {
            setAccount(x.id);
          }
        });
      } else if (accountType === 'Loan') {
        const accounts = res.loanAccounts;
        setFilteredLoanAccounts(accounts);
        accounts.forEach((x: any) => {
          if (x.accountNo === accountNumber) {
            setAccount(x.id);
          }
        });
      }
    });
  };

  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  getAccountNumber();

  const makeTransfer = async () => {
    const tenantId = await accountService.getTenantId();
    setLoader(true);
    const phone = phoneNumber.replace('+', '');
    const mobileMoneyPaymentTypeId = 4;
    const transferData = {
      tenantId: tenantId,
      paymentTypeId: mobileMoneyPaymentTypeId,
      accountNumber: accountNumber ? accountNumber : parseInt(accountNumber, 10),
      mobileNumber: phone,
      transactionAmount: amount,
      provider: provider,
    };
  
    if (accounttype === 'Savings' || accounttype === 'Loan') {
      if (accounttype === 'Savings') {
        await accountService
          .SavingTransfer(transferData)
          .then((res: any) => {
            setLoader(false);
            return res.data;
          })
          .catch((err: any) => {
            console.log('deposit ', err);
          });
      } else {
        await accountService
          .LoanTransfer(transferData)
          .then((res: any) => {
            setLoader(false);
            Alert.alert('Kanzu Banking', 'Transfer Completed Successfully');
            console.log(res.data);
            return res.data;
          })
          .catch((err: any) => {
            setLoader(false);
            Alert.alert('Kanzu Banking', 'Transfer not Completed');
            console.log('deposit ', err);
          });
      }
    } else {
      console.error('Invalid account type');
    }
  };

  return (
    <ScrollView>
      <View style={{padding:20}}>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <Text style={styles.depositLabels}>
              Select Account Type
              {<Text style={{color: 'red'}}>*</Text>}
            </Text>
          </Col>
        </Row>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <SafeAreaView>
              <DropDown
                mode="outlined"
                visible={showDropDown}
                showDropDown={() => setShowDropDown(true)}
                onDismiss={() => setShowDropDown(false)}
                value={accounttype}
                setValue={(e: any) => onChangeAccountType(e)}
                list={accountType}
              />
            </SafeAreaView>
          </Col>
        </Row>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <Text style={styles.depositLabels}>
              Account Number
              {<Text style={{color: 'red'}}>*</Text>}
            </Text>
          </Col>
        </Row>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <SafeAreaView>
              <DropDown
                mode="outlined"
                visible={showDropDown1}
                showDropDown={() => setShowDropDown1(true)}
                onDismiss={() => setShowDropDown1(false)}
                value={accountNumber}
                setValue={onChangeAccountNumber}
                list={accountsMap[accounttype as AccountType] || accounts}
              />
            </SafeAreaView>
          </Col>
        </Row>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <Text style={styles.depositLabels}>Amount</Text>
          </Col>
        </Row>

        <Row>
          <Col customStyles={{ flex: 1 }}>
            <TextInput
              placeholder="Principal Amount"
              value={amount}
              onChangeText={(e: any) => setAmount(e)}
              mode="outlined"
              outlineColor="#2679b0"
              activeOutlineColor="#2679b0"
              style={styles.amountView}
            />
          </Col>
        </Row>

        <View style={styles.divider} />

        <View>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositLabels}>Mobile Money</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositsubtitleLabels}>Network</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Image style={[styles.mobilemoneyImages]} source={require('../../assets/mtn.png')} />
            </Col>
            <Col customStyles={{ flex: 1 }}>
              <Image style={[styles.mobilemoneyImages]} source={require('../../assets/airtel.png')} />
            </Col>
            {/* <Col customStyles={{ flex: 1 }}>
              <Image style={[styles.mobilemoneyImages]} source={require('../../assets/flexipaylogo.png')} />
            </Col> */}
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositsubtitleLabels}>Choose Provider</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <SafeAreaView style={{ width: '92%' }}>
                <DropDown
                  placeholder="Select Provider"
                  visible={showOption}
                  showDropDown={() => setShowOption(true)}
                  onDismiss={() => setShowOption(false)}
                  value={provider}
                  setValue={(value: any) => setProvider(value)}
                  list={option}
                  dropDownStyle={styles.dropdownContainerStyle}
                />
              </SafeAreaView>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.depositsubtitleLabels}>Number</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <PhoneInput
                //   ref={phoneInput}
                defaultValue={value}
                defaultCode="UG"
                layout="first"
                onChangeText={(e:any) => {
                  setValue(e);
                }}
                onChangeFormattedText={(e:any) => {
                  setPhoneNumber(e);
                }}
                withDarkTheme
                withShadow
                //   autoFocus
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 , flexDirection:'row'}}>
                <Text style={[styles.label, {fontSize: 14}]}> Transactional Fees</Text> : <span style={{fontSize: 14}}>UGX</span>{transactionFees.amount}
            </Col>
          </Row>

          <View style={styles.divider} />

          {loader && <Activity />}

          <TouchableHighlight onPress={makeTransfer}>
            <View style={styles.depositButton}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>Deposit</Text>
            </View>
          </TouchableHighlight>
        </View>
      </View>
      </ScrollView>
  );
}
