import React from 'react';
import { LogBox, Platform } from 'react-native';
import { decode, encode } from 'base-64';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import 'react-native-gesture-handler';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastProvider } from 'react-native-paper-toast';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { setCustomText } from 'react-native-global-props';
import { en, registerTranslation } from 'react-native-paper-dates';
import Intl from 'intl';
// eslint-disable-next-line import/no-relative-packages
import { useFonts } from 'expo-font';
import 'intl/locale-data/jsonp/en';

import DrawerNavigator from './src/screens/navigation/screen.tsx';
import { persistor, store } from './src/redux/index.ts';
import '@expo/match-media';

if (Platform.OS === 'android') {
  Intl.__disableRegExpRestore();
}

registerTranslation('en', en);

if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}
const fontColor = 'rgb(41, 113, 182)';

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: fontColor,
    accent: '#f1c40f',
  },
};

/**
 * This function ensures that console.log entries
 * do not get written in production.
 * We set process.env.NODE_ENV in package.json
 */
function configureConsoleLogging() {
  // Save the original console.log function
  const isDevelopment = process.env.NODE_ENV === 'development';
  const originalConsoleLog = console.log;

  // Replace console.log with a custom function
  console.log = function(...args) {
    if (isDevelopment) {
      // Call the original console.log if in development mode
      originalConsoleLog.apply(console, args);
    }
    // Otherwise, do nothing (suppressing the log in production)
  };
}

export default function App() {
  configureConsoleLogging();
  const [loaded] = useFonts({
    MontserratRegular: require('./src/assets/fonts/Montserrat-Regular.ttf'),
    'material-community': require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
  });
  LogBox.ignoreAllLogs();

  if (!loaded) {
    return null;
  }

  const customTextProps = {
    style: {
      fontFamily: 'MontserratRegular',
    },
  };

  setCustomText(customTextProps);
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PaperProvider theme={theme}>
          <SafeAreaProvider>
            <ToastProvider>
              <DrawerNavigator />
            </ToastProvider>
          </SafeAreaProvider>
        </PaperProvider>
      </PersistGate>
    </ReduxProvider>
  );
}
