import { StyleSheet, Text, View, SafeAreaView, Image, TouchableHighlight, Platform, TouchableOpacity, Pressable, Alert} from 'react-native';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Entypo } from '@expo/vector-icons';
import { Provider, Card, TextInput, Button } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import { useSelector } from 'react-redux';
import { useToast } from 'react-native-paper-toast';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Activity from '../../components/activityIndicator/ActivityIndicator';

import { AccountService } from '../../components/common/account.service';

import ChangePasswordModal from '../changePassword/ChangePasswordMob';
import ProfileCtrl from './Profile.component';

export default function UserProfileMobile({ navigation }: any) {
  const toaster = useToast();
  const [clientId,setClientId] = useState(Number)
  const [loader, setLoader] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [activationDate, setActivationDate] = useState([]);
  const [fsp, setFsp] = useState('');
  const [mobile, setMobile] = useState('');
  const profileImage = useSelector((state) => (state as any).image);
  const profileCtrl = new (ProfileCtrl as any)();

  const accountService = new (AccountService as any)();

  const apiHeaders = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const getDisplayDetails = async () => {
    // eslint-disable-next-line no-shadow
    const clientId = await accountService.getClientId();
    const tenantId = await accountService.getTenantId();

    const user = await accountService
      .getClient(clientId)
      .then(async (response: any) => {
        setMobile(response.data.mobileNo)
        return response.data;
      })
      .catch(async (err: any) => {
        console.log(err, 'No clients found');
      });

    setFirstName(user.firstname);
    setLastName(user.lastname);
    setActivationDate(user.activationDate);
    setClientId(user.id)
    setFsp(tenantId);
  };

  // New code for handling the form submission
  const handleSubmit = async () => {
    setLoader(true);
    try {
      const userdetails = await AsyncStorage.getItem('apiDocHeaders');
      let finalUserDetails = userdetails != null ? JSON.parse(userdetails) : null;

      if(mobile){
        let mobileNumber = mobile;
        if (mobileNumber.startsWith('0')) {
          mobileNumber = '256' + mobileNumber.substring(1);
        }

        const payload = {
          fsp: fsp, // Default value if not available
          username: finalUserDetails.auth.username,
          password: finalUserDetails.auth.password,
          phoneNumber: mobileNumber,
        };

        accountService
          .whatsapp(payload, apiHeaders)
          .then((response: any) => {
            setLoader(false);
            toaster.show({
              message: 'User registered successfully.',
              duration: 5000,
              type: 'success',
              position: 'middle',
            });
            
          })
          .catch((error: any) => {
            setLoader(false);
            toaster.show({
              message: 'User already registered.',
              duration: 5000,
              type: 'error',
              position: 'middle',
            });
            console.log(error, 'User already registered');
          });
      } else {
          setLoader(false);
          toaster.show({
            message: 'No phone number attached to user',
            duration: 5000,
            type: 'error',
            position: 'middle',
          });
      }

    } catch (error) {
      setLoader(false);
      console.error('Error sending payload:', error);
    }
  };
  
  const [image, setProf] = useState('');
  const pickDocument = async () => {
    await DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    }).then((response: any) => {
      if (response.type === 'success') {
        const { file } = response;
        let { uri } = response;

        if (Platform.OS === 'android' && uri[0] === '/') {
          uri = `file://${uri}`;
          uri = uri.replace(/%/g, '%25');
        }

        setProf(file);
        
      }
    });

  };

  const uploadFile = async () => {

    setLoader(true);

    const formData = new FormData();
    formData.append('file', image);

    // eslint-disable-next-line no-shadow
    const clientId = await accountService.getClientId();

    toaster.show({
      message: 'Uploading Profile Picture In Progress',
      duration: 5000,
      type: 'warning',
      position: 'middle',
    });

    accountService
      .profileUpload(clientId, formData, apiHeaders)
      .then((response: any) => {
        toaster.show({
          message: 'Your profile picture has been changed successfully.',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });
        setLoader(false);
        profileCtrl.getImage();
      })
      .catch((error: any) => {
        setLoader(false);
        console.log(error, 'Photo was not uploaded');
      });
      
  };


  useEffect(()=>{
    getDisplayDetails();
  },[false])

  const [visible, setVisible] = useState(false);

  const toggleBottomNavigationView = () => {
    setVisible(!visible);
  };
  return (
    <Provider>
      <View style={{ backgroundColor: '#fff', height: '100%' }}>
        <View style={{ height: 150, backgroundColor: '#2679b0' }}>
          <View style={{ position: 'absolute', alignSelf: 'center' }}>
            <SafeAreaView style={styles.image}>
              <Image source={{ uri: profileImage }} style={styles.img} />
            </SafeAreaView>
          </View>
        </View>

        <View style={{ height: 550 }}>
          <View style={{ alignItems: 'center', marginTop: 50 }}>
            <View style={{ margin: 10 }}>
              <Text style={[styles.headerText, { marginLeft: 25 }]}>Full Name</Text>

              <View style={{ alignItems: 'center' }}>
                <Text style={styles.text}>
                  {lastName} {firstName}
                </Text>
              </View>
            </View>

            <View style={{ margin: 10 }}>
              <Text style={styles.headerText}>SACCO/Investment Club</Text>

              <View style={{ alignItems: 'center' }}>
                <Text style={styles.text}> {fsp}</Text>
              </View>
            </View>

            <View style={{ margin: 10 }}>
              <Text style={styles.headerText}> Activation Date</Text>

              <View style={{ alignItems: 'center' }}>
                <Text style={styles.text}>{moment(activationDate).format('DD/MM/YYYY')}</Text>
              </View>
            </View>

            
            <View style={{ width: '70%', marginTop: 40 }}>
              <ChangePasswordModal />
            </View>
            <View style={{ width: '70%', marginTop: 40 }}>
              <Button
                color="#2679b0"
                mode="contained"
                onPress={pickDocument}
              >
                Change Profile Image
              </Button>
            </View>
            {image  && (
              <View style={{ width: '70%', marginTop: 40 }}>
                <Button
                  color="#2679b0"
                  mode="contained"
                  onPress={uploadFile}
                >
                  Confirm Image Change
                </Button>
              </View>
            ) }

            <View style={{ width: '70%', marginTop: 40 }}>
              {/* New Submit Button for the form */}
              <View style={{ marginTop: 20 }}>
              {loader && <Activity />}
              { !loader ?
                <Button
                  color="#2679b0"
                  mode="contained"
                  onPress={handleSubmit}
                >
                  Enable Whatsapp ChatBot
                </Button>
              : null}
            </View>
          </View>
          </View>
        </View>
      </View>
    </Provider>
  );
}

const styles = StyleSheet.create({
  img: {
    width: 150,
    height: 150,
    borderRadius: 150 / 2,
    borderColor: 'white',
    borderWidth: 5,
  },
  view: {
    backgroundColor: '#2679b0',
    height: '40%',
  },
  image: {
    flex: 1,
    alignItems: 'center',
    marginTop: 40,
  },
  choosePhoto: {
    width: '40%',
    paddingTop: 10,
  },
  btnContainer: {
    flex: 1,
    padding: 10,
    alignItems: 'flex-end',
  },
  upDateProfileContainer: {
    padding: 20,
  },
  inputs: {
    borderWidth: 1,
    width: 300,
    height: 40,
    padding: 10,
    margin: 10,
    color: '#000',
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    padding: 10,
    height: 50,
    elevation: 2,
    margin: 8,
    width: 300,
    backgroundColor: '#2196F3',
  },
  header: {
    fontSize: 18,
    justifyContent: 'flex-start',
    color: 'rgb(41, 113, 182)',
    marginLeft: 8,
  },
  text: {
    color: '#2679b0',
    fontSize: 17,
    paddingTop: 10,
  },
  headerText: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: 16,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: 1,
    textAlign: 'center',
  },
  upload: {
    backgroundColor: '#0782F9',
    width: '17%',
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    borderRadius: 4,
  },
});
