import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { List, Button } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Linking from 'expo-linking';

import { Col } from '../../components/Row';

export default function Help() {
  const [expanded, setExpanded] = React.useState(true);

  const handlePress = () => setExpanded(!expanded);

  const sendEmail = () => {
    Linking.openURL('mailto:support@kanzucode.com?subject=Kanzu Banking App! Feedback');
  };
  const onPressCall = () => {
    Linking.openURL('tel:+256772277441');
  };
  return (
    <View style={styles.container}>
      <View style={styles.headlineView}>
        <Text style={styles.headlineText}>Frequently asked Questions</Text>
      </View>

      <List.Section>
        <List.Accordion title="How do i apply for a new loan account ?" onPress={handlePress}>
          <List.Item
            titleNumberOfLines={3}
            title={`To apply for loan account , click on "Apply for Loan" given on the Home Screen `}
          />
        </List.Accordion>

        <List.Accordion title="Where can i view my profile information ?" onPress={handlePress}>
          <List.Item
            titleNumberOfLines={3}
            title={`You can view your profile information by clicking on the Profile present in the Side bar `}
          />
        </List.Accordion>

        <List.Accordion
          title="Where can i see my Savings Accounts Transactions ?"
          onPress={handlePress}
        >
          <List.Item
            titleNumberOfLines={5}
            title="To view your savings account transactions , navigate to the Accounts Section, click on the required savings account, click on three dots present on top right and select Transactions option"
          />
        </List.Accordion>

        <List.Accordion title="How to make a payment for a Loan Account ?" onPress={handlePress}>
          <List.Item
            titleNumberOfLines={5}
            title="To make a payment for a loan account , navigate to the Accounts Sections , choose LOAN then open your required Loan Account and click on Make Payment option"
          />
        </List.Accordion>
      </List.Section>

      <View style={styles.footer}>
        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <Button
            icon={() => <MaterialCommunityIcons name="phone" color="#2679b0" size={20} />}
            mode="text"
            onPress={() => onPressCall()}
          >
            <Text>Call now</Text>
          </Button>
        </Col>

        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <Button
            icon={() => <MaterialCommunityIcons name="email" color="#2679b0" size={20} />}
            mode="text"
            onPress={() => sendEmail()}
          >
            <Text>Leave an email</Text>
          </Button>
        </Col>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headlineView: {
    marginTop: 20,
    marginLeft: 17,
  },
  headlineText: {
    fontSize: 20,
  },
  footer: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    position: 'absolute',
    bottom: 30,
  },
});
