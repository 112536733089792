import React from 'react';

export const freshdesk = (displayName, fsp) => {
  // Set up Freshdesk widget settings
  window.fwSettings = {
    widget_id: '501000000330', // Replace with your actual Freshdesk widget ID
  };

  // Define FreshworksWidget function to queue calls until the widget loads
  if (typeof window.FreshworksWidget !== 'function') {
    const FreshworksWidget = function() {
      FreshworksWidget.q.push(arguments);
    };
    FreshworksWidget.q = [];
    window.FreshworksWidget = FreshworksWidget;
  }

  // Fetch tenantId and user details from localStorage
  const tenantId = localStorage.getItem("mifosX.tenantId");
  const userDetails = JSON.parse(localStorage.getItem("mifosX.fullUserDetails"));

  let userName = '';
  let email = '';

  console.log(userDetails)

  if (userDetails) {
    userName = `${userDetails.firstname} ${userDetails.lastname} - ${tenantId}`;
    email = userDetails.email;
  }

  // Load the Freshdesk widget script
  setTimeout(() => {
    (function() {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://mec-widget.freshworks.com/widgets/501000000330.js'; // Replace with the widget URL
      script.charset = 'UTF-8';
      script.defer = true;
      document.head.appendChild(script);
    })();
  }, 100);

  // Wait until FreshworksWidget is available to set user data and display the widget
  const initializeWidget = () => {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('hide'); // Initially hide the widget
      window.FreshworksWidget('identify', 'ticketForm', {
        name: userName || `${displayName} - ${fsp}`,
        email: email,
      }, {
        formId: 1234, // Replace with your ticket form ID
      });

      // Show the widget when needed, e.g., on a button click or automatically on load
      window.FreshworksWidget('show');
    }
  };

  // Wait a moment to ensure the script has loaded
  setTimeout(initializeWidget, 500);
};
