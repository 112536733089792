import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Image,
  Pressable,
  Platform,
  ScrollView,
} from 'react-native';

import React, { useState } from 'react';
import { TextInput } from 'react-native-paper';
import { AccountService } from '../../components/common/account.service';
import { useToast } from 'react-native-paper-toast';

import Row, { Col } from '../../components/Row';
import TrackScreenHit from '../../utils/analytics';


export default function ValidateRegisterWeb({ navigation }: any) {
  const [fsp, setFsp] = useState('');
  const [authenticationToken, setAuthenticationToken] = useState('');
  const [requestId, setRequestId] = useState('');
  const toaster = useToast();
  TrackScreenHit("Registration Page")


  const accountService = new (AccountService as any)();

  const handleSubmit = async () => {
    const data = {
      authenticationToken,
      requestId,
    };

    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': fsp,
      },
    };
    // checking if the account number exist
    await accountService
      .validate(data, apiHeaders)
      .then((res: any) => {
        navigation.navigate('Login');
        return res.data;

        // Check if account number input exist
      })
      .catch((err: any) => {
        const errMsg = err.data.errors[0].defaultUserMessage;
        toaster.show({
          message: errMsg,
          duration: 5000,
          type: 'error',
          position: 'middle',
        });
      });
  };
  return (
    <View style={styleRegister.web}>
      <ImageBackground
        source={require('../../assets/cover.jpg')}
        style={{
          width: '100%',
          height: 748,
        }}
      >
        <ScrollView>
          <View style={styleRegister.centeredView}>
            <View style={styleRegister.styleView}>
              <Row>
                <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                  <Image
                    // source={require('../../assets/mifosx_logo.png')}
                    style={{ height: 90, width: 90, marginTop: 10 }}
                  />

                  <Text style={styleRegister.text}>Please enter details provided in email sent</Text>
                </Col>
              </Row>

              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View style={styleRegister.inputView}>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        label="Sacco/Investment club name"
                        placeholder="Sacco/Investment club name"
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setFsp(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        label="Request Id"
                        placeholder="Request Id"
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setRequestId(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Authentication Token"
                        label="Authentication Token"
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setAuthenticationToken(e)}
                      />
                    </Col>
                  </Row>

                </View>
              </View>

              <View style={styleRegister.container}>

                <Pressable style={styleRegister.btn} onPress={handleSubmit}>
                  <Text style={{ color: 'white', fontSize: 16 }}>CREATE ACCOUNT</Text>
                </Pressable>

              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
}

const styleRegister = StyleSheet.create({
  inputText: {
    width: '70%',
    padding: 10,
  },
  styleView: {
    backgroundColor: '#FFFFFF',
    margin: 20,
    flex: 1,
    width: '60%',
    // height: '100%',
    borderRadius: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputView: {
    alignSelf: 'center',
    width: '70%',
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  checkboxContainer: {
    flexDirection: 'row',
    margin: 5,
  },
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    margin: 8,
  },
  btn: {
    borderRadius: 4,
    padding: 10,
    elevation: 2,
    backgroundColor: '#2196F3',
    width: '30%',
    alignItems: 'center',
  },
  text: {
    marginTop: 10,
    fontSize: 22,
  },
  web: {
    display: Platform.OS === 'web' ? 'flex' : 'none',
    backgroundColor: '#f7f7f7',
  },
});
