/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  SafeAreaView,
  TouchableHighlight,
  Image,
  Alert,
} from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { Card, TextInput } from 'react-native-paper';
import PhoneInput from 'react-native-phone-number-input';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import Activity from '../../components/activityIndicator/ActivityIndicator';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';

import Row, { Col } from '../../components/Row';
import { AccountService } from '../../components/common/account.service';
import TrackScreenHit from '../../utils/analytics';

import { useSelector, Provider } from 'react-redux';
import { store } from '../../redux/index';
import { Constants } from '../../Constants/Constants';
import PaymentCtrl from './Payment.component';


export default function PaymentWeb({ navigation, route }: any) {
  const accountService = new (AccountService as any)();
  const paymentCtrl = new (PaymentCtrl as any)();
  const { accountType } = route.params;
  TrackScreenHit("Deposit Funds Page")


  const [loader, setLoader] = useState(false);
  const [amount, setAmount] = useState('');
  const [value, setValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [account, setAccount] = useState('');
  const [transactionFees, setTransactionFees] = useState({ max: 0, min: 0, amount: 0 });

    let loanAccounts = useSelector((state: any) => (state as any).loanAccounts);
    let savingsAccounts = useSelector((state: any) => (state as any).savingsAccounts);

    const [filteredSavingsAccounts, setFilteredSavingsAccounts] = useState<any[]>([]);
    const [filteredLoanAccounts, setFilteredLoanAccounts] = useState<any[]>([]);
    const [accounts, setAccounts] = useState<any[]>([]);
    const [showDropDown1, setShowDropDown1] = useState(false);
    const [accountNumber, setAccountNumber] = useState('');
    const [accounttype, setAccountType] = useState<'Savings' | 'Loans'>(route.params.accountType);
    const [provider, setProvider] = useState('');
    const [showOption, setShowOption] = useState(false);
    const option = [
      { label: 'MTN_UG', value: 'MTN_UG' },
      { label: 'AIRTEL_UG', value: 'AIRTEL_UG' },
      // { label: 'FlexiPay', value: 'FlexiPay' }
    ];


    const accountsMap: { Savings: any[]; Loans: any[] } = {
      Savings: filteredSavingsAccounts,
      Loans: filteredLoanAccounts,
    };

    useEffect(() => {
      if (accounttype) {
        getAccountNumber(accounttype);
      }
    
    }, [amount, provider]);

    useEffect(() => {
      if (provider && amount) {
        if (provider === 'MTN_UG') {
          let rangeCharge = paymentCtrl.transactionalCharges(amount, Constants.MTN_CHARGES);
          setTransactionFees(rangeCharge);
          console.log("MTN Charges:", Constants.MTN_CHARGES);
        } else if (provider === 'AIRTEL_UG') {
          let rangeCharge = paymentCtrl.transactionalCharges(amount, Constants.AIRTEL_CHARGES);
          let percentageCharge = paymentCtrl.airtelPercentage(amount);
          
          // Combine the percentage charge with the range-based charge
          const totalCharge = rangeCharge ? rangeCharge.amount + percentageCharge : percentageCharge;
    
          setTransactionFees({ ...rangeCharge, amount: totalCharge });
        }
      }
    }, [provider, amount]);

    useEffect(() => {
      if (!savingsAccounts.length && !loanAccounts.length) {
        console.log('Accounts not loaded yet');
        return;
      }
    
      const savings = savingsAccounts
        .filter((acc: { status: { active: boolean } }) => acc.status.active === true)
        .map((x: any) => ({
          label: x.accountNo,
          value: x.accountNo,
        }));
    
      const loans = loanAccounts
        .filter((acc: { status: { active: boolean } }) => acc.status.active === true)
        .map((x: any) => ({
          label: x.accountNo,
          value: x.accountNo,
        }));
    
      setFilteredSavingsAccounts(savings);
      setFilteredLoanAccounts(loans);
    
      if (accountType == "Savings") {
        if (savings.length > 0) {
          setAccountNumber(savings[0].value);
        }
      }

      if (accountType == "Loans") {
        if (loans.length > 0) {
          setAccountNumber(loans[0].value);
        }
      }
    
      console.log('Filtered Savings Accounts:', savings);
      console.log('Filtered Loan Accounts:', loans);
    }, [savingsAccounts, loanAccounts]);

  const onChangeAccountNumber = (e: any) => {
    setAccountNumber(e);
  };

  const getAccountNumber = async (accountType: 'Savings' | 'Loans') => {
    try {
      const clientId = await accountService.getClientId();
      const response = await accountService.getAllAccounts(clientId);
      const res = response.data;
  
      if (accountType === 'Savings') {
        const accounts = res.savingsAccounts.map((acc: any) => ({
          label: acc.accountNo,
          value: acc.accountNo,
        }));
        setFilteredSavingsAccounts(accounts);
      } else if (accountType === 'Loans') {
        const accounts = res.loanAccounts.map((acc: any) => ({
          label: acc.accountNo,
          value: acc.accountNo,
        }));
        setFilteredLoanAccounts(accounts);
      }
  
      const selectedAccount = accounts.find((x: any) => x.value === accountNumber);
      if (selectedAccount) {
        setAccount(selectedAccount.id);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  getAccountNumber();

  const makeTransfer = async () => {
    const tenantId = await accountService.getTenantId();
    setLoader(true);
    const phone = phoneNumber.replace('+', '');
    const mobileMoneyPaymentTypeId = 4;
    const transferData = {
      tenantId: tenantId,
      paymentTypeId: mobileMoneyPaymentTypeId,
      accountNumber: accountNumber ? accountNumber : parseInt(accountNumber, 10),
      mobileNumber: phone,
      transactionAmount: amount,
      provider: provider,
    };
  
    if (accounttype === 'Savings' || accounttype === 'Loans') {
      if (accounttype === 'Savings') {
        await accountService
          .SavingTransfer(transferData)
          .then((res: any) => {
            setLoader(false);
            return res.data;
          })
          .catch((err: any) => {
            console.log('deposit ', err);
          });
      } else {
        await accountService
          .LoanTransfer(transferData)
          .then((res: any) => {
            setLoader(false);
            Alert.alert('Kanzu Banking', 'Transfer Completed Successfully');
            console.log(res.data);
            return res.data;
          })
          .catch((err: any) => {
            setLoader(false);
            Alert.alert('Kanzu Banking', 'Transfer not Completed');
            console.log('deposit ', err);
          });
      }
    } else {
      console.error('Invalid account type');
    }
  };


  return (
    <View>
      <Row>
        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <SideMenu navigation={navigation} />
        </Col>

        <Col customStyles={{ flex: 4 }}>
          {/* <View> */}

          <Row>
            <Col
              customStyles={{
                flex: 1,
              }}
            >
              <Card style={[DashboardStyles.card, { paddingLeft: 20 }]}>
                <ImageBackground
                  source={require('../../assets/kb-blue-background.png')}
                  style={[
                    {
                      flex: 1,
                      width: 200,
                      marginTop: 10,
                      paddingVertical: 100,
                      height: '90%',
                      position: 'absolute',
                      right: 0,
                    },
                  ]}
                />

                <Card.Content>

                   <View>
                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <Text style={styles.cardHeader}>Deposit</Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <Text style={styles.label}>
                          Select Account Type
                          {<Text style={{color: 'red'}}>*</Text>}
                        </Text>
                      </Col>
                    </Row>

                    <Row>
                    <Col customStyles={{ width: '30%' }}>
                      <TextInput
                        placeholder=" Amount"
                        value={accountType}
                        mode="outlined"
                        outlineColor="#2679b0"
                        activeOutlineColor="#2679b0"
                        editable={false}
                      />
                    </Col>
                  </Row>

                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <Text style={styles.label}>
                          Account Number
                          {<Text style={{color: 'red'}}>*</Text>}
                        </Text>
                      </Col>
                    </Row>

                    
                    <Row>
                      <Col customStyles={{ width: '30%' }}>
                        <SafeAreaView>
                          <DropDown
                            mode="outlined"
                            visible={showDropDown1}
                            showDropDown={() => setShowDropDown1(true)}
                            onDismiss={() => setShowDropDown1(false)}
                            value={accountNumber}
                            setValue={onChangeAccountNumber}
                            list={accountsMap[accounttype] || accounts}
                          />
                        </SafeAreaView>
                      </Col>
                    </Row>
                    </View> 

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={styles.label}>Amount</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '30%' }}>
                      <TextInput
                        placeholder=" Amount"
                        // value={text}
                        onChangeText={(e: any) => setAmount(e)}
                        mode="outlined"
                        outlineColor="#2679b0"
                        activeOutlineColor="#2679b0"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col customStyles={{ width: '30%' }}>
                      <Text>Account No: {accountNumber}</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '70%', paddingLeft: 0 }}>
                      <View style={styles.divider} />

                      <Row>
                        <Col
                          customStyles={{
                            flex: '30%',
                            paddingLeft: 0,
                          }}
                        >
                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>Mobile Money</Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.subtiteLabel}>Network</Text>
                            </Col>
                          </Row>

                          <Row >
                            <Col customStyles={{ flex: 1 }}>
                              <Image
                                style={[styles.mobilemoneywebImages]}
                                source={require('../../assets/mtn.png')}
                              />
                            </Col>
                            <Col customStyles={{ flex: 1 }}>
                              <Image
                                style={[styles.mobilemoneywebImages]}
                                source={require('../../assets/airtel.png')}
                              />
                            </Col>
                            {/* <Col customStyles={{ flex: 1 }}>
                              <Image
                                style={[styles.mobilemoneywebImages]}
                                source={require('../../assets/flexipaylogo.png')}
                              />
                            </Col> */}
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.subtiteLabel}>Choose Provider</Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <SafeAreaView style={{ width: '83%' }}>
                                <DropDown
                                  placeholder="Select Provider"
                                  visible={showOption}
                                  showDropDown={() => setShowOption(true)}
                                  onDismiss={() => setShowOption(false)}
                                  value={provider}
                                  setValue={(value: any) => setProvider(value)}
                                  list={option}
                                  dropDownStyle={styles.dropdownContainerStyle}
                                />
                              </SafeAreaView>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.subtiteLabel}>Number</Text>
                            </Col>
                          </Row>

                          <Row>
                            <SafeAreaView style={styles.wrapper}>
                              <PhoneInput
                                //   ref={phoneInput}
                                defaultValue={value}
                                defaultCode="UG"
                                layout="first"
                                // @ts-expect-error TS(2322): Type '{ defaultValue: string; defaultCode: "UG"; l... Remove this comment to see the full error message
                                international={false}
                                onChangeText={(e: React.SetStateAction<string>) => {
                                  setValue(e);
                                }}
                                onChangeFormattedText={(e: React.SetStateAction<string>) => {
                                  setPhoneNumber(e);
                                }}
                                containerStyle={{
                                  width: 350,
                                  borderWidth: 1,
                                  borderColor: 'blue',
                                  marginLeft: 0,
                                  paddingLeft: 0,
                                  alignItems: 'flex-start',
                                }}
                                countryPickerButtonStyle={{
                                  paddingLeft: 0,
                                  marginLeft: -1,
                                  width: 100,
                                  backgroundColor: '#2679b0',
                                }}
                                textContainerStyle={{
                                  alignItems: 'flex-start',
                                  marginLeft: -15,
                                  paddingLeft: 5,
                                  backgroundColor: 'white',
                                }}
                                textInputStyle={{
                                  backgroundColor: 'white',
                                  width: 200,
                                }}
                                withShadow
                                //   autoFocus
                              />
                            </SafeAreaView>
                          </Row>
                          <Col customStyles={{ flex: 1 }}>
                            <Text style={[styles.selac, {paddingLeft:0, marginTop:10}]}>
                              <Text style={[styles.label, {paddingLeft:0, marginLeft:0}]}> Transactional Fees</Text> : <span style={{fontSize: 13}}>UGX </span>{transactionFees.amount}
                            </Text>
                          </Col>
                        </Col>

                        <Col
                          customStyles={{
                            flex: 1,
                            paddingLeft: 40,
                            marginLeft: 40,
                          }}
                        >
                          {/*
                          <Row>
                            <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                              <Text style={styles.label}>Other Payment Methods</Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.subtiteLabel}> </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Image
                                style={[styles.mobilemoneywebImages]}
                                source={require('../../assets/visa.png')}
                              />
                            </Col>

                            <Col customStyles={{ flex: 1 }}>
                              <Image
                                style={[styles.mobilemoneywebImages]}
                                source={require('../../assets/paypal.png')}
                              />
                            </Col>
                          </Row>
                          */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1, alignItems: 'flex-end', paddingRight: 30 }}>
                      {loader && <Activity />}

                      <TouchableHighlight onPress={makeTransfer} disabled={loader}>
                        <View style={[styles.depositButton, loader && styles.disabledButton]} >
                          <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Pay'}</Text>
                        </View>
                      </TouchableHighlight>
                    </Col>
                  </Row>
                </Card.Content>
              </Card>
            </Col>
          </Row>
          {/* </View> */}
        </Col>
      </Row>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 20,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  disabledButton: {
    backgroundColor: '#a9a9a9',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#62c341',
    padding: 10,
    borderRadius: 25,
    marginTop: 20,
    width: 200,
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: 'grey',
    fontWeight: 'bold',
  },
  dropdownContainerStyle: {
    position: 'absolute',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 95,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
  wrapper: {
    flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  selac: {
    fontSize: 16,
    color: '#000',
    fontWeight: 'bold',
  },
});
