/* eslint-disable prefer-object-spread */
/* eslint-disable max-classes-per-file */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

interface RowProps {
  children: React.ReactNode;
}

export default class Row extends Component<RowProps> {
  render() {
    return <View style={styles.container}>{this.props.children}</View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
});

interface ColProps {
  children: React.ReactNode;
  customStyles?: object;
}

export class Col extends Component<ColProps> {
  render() {
    const customStyles = Object.assign({}, this.props.customStyles, colStyles.container);

    return <View style={customStyles}>{this.props.children}</View>;
  }
}

const colStyles = StyleSheet.create({
  container: {
    marginLeft: 5,
    marginRight: 5,
  },
});
