
import { StyleSheet, Platform } from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';

export const styles = StyleSheet.create({
  feel: {
    borderWidth: 2,
    borderColor: 'yellow',
  },
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
  web: {
    display: Platform.OS === 'web' ? 'flex' : 'none',
    backgroundColor: '#f7f7f7',
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2679b0',
    height: hp('50%'),
    padding: 30,
  },
  recentTransactionContainer: {
    flexDirection: 'column',
    padding: 30,
  },
  totalsaving: {
    color: '#03d85e',
    fontSize: 20,
  },
  actionIcons: {
    width: 50,
    height: 50,
  },
  depositIcons: {
    width: 50,
    height: 50,
  },
  dashboardIcons: {
    width: 60,
    height: 25,
  },
  symbolImage: {
    width: 40,
    height: 40,
  },
  mifosImage: {
    width: 40,
    height: 40,
    marginTop: 5,
  },
  sideBarIcons: {
    width: 25,
    height: 25,
  },
  loanActionIcons: {
    width: 100,
    height: 50,
  },
  transactionIcons: {
    marginTop: 2,
    width: 25,
    height: 25,
  },
  currency: {
    color: 'white',
    fontSize: 40,
    marginTop: 22,
  },
  ugx: {
    color: 'white',
    fontSize: 18,
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: 'white',
    marginTop: 80,
    width: wp('81%'),
  },
  recentTransactionDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 5,
    width: '100%',
  },
  transactionsDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 20,
    width: wp('83%'),
  },
  quickActionView: {
    color: 'white',
    marginTop: 20,
    alignSelf: 'flex-start',
  },
  quickActionsText: {
    color: 'white',
    fontSize: 20,
  },
  iconsText: {
    color: 'white',
  },
  recentTransactionText: {
    fontSize: 18,
    color: '#737373',
    fontWeight: 'bold',
  },
  SeeAllText: {
    fontSize: 18,
    color: '#2175ad',
    // fontWeight:"bold"
  },
  recentTransactionColor: {
    color: '#737373',
  },
  dateLabel: {
    color: '#2679b0',
    fontWeight: 'bold',
  },
  card: {
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.1,
    width: '95%',
  },
  depositCard: {
    height: '100%',
    backgroundColor: '#62c341',
  },
  sideBarCard: {
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.1,
    width: '90%',
    height: '99%',
    marginTop: -10,
    paddingTop: 30,
  },
  sideBarText: {
    color: 'grey',
  },
  savingsText: {
    fontWeight: 'bold',
    color: '#5eb158',
    fontSize: 25,
  },
  currencyText: {
    fontWeight: 'bold',
    color: '#606060',
    fontSize: 28,
  },
  cardSubtitleText: {
    marginTop: 20,
    color: 'grey',
    fontSize: 12,
  },
  expoIcons: {
    marginTop: -2,
    marginLeft: 3,
  },
  datelabel: {
    color: '#2679b0',
    fontWeight: 'bold',
  },
  versionlabel: {
    color: 'grey',
    fontWeight: 'bold',
  },
  versionContext: {
    color: '#a0a0a0',
    // fontWeight: 'bold',
  },
  versionSubtitle: {
    color: '#a0a0a0',
    marginTop: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  cardHeaders: {
    fontWeight: 'bold',
    fontSize: 20,
    color: 'grey',
  },
});

export default { styles };
