/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { View, Text, TouchableHighlight, SafeAreaView, Platform } from 'react-native';
import { TextInput, Provider, Button } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import DropDown from 'react-native-paper-dropdown';
import Activity from '../../components/activityIndicator/ActivityIndicator';
import { AccountService } from '../../components/common/account.service';
import { useToast } from 'react-native-paper-toast';

import Row, { Col } from '../../components/Row';
import { styles } from './ShareStyles';
import moment from 'moment';

export default function ShareMobile({ navigation }: any) {
  const SavingsAccounts = useSelector((state) => (state as any).savingsAccounts);
  const toaster = useToast();
  const productOptions = useSelector((state) => (state as any).shareProductList.pageItems);
  const accountService = new (AccountService as any)();
  const [loader, setLoader] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [numberOfShares, setNumberOfShares] = useState(0);

  const [shareProducts, setShareProducts] = useState([]);
  const [savingsAccounts, setSavingsAccounts] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showAccountsDropDown, setShowAccountsDropDown] = useState(false);
  const [shareProduct, setShareProduct] = useState('');
  const [savingsAccount, setSavingsAccount] = useState('');
  const [finalSavingsAccounts, setFinalSavingsAccounts] = useState([])
  
  useEffect(() => {

    const tempSavingsAccounts: any = []; 

    if (savingsAccounts) {
      savingsAccounts.forEach((i: any) => {
        tempSavingsAccounts.push({ label: i.accountNo, value: i.id });
      });
    }

    setFinalSavingsAccounts(tempSavingsAccounts)
  }, [savingsAccounts])

  useEffect(() => {
    const tempProducts: any = [];

    if (productOptions) {
      productOptions.forEach((i: any) => {
        tempProducts.push({ label: i.name, value: i.id });
      });
    }
    setShareProducts(tempProducts);

  }, []);

  useEffect(() => {

    const tempSavingsAccounts: any = []

    if (savingsAccounts) {
      savingsAccounts.forEach((i: any) => {
        tempSavingsAccounts.push({ label: i.accountNo, value: i.id });
      });
    }

    setFinalSavingsAccounts(tempSavingsAccounts)

  }, [])


  const onChangeShareProducts = async (productId: any) => {
    setIsDone(false);
    const clientId = await accountService.getClientId();
    getShareTemplate(clientId, productId);
    setShareProduct(productId);
  };

  const onChangeSavingsAccount = async (productId: any) => {
    setIsDone(false);
    const clientId = await accountService.getClientId();
    getShareTemplate(clientId, productId);
    setSavingsAccount(productId);
  };

  const onSubmit = async () => {
    setLoader(true);

    if (!shareProduct || !numberOfShares || !savingsAccount) {

      toaster.show({
        message: 'Add all the required data ',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });

      return
    }

    const clientId = await accountService.getClientId();

    const shareApplicationData = {
      "clientId":clientId,
      "productId": shareProduct,
      "requestedShares": numberOfShares,
      "submittedDate": moment(new Date()).format('DD MMMM YYYY'),
      "applicationDate": moment(new Date()).format('DD MMMM YYYY'),
      "savingsAccountId": parseInt(savingsAccount),
      "locale":"en",
      "dateFormat":"dd MMMM yyyy"
    };

    
    accountService
      .shares(shareApplicationData)
      .then((resp: any) => {
        // Handling success
        const shareResponse = resp.data;

        toaster.show({
          message: 'Share Application Submitted Successfully.',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });
        
        setLoader(false);
      })
      .catch((error: any) => {
        // Handling error
        setLoader(false);
        const text = 'Share Application Not Submitted Kindly contact the Maintainer ';
        console.log(error, text);
      });
  };

  function getShareTemplate(clientId: any, productId: any) {
    const individualTemplate = {
      clientId,
      productId,
    };

    accountService
      .SharesTemplate(individualTemplate)
      .then((res: any) => {
        setNumberOfShares(res.data.productOptions[0].nominalShares);
        setIsDone(true);
      })
      .catch((error: any) => {
        const text = 'Share Application template Not Found ';
        console.log(text)
      });

      setSavingsAccounts(SavingsAccounts)
  }

  return (
    <Provider>
      <KeyboardAwareScrollView>
        <View style={[styles.containerMobile]}>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.headerLabel}>Apply For Shares</Text>
            </Col>
          </Row>

          <SafeAreaView>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={styles.labels}>
                  Share Product
                  {<Text style={{color: 'red'}}>*</Text>}
                </Text>
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <SafeAreaView>
                  <DropDown
                    placeholder="Select Share Product"
                    mode="outlined"
                    visible={showDropDown}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={shareProduct}
                    setValue={(value) => onChangeShareProducts(value)}
                    list={shareProducts}
                    dropDownStyle={styles.dropdownContainerStyle}
                    dropDownItemSelectedTextStyle={styles.dropdownSelectedStyle}
                  />
                </SafeAreaView>
              </Col>
            </Row>
          </SafeAreaView>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>
                Number Of Shares
                {<Text style={{color: 'red'}}>*</Text>}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1, color:"black" }}>
              <TextInput
                placeholder="Number Of Shares"
                value={numberOfShares.toString()}
                onChangeText={(e: any) => setNumberOfShares(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={styles.amountView}
              />
            </Col>
          </Row>

          <SafeAreaView>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={styles.labels}>
                  Savings Account
                  {<Text style={{color: 'red'}}>*</Text>}
                </Text>
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1 }}>
                <SafeAreaView>
                  <DropDown
                    placeholder="Select Savings Account"
                    mode="outlined"
                    visible={showAccountsDropDown}
                    showDropDown={() => setShowAccountsDropDown(true)}
                    onDismiss={() => setShowAccountsDropDown(false)}
                    value={savingsAccount}
                    setValue={(value) => onChangeSavingsAccount(value)}
                    list={finalSavingsAccounts}
                    dropDownStyle={styles.dropdownContainerStyle}
                    dropDownItemSelectedTextStyle={styles.dropdownSelectedStyle}
                  />
                </SafeAreaView>
              </Col>
            </Row>
          </SafeAreaView>

          {loader && <Activity />}

          <TouchableHighlight onPress={() => onSubmit()} disabled={loader}>
            <View style={[styles.depositButton, loader && styles.disabledButton]}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Submit For Review'}</Text>
            </View>
          </TouchableHighlight>
        </View>
      </KeyboardAwareScrollView>
    </Provider>
  );
}
