import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Image,
  Pressable,
  Platform,
  ScrollView,
} from 'react-native';

import React, { useState } from 'react';
import Checkbox from 'expo-checkbox';
import { TextInput, Button } from 'react-native-paper';
import { AccountService } from '../../components/common/account.service';
import { useToast } from 'react-native-paper-toast';

import Row, { Col } from '../../components/Row';
import TrackScreenHit from '../../utils/analytics';


export default function SelfRegisterWeb({ navigation }: any) {
  const [isChecked, setChecked] = useState(false);
  const [fsp, setFsp] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const toaster = useToast();
  TrackScreenHit("Registration Page")


  const accountService = new (AccountService as any)();

  //   async function getData() {
  //     await
  //     accountService

  // }
  // getData();

  const handleSubmit = async () => {
    const data = {
      username,
      accountNumber,
      password,
      firstName,
      lastName,
      email,
      authenticationMode: 'email',
    };

    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': fsp,
      },
    };
    // checking if the account number exist
    await accountService
      .register(data, apiHeaders)
      .then((res: any) => {
        navigation.navigate('ValidateRegister');
        return res.data;

        // Check if account number input exist
      })
      .catch((err: any) => {
        const errMsg = err.data.errors[0].defaultUserMessage;
        toaster.show({
          message: errMsg,
          duration: 5000,
          type: 'error',
          position: 'middle',
        });
      });
  };
  return (
    <View style={styleRegister.web}>
      <ImageBackground
        source={require('../../assets/cover.jpg')}
        style={{
          width: '100%',
          height: 748,
        }}
      >
        <ScrollView>
          <View style={styleRegister.centeredView}>
            <View style={styleRegister.styleView}>
              <Row>
                <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                  <Image
                    source={require('../../assets/kanzubanking_logo.png')}
                    style={{ height: 90, width: 90, marginTop: 10 }}
                  />

                  <Text style={styleRegister.text}>Create an account</Text>
                </Col>
              </Row>

              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View style={styleRegister.inputView}>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        label="Sacco/Investment club name"
                        placeholder="Sacco/Investment club name"
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setFsp(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        label="Account Number"
                        placeholder="Account Number"
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setAccountNumber(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Username"
                        label="Username"
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setUsername(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <View style={{ flexDirection: 'row' }}>
                        <TextInput
                          placeholder="First name"
                          label="First name"
                          style={{ width: 300 }}
                          onChangeText={(e: any) => setFirstName(e)}
                        />

                        <TextInput
                          placeholder="Last name"
                          label="Last name"
                          style={{ marginLeft: 20, width: 350 }}
                          onChangeText={(e: any) => setLastName(e)}
                        />
                      </View>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col customStyles={{ flex: 1 }}>
                   
                  </Col>
                </Row> */}

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Email"
                        label="Email"
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setEmail(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Password"
                        label="Password"
                        secureTextEntry
                        style={{ width: '100%' }}
                        onChangeText={(e: any) => setPassword(e)}
                      />
                    </Col>
                  </Row>
                </View>
              </View>

              <View style={styleRegister.container}>
                <View style={styleRegister.checkboxContainer}>
                  <Checkbox
                    style={styleRegister.checkbox}
                    value={isChecked}
                    onValueChange={setChecked}
                    color={isChecked ? '#4630EB' : undefined}
                  />

                  <Text style={styleRegister.label}>
                    I read and accept <Text style={{ color: '#2196F3' }}>Terms and Conditions</Text>
                  </Text>
                </View>

                <Pressable style={styleRegister.btn} onPress={handleSubmit}>
                  <Text style={{ color: 'white', fontSize: 16 }}>Create my account</Text>
                </Pressable>

                <Button
                  mode="text"
                  uppercase={false}
                  style={{ marginBottom: 10 }}
                  onPress={() => navigation.navigate('Login')}
                >
                  Already have an account ?
                  <Text style={{ color: '#2196F3', margin: 10, fontSize: 16 }}>Login</Text>
                </Button>
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
}

const styleRegister = StyleSheet.create({
  inputText: {
    width: '70%',
    padding: 10,
  },
  styleView: {
    backgroundColor: '#FFFFFF',
    margin: 20,
    flex: 1,
    width: '60%',
    // height: '100%',
    borderRadius: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputView: {
    alignSelf: 'center',
    width: '70%',
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  checkboxContainer: {
    flexDirection: 'row',
    margin: 5,
  },
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    margin: 8,
  },
  btn: {
    borderRadius: 4,
    padding: 10,
    elevation: 2,
    backgroundColor: '#2196F3',
    width: '30%',
    alignItems: 'center',
  },
  text: {
    marginTop: 10,
    fontSize: 22,
  },
  web: {
    display: Platform.OS === 'web' ? 'flex' : 'none',
    backgroundColor: '#f7f7f7',
  },
});
