/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { View, Text, TouchableHighlight, SafeAreaView, Platform, Alert, ActivityIndicator, Image } from 'react-native';
import { TextInput, Provider, Button } from 'react-native-paper';
import { useSelector } from 'react-redux';
import * as DocumentPicker from 'expo-document-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import DropDown from 'react-native-paper-dropdown';
import { useToast } from 'react-native-paper-toast';
import moment from 'moment';
import { AccountService } from '../../components/common/account.service';

import LoanApplicationCtrl from './loan-application.component';

import Row, { Col } from '../../components/Row';
import { styles } from './LoanStyles';
import RequiredTextInput from '../../components/RequiredTextInput';
import DropDownSelector from '../../components/DropDownSelector';

export default function LoanMobile({ navigation }: any) {
  const productOptions = useSelector((state) => (state as any).productList);
  const toaster = useToast();
  const accountService = new (AccountService as any)();
  const [isLoaded, setIsLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [amount, setAmount] = useState('0');
  const [loanTerm, setLoanTerm] = useState('0');
  const [docName, setDocName] = useState('');
  const [docDesc, setDocDesc] = useState('');
  const [loanComment, setLoanComment] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const [product, setProduct] = useState('');

  const [templateForm, setTemplateForm] = useState({});
  const [templateData, setTemplateData] = useState({
    locale: 'en_GB',
    dateFormat: 'dd MMMM yyyy HH:mm z',
    loanType: 'individual',
  });
  const [loanProducts, setLoanProducts] = useState([
    {
      label: 'Loading....',
      value: 'Loading....',
    },
  ]);
  const [genderList, setGenderList] = useState([
    {
      label: 'purpose',
      value: 'purpose',
    }
  ]);
  const [maxPrincipal, setMaxPrincipal] = useState(false);
  const [minPrincipal, setMinPrincipal] = useState(false);

  type Document = {
    name: string;
    uri: string;
  };

  // Required Error checking
  const [docNameError, setDocNameError] = useState('');
  const [docDescError, setDocDescError] = useState('');
  const [amountError, setAmountError] = useState('');


  const loanApplicationCtrl = new (LoanApplicationCtrl as any)();

  useEffect(() => {
    const tempProducts: any = [];
    const calculatorTempProducts: any = [];
    if (productOptions) {
      productOptions.forEach((i: any) => {
        tempProducts.push({ label: i.name, value: i.id });
        calculatorTempProducts.push(i.name)
      });
    }

    setLoanProducts(tempProducts);
  }, []);
  
  const onChangeLoanproducts = async (productId: any) => {
    if (productId !== product) {
      setIsDone(false);
      const clientId = await accountService.getClientId();
      getLoanTemplate(clientId, productId);
      setProduct(productId);
    }
  };

  function getLoanTemplate(clientId: any, productId: any) {
    const individualTemplate = {
      templateType: 'individual',
      clientId,
      productId,
    };

    accountService
      .template(individualTemplate)
      .then((res: any) => {
        const date = moment(new Date()).format('DD MMMM yyyy HH:mm');
        setTemplateForm(res.data);
        const tempobj = {};
        (tempobj as any).principal = res.data.principal;
        (tempobj as any).submittedOnDate = date + " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
        (tempobj as any).expectedDisbursementDate = date + " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTemplateData({ ...templateData, ...tempobj });
        setAmount(res.data.principal);
        setLoanTerm(res.data.numberOfRepayments.toString());
        const updatedGenderList = [...genderList];
          if (res.data.loanPurposeOptions && res.data.loanPurposeOptions.length > 0) {
            res.data.loanPurposeOptions.forEach((purpose: any) => {
              updatedGenderList.push({
                label: purpose.name,
                value: purpose.name,
              });
            });
          }
          setGenderList(updatedGenderList);
          setMaxPrincipal(res.data.product.maxPrincipal)
          setMinPrincipal(res.data.product.minPrincipal)
        setIsDone(true);
      })
      .catch((error: any) => {
        const text = 'Loan Application template Not Found ';
        console.log(text);
      });
  }

  const [doc, setDoc] = useState<Document | null>(null);
  const [imagePreview, setImagePreview] = useState('');
  const pickDocument = async () => {
    await DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    }).then((response) => {
      if (response.type === 'success') {
        const { file, name, mimeType } = response;
        setDoc({ name: response.name, uri: response.uri });
  
        let uri = response.uri;
        if (Platform.OS === 'android' && uri[0] === '/') {
          uri = `file://${uri}`;
          uri = uri.replace(/%/g, '%25');
        }
  
        setDoc(file);

        if (mimeType && mimeType.startsWith('image/')) {
          setImagePreview(uri);
        } else {
          setImagePreview('');
        }
      }
    });
  };

  const onSubmit = async () => {
    setLoader(true);

    if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
      setAmountError('Please enter a valid amount');
      setLoader(false);
      return;
    }

    if (docName == "") {
      setAmountError('Please enter a valid document name');
      setLoader(false);
      return;
    }

    if (product == "") {
      toaster.show({
        message: 'Please enter a valid Account Type.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
      setLoader(false);
      return;
    }

    if (docDesc == "") {
      setAmountError('Please enter a valid description');
      setLoader(false);
      return;
    }

    const clientId = await accountService.getClientId();

    const fileData = new FormData();
    fileData.append('file', doc);
    fileData.append('description', docDesc);
    fileData.append('name', docName);

    const loanTemp = {
      clientId,
      productId: product,
      loanTermFrequency: parseInt(loanTerm),
      loanTermFrequencyType: (templateForm as any).termPeriodFrequencyType.id,
      numberOfRepayments: parseInt(loanTerm),
      repaymentEvery: (templateForm as any).repaymentEvery,
      repaymentFrequencyType: (templateForm as any).repaymentFrequencyType.id,
      interestRatePerPeriod: (templateForm as any).interestRatePerPeriod,
      amortizationType: (templateForm as any).amortizationType.id,
      interestType: (templateForm as any).interestType.id,
      interestCalculationPeriodType: (templateForm as any).interestCalculationPeriodType.id,
      transactionProcessingStrategyId: (templateForm as any).transactionProcessingStrategyId,
    };
    (templateData as any).principal = amount;
    const data = { ...templateData, ...loanTemp };
    accountService
      .loan(data)
      .then((resp: any) => {
        const loanResponse = resp.data;

        toaster.show({
          message: 'Loan Application Submitted Successfully.',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });

        if(loanComment) {
          let notes = {
            note : loanComment
          }
          accountService.notes(loanResponse.loanId, notes)
        }

        setLoader(false);
        if(doc) {
          setLoader(true);

          toaster.show({
            message: 'Uploading File In Progress',
            duration: 5000,
            type: 'warning',
            position: 'middle',
          });

          submitFile(loanResponse.loanId, fileData);
        }
      })
      .catch((error: any) => {
        setLoader(false);
        const text = 'Loan Application Not Submitted Kindly contact the Maintainer ';
        console.log(error, text);

        toaster.show({
          message: 'Loan Application Not Successful.',
          duration: 5000,
          type: 'error',
          position: 'middle',
        });

      });
  };

  const submitFile = async (loanId: any, data: any) => {
    let text = '';
    accountService
      .loanDocUpload(loanId, data)
      .then((response: any) => {
        setLoader(false);
        toaster.show({
          message: 'Loan Application Submitted Successfully.',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });
        (navigation as any).replace('Home', { screen: 'DashBoard' });
      })
      .catch((error: any) => {
        setLoader(false);
        toaster.show({
          message: 'Loan Application Not Successful.',
          duration: 5000,
          type: 'error',
          position: 'middle',
        });
      });
  };
  return (
    <Provider>
      <KeyboardAwareScrollView>
        <View style={[styles.container]}>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.headerLabel}>New Loan Application</Text>
            </Col>
          </Row>

          <SafeAreaView>
            <Row>
              <Col customStyles={{ flex: 1 }}>
                <SafeAreaView>
                  <DropDown
                    placeholder="Select Loan Product"
                    mode="outlined"
                    visible={showDropDown}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={product}
                    setValue={(value) => onChangeLoanproducts(value)}
                    list={loanProducts}
                    dropDownStyle={styles.dropdownContainerStyle}
                    dropDownItemSelectedTextStyle={styles.dropdownSelectedStyle}
                  />
                </SafeAreaView>
              </Col>
            </Row>
          </SafeAreaView>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>Principal Amount</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Principal Amount"
                value={isDone ? String(amount) : '0'}
                onChangeText={(e: any) => setAmount(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
              />
            </Col>
          </Row>

          {
            (maxPrincipal && minPrincipal) ? 
              (<Row>
                <Col customStyles={{ flex: 1, flexDirection:"row", margin:0 }}>
                  <Text style={{color: '#2679b0'}} >Min : <Text style={{color: '#3f1717'}} >{minPrincipal}</Text></Text>  <Text style={{color: '#2679b0', marginLeft:30}} >Max : <Text style={{color: '#3f1717'}} >{maxPrincipal}</Text></Text>
                </Col>
              </Row>)
            :
              ""
          }

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>Loan Term (Months)</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Loan Term"
                value={loanTerm}
                onChangeText={(e: any) => setLoanTerm(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>Currency</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="UGANDA SHILLINGS (UGX)"
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>
                Purpose
                <Text style={{color: 'red'}}>*</Text>
              </Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <DropDownSelector
                placeholder="Purpose of the Loan "
                list={genderList}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <RequiredTextInput
                label="Document Name"
                value={docName}
                onChange={(e: any) => setDocName(e)}
                required
                error={docNameError}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={[styles.amountView]}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <RequiredTextInput
                label="Document Description"
                value={docDesc}
                onChange={(e: any) => setDocDesc(e)}
                required
                error={docDescError}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={[styles.amountView]}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <View>
                <Button
                  onPress={pickDocument}
                  mode="contained"
                  color="rgb(41, 113, 182)"
                  style={styles.buttonSize}
                >
                  Select Document
                </Button>
              </View>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1, marginBottom: 5 }}>
              <View style={styles.container}>
                {imagePreview ? (
                  <View>
                    <Image
                      source={{ uri: imagePreview }}
                      style={{ width: 100, height: 100 }}
                    />
                  </View>
                ) : doc ? (
                  <View>
                    <Text style={{fontSize: 18, color: '#2679b0', fontWeight: 'bold'}}>Attached Document:</Text>
                    <Text style={{fontSize: 17}}>{doc.name.toUpperCase()}</Text>
                  </View>
                ) : null}
              </View>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>Loan Description / Comment</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                placeholder="Loan comment or description"
                value={loanComment}
                onChangeText={(e: any) => setLoanComment(e)}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
              />
            </Col>
          </Row>

          {loader && (
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <ActivityIndicator size="large" color='rgb(30,136,229)' />
            </View>
          )}

            <TouchableHighlight onPress={() => onSubmit()} disabled={loader}>
              <View style={[styles.loanButton, loader && styles.disabledButton]}>
                <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Review'}</Text>
              </View>
            </TouchableHighlight>
        </View>
      </KeyboardAwareScrollView>
    </Provider>
  );
}

