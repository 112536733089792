/* eslint-disable react-hooks/rules-of-hooks */
import { useMediaQuery } from 'react-responsive';

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '800px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

export const isPhone = () => {
  return useMediaQuery({
    query: `${devices.mobileL}`,
  });
};

export default { devices };
