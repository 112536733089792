import { useEffect } from 'react';
import { Analytics, PageHit } from 'expo-analytics';

const analytics = new Analytics('UA-140824610-3');

export const TrackScreenHit = (ScreenName: string): void => {
  useEffect(() => {
    analytics.hit(new PageHit(ScreenName));
  }, [ScreenName]);
};
export default TrackScreenHit;