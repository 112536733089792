/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  View,
  Image,
  ScrollView,
  Dimensions,
  StyleSheet,
  Text,
  ActivityIndicator,
} from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { useToast } from 'react-native-paper-toast';

import Row, { Col } from '../../components/Row';
import { AccountService } from '../../components/common/account.service';
import { Constants } from '../../Constants/Constants';
import DashboardCtrl from '../dashboard/dashboard.component';

import { styles } from './loginStyles';

const { BASE_URL } = Constants;

import axios from 'axios';

const fontColor = 'rgb(30,136,229)';

const ScreenHeight = Dimensions.get('window').height;

export default function LoginMobile({ navigation }: any) {
  const toaster = useToast();
  const [username, setUsername] = useState('');
  const [fsp, setFsp] = useState('');
  const [loader, setLoader] = useState(false);

  const accountService = new (AccountService as any)();
  const [password, setPassword] = useState('');

  const dashboardCtrl = new (DashboardCtrl as any)();

  const onSubmit = async () => {
    setLoader(true);
    const userData = { username, fsp, password };

    doLogin(userData);
  };
  async function doLogin(data: any) {
    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': data.fsp,
      },
    };
    const params = {
      username: data.username,
      password: data.password,
    };

    axios
      .post(`${BASE_URL}self/authentication`, params, apiHeaders)
      .then(async (res: any) => {
        accountService.setTenantId(fsp);
        accountService.setAuth(username, password, fsp);
        accountService.setIsSignedIn(true);

        await accountService
          .getClients()
          .then((resp: any) => {
            if (resp.data.pageItems.length !== 0) {
              const clientId = resp.data.pageItems[0].id;
              accountService.setClientId(clientId);
              setLoader(false);
              dashboardCtrl.getDashboardData();

              navigation.replace('Home', { screen: 'DashBoard' });
            }
          })
          .catch((error: any) => {
            setLoader(false);
            toaster.show({
              message: 'Not a self service User.',
              duration: 5000,
              type: 'error',
              position: 'middle',
            });
          });
      })
      .catch((error: any) => {
        setLoader(false);
        toaster.show({
          message: 'Invalid Password or Username.',
          duration: 5000,
          type: 'error',
          position: 'middle',
        });
      });
  }
  return (
    <ScrollView style={loginMobileStyles.container}>
      <KeyboardAwareScrollView>
        <View style={[styles.mobile]}>
          <View
            style={{
              flexDirection: 'row',
              height: ScreenHeight,
            }}
          >
            <View style={loginMobileStyles.mainView}>
              <Row>
                <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                  <Image
                    source={require('../../../assets/appIcon.png')}
                    style={loginMobileStyles.mifosLogo}
                  />
                </Col>
              </Row>

              <Row>
                <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                  <Text style={loginMobileStyles.loginText}>Login</Text>
                </Col>
              </Row>
              {loader && (
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <ActivityIndicator size="large" color="white" />
                </View>
              )}

              <View style={{ margin: 25, marginTop: hp('3') }}>
                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <TextInput
                      placeholder="Financial Services Provider"
                      style={[loginMobileStyles.inputs]}
                      onChangeText={(e: any) => setFsp(e)}
                      underlineColor="transparent"
                      theme={theme}
                      placeholderTextColor="#FFFFFF"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <TextInput
                      placeholder="Username"
                      style={loginMobileStyles.inputs}
                      onChangeText={(e: any) => setUsername(e)}
                      underlineColor="transparent"
                      placeholderTextColor="#FFFFFF"
                      theme={theme}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <TextInput
                      placeholder="Password"
                      style={loginMobileStyles.inputs}
                      secureTextEntry
                      theme={theme}
                      placeholderTextColor="#FFFFFF"
                      underlineColor="transparent"
                      onChangeText={(e: any) => setPassword(e)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                    <View>
                      <Button
                        mode="contained"
                        uppercase={false}
                        color={fontColor}
                        onPress={() => onSubmit()}
                        style={loginMobileStyles.nextButton}
                      >
                        LOGIN
                      </Button>
                    </View>
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                    <View>
                      <Button mode="text" uppercase={false} color="#FFFFFF">
                        Forgot password
                      </Button>
                    </View>
                  </Col>
                </Row>

                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <View>
                      <Button
                        mode="text"
                        uppercase={false}
                        color="#FFFFFF"
                        onPress={() => navigation.navigate('Register')}
                      >
                        <Text style={{ fontSize: 13 }}>New User ? Create an account</Text>
                      </Button>
                    </View>
                  </Col>
                </Row>
              </View>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScrollView>
  );
}

const loginMobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#0261a1',
  },
  inputs: {
    backgroundColor: 'transparent',
    color: 'white',
    borderWidth: 1,
    // height: 50,
    borderColor: 'white',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  btnColor: {
    color: 'white',
  },

  mifosLogo: {
    height: 150,
    width: 150,
  },
  loginText: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginTop: -hp('2'),
  },
  mainView: {
    flex: 1,
    marginTop: hp('5'),
  },
  nextButton: {
    width: wp('58'),
    height: hp('6'),
    justifyContent: 'center',
    borderRadius: 20,
  },
});

const theme = { colors: { text: 'white', primary: 'white' } };
