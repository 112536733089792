const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const getMonth = (index: any) => {
  return months[index - 1];
};

export const dateFormat = (date: any) => {
  return `${date[2]} ${getMonth(date[1])} ${date[0]}`;
};
export const numberWithCommas = (x: any) => {
  return parseInt(x, 10)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default { getMonth };
