/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-import-module-exports */
import React, { useState, useEffect } from 'react';

import { SafeAreaView, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import { useSelector } from 'react-redux';

import Row, { Col } from '../../components/Row';
import { numberWithCommas, dateFormat } from '../../components/common/common';

export default function AccountsMobile(prop: any) {
  const [selection, setSelection] = useState(1);
  const { navigation } = prop;
  const SavingsAccounts = useSelector((state) => (state as any).savingsAccounts);
  const LoanAccounts = useSelector((state) => (state as any).loanAccounts);
  const ShareAccounts = useSelector((state) => (state as any).shareAccounts);
  const [showClosed, setShowClosed] = useState(false);

  const sortedLoanAccounts = LoanAccounts.filter((acc : any) => acc.status.active === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedSavingsAccounts = SavingsAccounts.filter((acc : any) => acc.status.active === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedSharesAccounts = ShareAccounts.slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);

  const sortedClosedLoanAccounts = LoanAccounts.filter((acc : any) => acc.status.closed === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedClosedSavingsAccounts = SavingsAccounts.filter((acc : any) => acc.status.closed === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedClosedSharesAccounts = ShareAccounts.filter((acc : any) => acc.status.closed === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);

  const options = ['Savings', 'Loans', 'Shares']

  const viewAccountCard = (accountType: any) => {
    return accountType.map((i: any, j: any) => {
      const dateFormat = (date: any) => {
        if (date !== undefined) {
          return `${date[2]}-${date[1]}-${date[0]}`;
        } else {
          return '';
        }
      };
      return (
        <View key={j}>
          <Card
            onPress={() =>
              selection === 1
                ? navigation.navigate('Saving Detail', { accountNo: i.accountNo })
                : selection === 2
                ? navigation.navigate('Loan Detail', { accountNo: i.accountNo })
                : navigation.navigate('Share Detail', { accountNo: i.accountNo })
            }
          >
            <Card.Content>
              <View
                style={[
                  styles.accountCard,
                  i.status.value !== 'Active' ? { borderColor: 'orange' } : null,
                ]}
              >
                <Col
                  customStyles={{
                    flex: 1,
                    paddingLeft: 10,
                  }}
                >
                  <Text>{i.accountNo}</Text>

                  <Text>{i.productName}</Text>

                  {/* <Text>
                    {(selection === 1 && i.timeline?.activatedOnDate) 
                      ? `${dateFormat(i.timeline.activatedOnDate)} `
                      : (selection === 2 && i.timeline?.expectedDisbursementDate)
                      ? ` ${dateFormat(i.timeline.expectedDisbursementDate)} `
                      : (selection !== 1 && selection !== 2 && i.timeline?.activatedDate)
                      ? `${dateFormat(i.timeline.activatedDate)} `
                      : null}
                  </Text> */}
                </Col>

                <Col customStyles={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                  {i.status.value === 'Active' ? (
                    <Text style={[styles.amount, { color: 'green' }]}>
                      {selection === 1
                        ? `USh ${numberWithCommas(i.accountBalance)} `
                        : selection === 2
                        ? `USh ${numberWithCommas(i.loanBalance)} `
                        : null}
                    </Text>
                  ) : (
                    <Text style={[styles.amount]}>
                      {selection === 1 ? `USh ${i.accountBalance} ` : i.loanBalance}
                    </Text>
                  )}
                </Col>
              </View>
            </Card.Content>
          </Card>
        </View>
      );
    });
  };

  const noAccount = () => <Text>No account found</Text>;
  const renderAccount = () => {
    switch (selection) {
      case 1:
        return viewAccountCard(showClosed ? sortedClosedSavingsAccounts : sortedSavingsAccounts);

      case 2:
        return viewAccountCard(showClosed ? sortedClosedLoanAccounts : sortedLoanAccounts);

      case 3:
        return viewAccountCard(showClosed ? sortedClosedSharesAccounts : sortedSharesAccounts);

      default:
        return null;
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.btnGroup}>
        <TouchableOpacity
          style={[styles.btn, selection === 1 ? styles.activeBtn : null]}
          onPress={() => {setSelection(1), setShowClosed(false)}}
        >
          <Text style={[styles.btnText, selection === 1 ? { color: 'white' } : null]}>SAVINGS</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.btn, selection === 2 ? styles.activeBtn : null]}
          onPress={() => {setSelection(2), setShowClosed(false)}}
        >
          <Text style={[styles.btnText, selection === 2 ? { color: 'white' } : null]}>LOAN</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.btn, selection === 3 ? styles.activeBtn : null]}
          onPress={() => {setSelection(3), setShowClosed(false)}}
        >
          <Text style={[styles.btnText, selection === 3 ? { color: 'white' } : null]}>SHARE</Text>
        </TouchableOpacity>
      </View>
      {renderAccount()}

      {
        (sortedClosedLoanAccounts.length > 0 || sortedClosedSavingsAccounts.length > 0 || sortedClosedSharesAccounts.length > 0) ? 
        <TouchableOpacity
          // style={[styles.btn, selection === 3 ? styles.activeBtn : null]}
          style={{backgroundColor: 'rgb(41, 113, 182)', paddingHorizontal: 10, width: "fit-content", margin: 5, marginHorizontal: 'auto', borderRadius: 6}}
          onPress={() => setShowClosed(!showClosed)}
        >
          <Text style={[styles.btnText2, selection === 3 ? { color: 'white' } : null]}>{showClosed ? `View Active ${options[selection - 1]}` : `View Closed ${options[selection - 1]}` }</Text>
        </TouchableOpacity> 
        : 
        ''
      }
      

    </SafeAreaView>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  btnGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomColor: '#00008b',
    backgroundColor: '#2679b0',
    shadowColor: '#000000',
    shadowRadius: 20,
    shadowOpacity: 0.01,
  },
  activeBtn: {
    borderBottomWidth: 3,
    borderBottomColor: 'white',
  },
  btn: {
    flex: 1,
  },
  btnText: {
    textAlign: 'center',
    paddingVertical: 16,
    fontSize: 14,
    color: 'white',
  },
  btnText2: {
    textAlign: 'center',
    paddingVertical: 16,
    fontSize: 20,
    color: 'white',
  },
  accountCard: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    borderLeftWidth: 4,
    borderColor: 'green',
  },
  amount: {
    fontSize: 20,
  },
  share: {
    margin: 10,
    padding: 10,
  },
});
