/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { Text, ImageBackground,Image, TouchableWithoutFeedback } from 'react-native';
import { FontAwesome,MaterialCommunityIcons,MaterialIcons,Ionicons, Entypo } from '@expo/vector-icons';
import { styles } from '../../screens/dashboard/DashboardStyles';
import Row, { Col } from '../Row';


export default function SideMenu({ navigation, page }: any) {
  const profileImage = useSelector((state) => (state as any).image);
  const user = useSelector((state) => (state as any).loggedInUser);

  return (
    <>
      <Card style={[styles.sideBarCard]}>
        <ImageBackground
          source={require('../../assets/kb-blue-background.png')}
          style={{
            width: '75%',
            marginTop: 170,
            marginRight: -70,
            height: 350,
            position: 'absolute',
            //   bottom: 0,
            //   top: 1,
            right: 0,
          }}
        />

        <Row>
          <Col customStyles={{ flex: 2, alignItems: 'center', marginBottom: '20px' }}>
            <Image style={styles.img} source={{ uri: profileImage }} />
            <Text style={[styles.sideBarText, { marginTop: '15px', fontWeight: 'bold' }]}>
              {user?.firstname} {user?.lastname}
            </Text>
          </Col>
        </Row>

        <Card.Content>
          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <Ionicons name="home-outline" size={25} color= {page === 'Dashboard' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('DashBoard')}>
                <Text style={[styles.sideBarText, page === 'Dashboard' ? styles.active : null]}>
                  Dashboard
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <MaterialCommunityIcons name="piggy-bank-outline" size={25}  color= {page === 'Accounts' ? 'rgb(41, 113, 182)':"grey" }   />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback
                onPress={() => {
                  navigation.navigate('Accounts');
                }}
              >
                <Text style={[styles.sideBarText, page === 'Accounts' ? styles.active : null]}>
                  Accounts
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <MaterialIcons name="payment" size={25} color= {page === 'Deposit' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('Deposit')}>
                <Text style={[styles.sideBarText, page === 'Deposit' ? styles.active : null]}>
                  Submit a Deposit
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <MaterialCommunityIcons name="hand-coin-outline" size={25} color= {page === 'Loan' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('Loan')}>
                <Text style={[styles.sideBarText, page === 'Loan' ? styles.active : null]}>
                  Apply For Loan
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <Entypo name="slideshare" size={25} color= {page === 'Share' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('Share')}>
                <Text style={[styles.sideBarText, page === 'Share' ? styles.active : null]}>
                  Apply For Shares
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>
          

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <MaterialCommunityIcons name="clipboard-clock-outline" size={25} color= {page === 'Recent Transactions' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('Recent Transactions', {tabName : 'Recent Transactions'})}>
                <Text
                  style={[
                    styles.sideBarText,
                    page === 'Recent Transactions' ? styles.active : null,
                  ]}
                >
                  Recent Transactions
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <MaterialCommunityIcons name="account-details-outline" size={25} color= {page === 'Self Reported Transactions' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('Recent Transactions', {tabName : 'Self Reported Transactions'})}>
                <Text
                  style={[
                    styles.sideBarText,
                    page === 'Self Reported Transactions' ? styles.active : null,
                  ]}
                >
                  Self Reported Transactions
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <FontAwesome name="paper-plane" size={25} color= {page === 'Withdraw Request' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>
            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('Withdraw Request')}>
                <Text
                  style={[styles.sideBarText, page === 'Withdraw Request' ? styles.active : null]}
                >
                  Request Withdraw
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>
          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <FontAwesome name="user" size={25} color= {page === 'Profile' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback onPress={() => navigation.navigate('Profile')}>
                <Text style={[styles.sideBarText, page === 'Profile' ? styles.active : null]}>
                  Profile
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 2, alignItems: 'center' }}>
              <FontAwesome name="sign-out" size={25} color= {page === 'Auth' ? 'rgb(41, 113, 182)':"grey"} />
            </Col>

            <Col customStyles={{ flex: 4, paddingTop: 5 }}>
              <TouchableWithoutFeedback
                onPress={() => {
                  navigation.navigate('Auth');
                }}
              >
                <Text style={[styles.sideBarText, page === 'Auth' ? styles.active : null]}>
                  Logout
                </Text>
              </TouchableWithoutFeedback>
            </Col>
          </Row>
        </Card.Content>
      </Card>
    </>
  );
}
