// @ts-nocheck
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';

import { View, Text, Image, TouchableHighlight, SafeAreaView, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { TextInput, Provider, Button } from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';
import { DatePickerModal } from 'react-native-paper-dates';
import moment from 'moment';
import * as DocumentPicker from 'expo-document-picker';
import { useToast } from 'react-native-paper-toast';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { useSelector } from 'react-redux';
import { store } from '../../redux/index';

import Row, { Col } from '../../components/Row';
import Activity from '../../components/activityIndicator/ActivityIndicator';
import { styles } from './SelfDepositStyles';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import { AccountService } from '../../components/common/account.service';
import RequiredTextInput from '../../components/RequiredTextInput';
// import { color } from 'react-native/Libraries/Components/View/ReactNativeStyleAttributes';

export default function SelfDepositMobile({ navigation }: any) {
  const accountService = new (AccountService as any)();
  const toaster = useToast();
  const [docName, setDocName] = useState('');
  const [docDesc, setDocDesc] = useState('');
  const [amount, setAmount] = useState('');
  const [loader, setLoader] = useState(false);
  const [accounts, setAccounts] = useState([
    {
      label: 'Loading....',
      value: 'Loading....',
    },
  ]);

  const [filteredSavingsAccounts, setFilteredSavingsAccounts] = useState([]);
  const [filteredLoanAccounts, setFilteredLoanAccounts] = useState([]);
  let loanAccounts = useSelector((state) => (state as any).loanAccounts);
  let savingsAccounts = useSelector((state) => (state as any).savingsAccounts);

  const [choices, setChoices] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [accounttype, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [addedAccounts, setAddedAccounts] = React.useState(true);

  // Required Error checking
  const [docNameError, setDocNameError] = useState('');
  const [docDescError, setDocDescError] = useState('');
  const [amountError, setAmountError] = useState('');

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params: any) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  const accountsMap = {
    'Savings': filteredSavingsAccounts,
    'Loan': filteredLoanAccounts
  };

  const showAccounts = () => {
    const tempObj = {
      id: choices.length + 1,
      locale: 'en',
      accountType: accounttype,
      accountId: parseInt(accountNumber, 10),
      amount: amount,
    };
    setChoices([...choices, tempObj]);
    setAddedAccounts(false)
    setShow(true);
  }

  useEffect(() => {

    const savings = savingsAccounts.filter(acc => acc.status.active === true).map((x: any) => ({
      label: x.accountNo,
      value: x.accountNo,
    }));
    const loans = loanAccounts.filter(acc => acc.status.active === true).map((x: any) => ({
      label: x.accountNo,
      value: x.accountNo,
    }));

    setFilteredSavingsAccounts(savings)
    setFilteredLoanAccounts(loans)

  }, []);

  const onSubmit = async () => {
    setLoader(true);
  
    if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
      setAmountError('Please enter a valid amount');
      setLoader(false);
      return;
    }
  
    if (accounttype === "") {
      toaster.show({
        message: 'Please enter a valid Account Type.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
      setLoader(false);
      return;
    }
  
    if (accountNumber === "") {
      toaster.show({
        message: 'Please enter a valid Account number.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
      setLoader(false);
      return;
    }
  
    if (docName === "") {
      setDocNameError('Please enter a valid document name');
      setLoader(false);
      return;
    }
  
    if (docDesc === "") {
      setDocDescError('Please enter a valid description');
      setLoader(false);
      return;
    }
  
    try {
      const data = new FormData();
      data.append('file', doc);
      data.append('description', docDesc);
      data.append('name', docName);
  
      await submitFile(data); // wait for the submission to complete
    } catch (error) {
      console.error('Error during submission:', error);
    } finally {
      setLoader(false); // Set loader to false after submission is complete
    }
  };
  
  const submitFile = async (data) => {
    try {
      const tempObj = {
        id: choices.length + 1,
        locale: 'en',
        accountType: accounttype,
        accountId: parseInt(accountNumber, 10),
        amount: amount,
      };
  
      const mobileMoneyPaymentTypeId = 4;
      const reportData = {
        paymentTypeId: mobileMoneyPaymentTypeId,
        transactionDate: moment(date).format(moment.HTML5_FMT.DATE),
        transactionId: null,
        paymentProof: null,
        split: addedAccounts ? [tempObj] : choices,
      };
  
      const clientId = await accountService.getClientId();
      const response = await accountService.docUpload(clientId, data);
      reportData.paymentProof = response.data.resourceId;
  
      await accountService.selfReporting(clientId, reportData);
  
      toaster.show({
        message: 'Your application has been submitted now pending approval.',
        duration: 5000,
        type: 'success',
        position: 'middle',
      });
      setAddedAccounts(false);
      (navigation as any).replace('Home', { screen: 'DashBoard' });
    } catch (error) {
      console.error('Error in submitFile:', error);
      toaster.show({
        message: 'An error occurred during submission. Please try again.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
    }
  };

  const onChangeAccountType = (e) => {
    setAccountType(e);
    if (e === 'Savings') {
      setAccounts(accountsMap['Savings']);
    } else if (e === 'Loan') {
      setAccounts(accountsMap['Loan']);
    }
  };

  const onChangeAccountNumber = (e: any) => {
    setAccountNumber(e);
  };

  const accountType = [
    {
      label: 'Loan',
      value: 'Loan',
    },
    {
      label: 'Savings',
      value: 'Savings',
    },
  ];

  const [doc, setDoc] = useState();
  const [imagePreview, setImagePreview] = useState();

  const pickDocument = async () => {
    await DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    }).then((response: { type?: any; file?: any; uri?: any }) => {
      if (response.type === 'success') {
        const { file } = response;
        let { uri } = response;

        if (Platform.OS === 'android' && uri[0] === '/') {
          uri = `file://${uri}`;
          uri = uri.replace(/%/g, '%25');
        }
        setImagePreview(uri);
        setDoc(file);
      }
    });
  };

  return (
    <Provider>
      <KeyboardAwareScrollView>
        <View style={[styles.container]}>
          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.headerLabel}>New Deposit Application</Text>
            </Col>
          </Row>

          <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={styles.labels}>
                  Account Type
                  {<Text style={{color: 'red'}}>*</Text>}
                </Text>
              </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <SafeAreaView>
                <DropDown
                  mode="outlined"
                  visible={showDropDown}
                  placeholder="Select Account Type"
                  showDropDown={() => setShowDropDown(true)}
                  onDismiss={() => setShowDropDown(false)}
                  value={accounttype}
                  setValue={(value:any) => onChangeAccountType(value)}
                  list={accountType}
                  dropDownStyle={styles.dropdownContainerStyle}
                  dropDownItemSelectedTextStyle={styles.dropdownSelectedStyle}
                />
              </SafeAreaView>
            </Col>
          </Row>

          <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={styles.labels}>
                  Account Number
                  {<Text style={{color: 'red'}}>*</Text>}
                </Text>
              </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <SafeAreaView>
                <DropDown
                  mode="outlined"
                  placeholder="Account Number"
                  visible={showDropDown1}
                  showDropDown={() => setShowDropDown1(true)}
                  onDismiss={() => setShowDropDown1(false)}
                  value={accountNumber}
                  setValue={(value) => onChangeAccountNumber(value)}
                  list={accountsMap[accounttype] || accounts}
                  dropDownStyle={styles.dropdownContainerStyle}
                  dropDownItemSelectedTextStyle={styles.dropdownSelectedStyle}
                />
              </SafeAreaView>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <RequiredTextInput
                label="Amount"
                value={amount}
                onChange={(e: any) => {
                  setAmount(e)
                  setAmountError('')

                }}
                required
                error={amountError}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
                style={[styles.amountView]}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <View>
                <Button
                  onPress={showAccounts}
                  mode="contained"
                  color="rgb(41, 113, 182)"
                  style={styles.buttonSize}
                >
                  Add Extra Account
                </Button>
              </View>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>Accounts selected</Text>

              {choices.map((x, i) => (
                <Text style={styles.labels} key={i}>
                  {(x as any).accountType} # {(x as any).accountId} UGX{(x as any).amount}
                </Text>
              ))}
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>Transaction ID</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <TextInput
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Text style={styles.labels}>Transaction Date</Text>
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <Button
                icon="calendar-range"
                onPress={() => setOpen(true)}
                uppercase={false}
                mode="outlined"
                color="rgb(41, 113, 182)"
              >
                {moment(date).format('DD/MM/YYYY')}
              </Button>

              <DatePickerModal
                locale="en"
                mode="single"
                visible={open}
                onDismiss={onDismissSingle}
                date={date}
                onConfirm={onConfirmSingle}
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <RequiredTextInput
                label="Document Name"
                value={docName}
                onChange={(e: any) => {
                  setDocName(e)
                  setDocNameError('')
                }}
                required
                error={docNameError}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <RequiredTextInput
                label="Document Description"
                value={docDesc}
                onChange={(e: any) => {
                  setDocDesc(e)
                  setDocDescError('')}
                }
                required
                error={docDescError}
                mode="outlined"
                outlineColor="#2679b0"
                activeOutlineColor="#2679b0"
              />
            </Col>
          </Row>

          <Row>
            <Col customStyles={{ flex: 1 }}>
              <View>
                <Button
                  onPress={pickDocument}
                  mode="contained"
                  color="rgb(41, 113, 182)"
                  style={styles.buttonSize}
                >
                  Select Document
                </Button>
                {imagePreview ? (
                  <View style={{ padding: 10 }}>
                    <Image
                      source={{ uri: imagePreview }}
                      style={{ width: 200, height: 200 }}
                    />
                  </View>
                ) : null}
              </View>
            </Col>
          </Row>

          {loader && <Activity />}

          <TouchableHighlight onPress={() => onSubmit()} disabled={loader}>
            <View style={[styles.loanButton, loader && styles.disabledButton]}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Review'}</Text>
            </View>
          </TouchableHighlight>
        </View>
      </KeyboardAwareScrollView>
    </Provider>
  );
}

