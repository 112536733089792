
import { StyleSheet, Platform } from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 10,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgb(30,136,229)',
    padding: 10,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
  },
  disabledButton: {
    backgroundColor: '#a9a9a9',
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: 'grey',
    fontWeight: 'bold',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 80,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
  loan: {
    padding: 5,
    color: 'black',
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 1,
  },
  header: {
    fontSize: 18,
    padding: 10,
  },
  inputs: {
    borderWidth: 1,
    width: 250,
    height: 50,
    padding: 10,
    margin: 10,
    color: '#000',
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    padding: 10,
    elevation: 2,
    margin: 8,
    width: 200,
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  cancel: {
    backgroundColor: 'gray',
  },
  containerMobile: {
    flexDirection: 'column',
    padding: 30,
    height: '100%',
    backgroundColor: 'white',
  },
  buttonSize: {
    height: 55,
    alignItems: 'center',
    justifyContent: 'center',
  },
  amountView: {
    backgroundColor: 'white',
  },
  dropdownContainerStyle: {
    marginTop: -30,
  },
  dropdownSelectedStyle: {
    color: 'black',
  },
  loanButton: {
    alignItems: 'center',
    backgroundColor: '#62c341',
    padding: 10,
    borderRadius: 25,
    marginTop: 40,
    width: '95%',
  },

  headerLabel: {
    fontSize: 20,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  labels: {
    color: '#2679b0',
    fontWeight: 'bold',
  },
});

export default { styles };
