import * as React from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { DataTable } from 'react-native-paper';
import { numberWithCommas } from '../common/common';

const numberOfItemsPerPageList = [2, 3, 4];
const fontColor = 'rgb(41, 113, 182)';

function Table({ titles, data, mode }: any) {
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, data.length);
  React.useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage]);

  return (
    <DataTable>
      <DataTable.Header style={styles.dataTable}>
        {titles.map((x: any, i: any) => (
          <DataTable.Title key={i}>
            <Text style={styles.title}>{x}</Text>
          </DataTable.Title>
        ))}
      </DataTable.Header>

      {data.map((x: any, i: any) => (
        <DataTable.Row key={i}>
          {mode == 'Self Reported Transactions' && (
            <>
              <DataTable.Cell>
                <View style={styles.text}>
                  {x.isAccepted ? (
                    <View style={styles.success}>Approved</View>
                  ) : x.isRejected ? (
                    <View style={styles.danger}>Rejected</View>
                  ) : (
                    <View style={styles.warning}>Pending</View>
                  )}
                </View>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{numberWithCommas(x.amount)}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.transactionDate}</Text>
              </DataTable.Cell>
            </>
          )}
          {mode == 'Recent Transactions' && (
            <>
              <DataTable.Cell>
                <Text style={styles.text}>{x.accountNumber}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.accountType}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{numberWithCommas(x.amount)}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.transactionType}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.transactionDate}</Text>
              </DataTable.Cell>
            </>
          )}
        </DataTable.Row>
      ))}

      <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(data.length / numberOfItemsPerPage)}
        onPageChange={(value: any) => setPage(value)}
        label={`${from + 1}-${to} of ${data.length}`}
        numberOfItemsPerPageList={numberOfItemsPerPageList}
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        selectPageDropdownLabel="Rows per page"
      />
    </DataTable>
  );
}

export default Table;

const styles = StyleSheet.create({
  title: {
    color: fontColor,
    fontSize: 18,
    textAlign: 'center',
  },
  success: {
    fontSize: 13,
    color: '#fff',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderRadius: 10,
    backgroundColor: '#4CAF50',
  },
  danger: {
    fontSize: 13,
    color: '#fff',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderRadius: 10,
    backgroundColor: '#f44336',
  },
  warning: {
    fontSize: 13,
    color: '#fff',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderRadius: 10,
    backgroundColor: '#e6e600',
  },
  text: {
    fontSize: 13,
    padding: 10,
    textAlign: 'center',
    fontFamily: 'MontserratRegular',
  },
  dataTable: {
    alignItems: 'center',
    backgroundColor: '#f2f7fb',
  },
});
