import React, { useState, useRef } from 'react';

import { StyleSheet, SafeAreaView } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';

import Row, { Col } from './Row';

export default function DropDownSelector({ placeholder, list }: any) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [gender, setGender] = useState('');

  return (
    <Row>
      <Col customStyles={{ flex: 1 }}>
        <SafeAreaView>
          <DropDown
            placeholder={placeholder}
            mode="outlined"
            visible={showDropDown}
            showDropDown={() => setShowDropDown(true)}
            onDismiss={() => setShowDropDown(false)}
            value={gender}
            setValue={setGender}
            list={list}
            dropDownStyle={styles.dropdownContainerStyle}
          />
        </SafeAreaView>
      </Col>
    </Row>
  );
}

const styles = StyleSheet.create({
  dropdownContainerStyle: {
    marginTop: -30,
  },
});
