/* eslint-disable react/prop-types */
import { StyleSheet, Text, View } from 'react-native';
import { Card } from 'react-native-paper';
import React, { useState, useEffect } from 'react';

import { AccountService } from '../../../components/common/account.service';
import { numberWithCommas } from '../../../components/common/common';

function LoanCharges({ route }: any) {
  const accountService = new (AccountService as any)();
  const { accountNo } = route.params;

  const [data, setData] = useState([]);

  const getData = async () => {
    await accountService.getLoanDetails(accountNo).then(async (res: any) => {
      const response = res.data;
      setData(response.summary);
    });
  };

  // console.log('data',data);

  useEffect(() => {
    getData();
  }, []);

  return (
    <View>
      <Card>
        <View style={styles.card}>
          <Text style={styles.headerText}>Loan Adminstration</Text>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            <Text style={styles.text}>Due:</Text>

            <Text>{numberWithCommas((data as any).feeChargesCharged | 0)}</Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            <Text style={styles.text}>Paid</Text>

            <Text>{numberWithCommas((data as any).feeChargesPaid | 0)}</Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            <Text style={styles.text}>Waived</Text>

            <Text> {numberWithCommas((data as any).interestWaived | 0)}</Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            <Text style={styles.text}>Outstanding</Text>

            <Text>{numberWithCommas((data as any).feeChargesOutstanding | 0)}</Text>
          </View>
        </View>
      </Card>
    </View>
  );
}

export default LoanCharges;

const styles = StyleSheet.create({
  card: {
    margin: 10,
    padding: 10,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'normal',
    paddingBottom: 10,
  },
  text: {
    fontWeight: 'bold',
  },
});
