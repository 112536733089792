/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  Image,
  ImageBackground,
  Dimensions,
  StyleSheet,
  ActivityIndicator,
  Alert,
} from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import LoginCtrl from './login.component';
import { AccountService } from '../../components/common/account.service';
import { windowLocationSearch, windowLocationReplace } from '../../components/common/accountHelper.js';

import ForgotPassword from '../forgotPassword/ForgotPassword';

import Row, { Col } from '../../components/Row';
import { styles } from './loginStyles';
import { isPhone } from '../../components/common/devices';
import TrackScreenHit from "../../utils/analytics"

const ScreenHeight = Dimensions.get('window').height;
const fontColor = 'rgb(30,136,229)';

export default function LoginWeb({ navigation }: any) {
  const [username, setUsername] = useState('');
  const [fsp, setFsp] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [alertMsg, setAlertMsg] = useState('')
  const [loader, setLoader] = useState(false);
  const urlParams = new URLSearchParams(windowLocationSearch());
  const token = urlParams.get('token');
  const tenantIdentifier = urlParams.get('tenantIdentifier');

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  useEffect(() => {
    if (tenantIdentifier) setFsp(tenantIdentifier);
  }, [tenantIdentifier]);

  TrackScreenHit("Login")

  const loginCtrl = new (LoginCtrl as any)();
  const accountService = new (AccountService as any)();

  const validatePassword = (password: string) => {
    const passwordRequirements = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).{8,}$/;
    return passwordRequirements.test(password);
  };

  const onSubmit = async () => {
    try {
      setLoader(true);
      const userData = { username, fsp, password };
      await loginCtrl.doLogin(userData, alertMsg, setLoader, setAlertMsg);
    } catch (error) {
      console.error('An unexpected error occurred during login:', error);
    } finally {
      setLoader(false);
    }
  };


  const onReset = async () => {
    if (!validatePassword(password)) {
      setAlertMsg('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.');
      return;
    }
  
    if (password !== repeatPassword) {
      setAlertMsg('Passwords do not match.');
      return;
    }
  
    setLoader(true);
    const userData = { password, repeatPassword };
    
    accountService.ResetPwd(token, fsp, userData)
    .then((data: any) => {
      if (data?.data?.expired !== undefined) {
        setAlertMsg('It looks like your password reset link has expired or has already been used. Please request a new password reset link by clicking "Forgot Password" below.');
        setLoader(false);
        return;
      }
      setAlertMsg('Your password has been successfully reset. Please login with your new password');
      setTimeout(() => { windowLocationReplace('/?tenantIdentifier=' + fsp)}, 2500)
    })
    .catch((err: any) => {
      setAlertMsg('Sorry, an error occured while resetting your password. Please retry.');
      setLoader(false);
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <View style={styles.web}>
      <View
        style={{
          flexDirection: 'row',
          height: ScreenHeight,
        }}
      >
        {isPhone() && (
          <Col
            customStyles={{ flex: 2, shadowColor: '#000000', shadowRadius: 10, shadowOpacity: 0.4 }}
          >
            <ImageBackground
              source={require('../../assets/cover.jpg')}
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <View style={loginStyles.welcomeNote}>
                <View>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={loginStyles.kbTitle}>Kanzu Banking Self Service</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={loginStyles.kbHeadline}>
                        Welcome to your financial institution&rsquo;s
                      </Text>

                      <Text style={loginStyles.kbHeadline}>online Banking platform</Text>
                    </Col>
                  </Row>
                </View>
              </View>
            </ImageBackground>
          </Col>
        )}

        <Col customStyles={{ flex: 1 }}>
          <View style={{ marginTop: 50 }}>
            <Row>
              <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                <Image
                  source={require('../../assets/kanzubanking_logo.png')}
                  style={loginStyles.mifosLogo}
                />
              </Col>
            </Row>

            <Row>
              <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                <Text style={loginStyles.loginText}>Login to your Account</Text>
              </Col>
            </Row>

            { !loader && alertMsg ? 
              <Row>
                <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                  <Text style={loginStyles.alertText}>{ alertMsg }</Text>
                </Col>
              </Row>
            : null }

            {loader && (
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <ActivityIndicator size="large" color="blue" />
              </View>
            )}

            <View style={{ margin: 50, marginBottom: 5, marginTop: 15 }}>
              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <TextInput
                    placeholder="Financial Services Provider"
                    label="Financial Services Provider"
                    style={[loginStyles.inputs]}
                    onChangeText={(e: any) => setFsp(e)}
                    value={fsp != ''? fsp : ''}
                  />
                </Col>
              </Row>

              { token ? 
                <>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Password"
                        style={loginStyles.inputs}
                        label="Password"
                        secureTextEntry={!showPassword}
                        onChangeText={(e: any) => setPassword(e)}
                        right={
                          <TextInput.Icon
                            name={showPassword ? 'eye-off' : 'eye'}
                            color={fontColor}
                            onPress={togglePasswordVisibility}
                          />
                        }
                      />
                      <Text style={loginStyles.passwordGuidelines}>
                        Password must be at least 8 characters,<br />
                        Contain one uppercase letter [A-Z],<br />
                        One lowercase letter [a-z],<br />
                        One number, and <br />
                        One special character [!, @, ?].
                      </Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Repeat Password"
                        label="Repeat Password"
                        style={loginStyles.inputs}
                        secureTextEntry={!showRepeatPassword}
                        onChangeText={(e: any) => setRepeatPassword(e)}
                        right={
                          <TextInput.Icon
                            name={showRepeatPassword ? 'eye-off' : 'eye'}
                            color={fontColor}
                            onPress={toggleRepeatPasswordVisibility}
                          />
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <View>
                        <Button
                          mode="contained"
                          uppercase={false}
                          color={fontColor}
                          onPress={() => onReset()}
                        >
                          Reset
                        </Button>
                      </View>
                    </Col>
                  </Row>
                </>
                :
                <>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Username"
                        style={loginStyles.inputs}
                        label="Username"
                        onChangeText={(e: any) => setUsername(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <TextInput
                        placeholder="Password"
                        label="Password"
                        style={loginStyles.inputs}
                        secureTextEntry={!showPassword}
                        onChangeText={(e: any) => setPassword(e)}
                        right={
                          <TextInput.Icon
                            name={showPassword ? 'eye-off' : 'eye'}
                            color={fontColor}
                            onPress={togglePasswordVisibility}
                          />
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <View>
                        <Button
                          mode="contained"
                          uppercase={false}
                          color={fontColor}
                          onPress={() => onSubmit()}
                        >
                          Login
                        </Button>
                      </View>
                    </Col>
                  </Row>
                </>  
              }

              

              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <View>
                    <Button
                      mode="text"
                      uppercase={false}
                      color={fontColor}
                      onPress={() => navigation.navigate('Register')}
                    >
                      New User? Create An Account
                    </Button>
                    {/* <Button onPress={() => navigation.navigate('ResetPassword')}>Reset</Button> */}
                  </View>
                </Col>
              </Row>

              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <View>
                    <Button
                      mode="text"
                      uppercase={false}
                      color={fontColor}
                      onPress={() => navigation.navigate('Forgot password')}
                    >
                      Forgot Password
                    </Button>
                  </View>
                </Col>
              </Row>
            </View>
          </View>
        </Col>
      </View>
    </View>
  );
}

const loginStyles = StyleSheet.create({
  inputs: {
    backgroundColor: 'transparent',
  },
  mifosLogo: {
    height: 150,
    width: 150,
  },
  welcomeNote: {
    flexDirection: 'row',
    width: '60%',
    margin: 200,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 30,
  },
  kbTitle: {
    color: 'white',
    fontSize: 40,
    paddingBottom: 30,
    borderBottomColor: 'white',
    borderBottomWidth: 2,
    fontWeight: '400',
  },
  kbHeadline: {
    color: 'white',
    fontSize: 20,
    fontWeight: '300',
  },
  loginText: {
    fontSize: 19,
  },
  alertText: {
    fontSize: 15,
    textAlign: 'center',
    color: 'red'
  },
  textInput: {
    margin: 12,
  },
  passwordGuidelines: {
    color: 'red',
    fontSize: 13,
    marginTop: 5,
  },
});
