import { StyleSheet, Text, View, Platform } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Button,  DataTable } from 'react-native-paper';

import { AccountService } from '../../components/common/account.service';
import moment from 'moment';
import { numberWithCommas } from '../../components/common/common';
import Row, { Col } from '../../components/Row';

function ShareAccountDetMob({ route }: any) {
  const accountService = new (AccountService as any)();
  const { accountId } = route.params;

  const [mode, setMode] = useState('transaction overview');
  const [savingAccount, setSavingAccount] = useState([]);
  const [currency, setCurrency] = useState('');
  const [purchase, setPurchase] = useState([]);
  const [dividend, setDividend] = useState([]);
  

  async function ShareAccounts() {
    await accountService.getShareAccount(accountId).then((res: any) => {
      const response = res;
      setSavingAccount(response.data);
      setCurrency(response.data.currency.name);
      setPurchase(response.data.purchasedShares);
      setDividend(response.data.dividends);
    });
  }

  useEffect(() => {
    ShareAccounts();
  }, []);

  return (
    <View style={styles.mobile}>
      <View>
        <DataTable.Row>
          <DataTable.Cell>
            <Text style={styles.header}>Account Number</Text>
          </DataTable.Cell>
          <DataTable.Cell>
            <Text style={styles.text}>{(savingAccount as any).accountNo}</Text>
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row>
          <DataTable.Cell>
            <Text style={styles.header}>Currency</Text>
          </DataTable.Cell>
          <DataTable.Cell>
            <Text style={styles.text}>{currency}</Text>
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row>
          <DataTable.Cell>
            <Text style={styles.header}>Savings Account</Text>
          </DataTable.Cell>
          <DataTable.Cell>
            <Text style={styles.text}>
              {(savingAccount as any).savingsAccountNumber}
            </Text>
          </DataTable.Cell>
        </DataTable.Row>
        <View style={{padding:8}}>
        <Row>
          <Col
            customStyles={{
              flex: 1,
            }}
          >
            <Button
              onPress={() => setMode('transaction overview')}
              uppercase={false}
              mode="contained"
              color={mode === 'transaction overview' ? 'rgb(30,136,229)' : '#f2f7fb'}
            >
              Transaction
            </Button>
          </Col>

          <Col
            customStyles={{
              flex: 1,
            }}
          >
            <Button
              onPress={() => setMode('dividend')}
              mode="contained"
              uppercase={false}
              color={mode === 'dividend' ? 'rgb(30,136,229)' : '#f2f7fb'}
            >
              Dividend
            </Button>
          </Col>
        </Row>
        </View>
      </View>
      {mode === 'transaction overview' ? (
        (purchase as any).map((x: any) => (
          <>
            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.header}>Transaction Date</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{moment(x.purchasedDate).format('DD/MM/YYYY')}</Text>
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.header}>Transaction Type</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>
                  {x.type.value}({x.status.value})
                </Text>
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.header}>Total Shares</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.numberOfShares}</Text>
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.header}>Redeemed Price</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{numberWithCommas(x.purchasedPrice)}</Text>
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.header}>Charge Amount</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{numberWithCommas(x.chargeAmount)}</Text>
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.header}>Amount Paid</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                
                <Text style={styles.text}>{numberWithCommas(x.amountPaid)}</Text>
              </DataTable.Cell>
            </DataTable.Row>
          </>
        ))
      ) : (
        <View>
          {dividend.map((item: any) => (
            <>
              <DataTable.Row>
                <DataTable.Cell>
                  <Text style={styles.header}>Transaction Date</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{moment(item.postedDate).format('DD/MM/YYYY')}</Text>
                </DataTable.Cell>
              </DataTable.Row>

              <DataTable.Row>
                <DataTable.Cell>
                  <Text style={styles.header}>Amount</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{numberWithCommas(item.amount)}</Text>
                </DataTable.Cell>
              </DataTable.Row>

              <DataTable.Row>
                <DataTable.Cell>
                  <Text style={styles.header}>Reference</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{item.savingsTransactionId}</Text>
                </DataTable.Cell>
              </DataTable.Row>

              <DataTable.Row>
                <DataTable.Cell>
                  <Text style={styles.header}>Status</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{item.status.value}</Text>
                </DataTable.Cell>
              </DataTable.Row>
            </>
          ))}
        </View>
      )}
    </View>
  );
}

export default ShareAccountDetMob;

const styles = StyleSheet.create({
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
  header:{
    fontSize: 18,
    fontWeight: '500',
  },
  text:{
    fontSize: 14,
    paddingTop: 5,
    color: 'gray',
  }
});
