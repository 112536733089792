import moment from 'moment';
import { AccountService } from '../../components/common/account.service';
import { store } from '../../redux/index';

/**
 * @module LoanApplicationCtrl
 * @description
 * Controls Application for Loan
 */
function LoanApplicationCtrl(this: any) {
  const vm = this;

  const accountService = new (AccountService as any)();

  vm.form = {
    locale: 'en_GB',
    dateFormat: 'dd MMMM yyyy',
    loanType: 'individual',
  };
  vm.template = {};
  vm.clientId = null;

  vm.init = init;
  vm.getLoanTemplate = getLoanTemplate;
  //   vm.submit = submit;
  vm.loanDocument = null;
  vm.loanDocumentName = null;
  vm.loanDocumentDescription = null;

  init();

  function init() {
    accountService.getClientId().then(function (clientId: any) {
      vm.clientId = clientId;
      const productId = 1;
      getLoanTemplate(clientId, productId);
    });
  }

  function getLoanTemplate(clientId: any, productId: any) {
    const individualTemplate = {
      templateType: 'individual',
      clientId,
      productId,
    };

    accountService
      .template(individualTemplate)
      .then((res: any) => {
        const date = moment(new Date()).format('DD MMMM YYYY');

        vm.template = res.data;
        vm.form.principal = vm.template.principal;
        vm.form.submittedOnDate = date;
        vm.form.expectedDisbursementDate = date;

        store.dispatch({
          type: 'SET_PRODUCT_LIST',
          productList: vm.template.productOptions,
        });
      })
      .catch((error: any) => {
        const text = 'Loan Application template Not Found ';
        console.log(text);
      });
  }
}

export default LoanApplicationCtrl;
