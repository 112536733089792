import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import Dashboard from '../dashboard/dashboard';
import Header from '../../components/header/header';
import DrawerContent from '../../components/drawer/Drawer';
import Deposit from '../SubmitDeposit/DepositMobile';
import TransferDeposit from '../SubmitDeposit/DepositWeb';
import SelfDeposit from '../self-reporting/SelfDeposit';
import Accounts from '../accounts/Accounts';
import Loan from '../Loans/Loan';
import Share from '../Shares/Share';
import Help from '../help/Help';
import AboutUs from '../about-us/AboutUs';
import Profile from '../Profiles/Profile';
import SelfRegister from '../SelfRegister/SelfRegister';
import ValidateRegister from '../validateRequest/Validate';

import SharesAccountDetail from '../account-details/ShareAccountDetails'
import ShareAccountDetailMobile from '../account-details/ShareAccountDetMob'

import LoadDetail from '../account-details/loanDetailMobile/LoanDetail';
import ShareDetail from '../account-details/shareDetailMobile/ShareDetail';
import LoanCharges from '../account-details/loanMonitors/LoanCharges';
import LoanSummary from '../account-details/loanMonitors/LoanSummary';
import LoanRepayment from '../account-details/loanMonitors/repaymentSchedule';
import LoanTransaction from '../account-details/loanMonitors/Transactions';
import LoanAccountDetail from '../account-details/LoanAccountDetailsWeb';
import SavingsAccountDetail from '../account-details/SavingsAccountDetail';
import SavingDetail from '../account-details/savingDetailMobile/SavingDetail';
import SavingCharges from '../account-details/savingDetailMobile/savingMonitor/SavingCharges';
import SavingTransactions from '../account-details/savingDetailMobile/savingMonitor/Transaction';
import Login from '../login/login';
import RecentTransaction from '../recent-transactions/RecentTransaction';
import { isPhone } from '../../components/common/devices';
// import { getIsSignedIn } from '../../components/common/account.service';
import Withdraw from '../request-withdraw/Request-withdraw';
import Reset from '../reset-pasword/Reset';
import ForgotPswd from '../forgotPassword/ForgotPswd';
import Payment from '../MakePayment/Payment';

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();

// const isSignedIn = false;

// async function checkFirstEntry() {
//   isSignedIn = await getIsSignedIn();
// }
// checkFirstEntry();

function AuthStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: !isPhone(),

        header: (props: any) => <Header {...props} />,
      }}
    >
      <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />

      <Stack.Screen name="Register" component={SelfRegister} />
      <Stack.Screen name="ValidateRegister" component={ValidateRegister} />
      <Stack.Screen name="ResetPassword" component={Reset} />
      <Stack.Screen name="Forgot password" component={ForgotPswd}/>
    </Stack.Navigator>
  );
}

function HomeStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: !isPhone(),

        header: (props: any) => <Header {...props} />,
      }}
    >
      <Stack.Screen name="DashBoard" component={Dashboard} />

      <Stack.Screen name="Make Deposit" component={Deposit} />

      <Stack.Screen name="Transfer Deposit" component={TransferDeposit} />

      <Stack.Screen name="Payment" component={Payment} />

      <Stack.Screen name="Deposit" component={SelfDeposit} />

      <Stack.Screen name="Accounts" component={Accounts} />

      <Stack.Screen name="Loan" component={Loan} />

      <Stack.Screen name="Share" component={Share} />

      <Stack.Screen name="Help" component={Help} />

      <Stack.Screen name="Recent Transactions" component={RecentTransaction} />

      <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="About Us" component={AboutUs} />

      <Stack.Screen name="Loan Account Detail" component={LoanAccountDetail} />

      <Stack.Screen name="Savings Account Detail" component={SavingsAccountDetail} />

      <Stack.Screen name="Saving Detail" component={SavingDetail} />

      <Stack.Screen name="Charges" component={SavingCharges} />

      <Stack.Screen name="Shares" component={ShareAccountDetailMobile} />

      <Stack.Screen name="Shares Account Detail" component={SharesAccountDetail} />

      <Stack.Screen name="Saving Transactions" component={SavingTransactions} />

      <Stack.Screen name="Loan Detail" component={LoadDetail} />

      <Stack.Screen name="Share Detail" component={ShareDetail} />

      <Stack.Screen name="Loan Charges" component={LoanCharges} />

      <Stack.Screen name="Loan Summary" component={LoanSummary} />

      <Stack.Screen name="Loan Repayment" component={LoanRepayment} />

      <Stack.Screen name="Loan Transaction" component={LoanTransaction} />
      <Stack.Screen name="Withdraw Request" component={Withdraw} />
    </Stack.Navigator>
  );
}

function MainStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: !isPhone(),

        header: (props: any) => <Header {...props} />,
      }}
      initialRouteName="Auth"
    >
      <Stack.Screen name="Auth" component={AuthStack} options={{ headerShown: false }} />

      <Stack.Screen name="Home" component={HomeStack} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}
function DrawerNavigator() {
  return (
    <NavigationContainer>
      <Drawer.Navigator
        screenOptions={{
          headerShown: false,
        }}
        drawerContent={(props: any) => <DrawerContent {...props} />}
      >
        <Drawer.Screen name="Drawer" component={MainStack} />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default DrawerNavigator;
