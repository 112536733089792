import { StyleSheet, Text, View, ScrollView } from 'react-native';
import React, { useState, useEffect } from 'react';

import AccountCtrl from '../../../accounts/account-list.component';

function SavingCharges({ route }: any) {
  const { accountNo } = route.params;

  const accountCtrl = new (AccountCtrl as any)();

  const [account, setAccount] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  async function getData() {
    setTimeout(function () {
      setAccount(accountCtrl.savingsAccounts);
      setIsLoaded(true);
    }, 5000);
  }

  // console.log('Saving Account',account);

  useEffect(() => {
    getData();
  }, []);
  return (
    <ScrollView>
      <View>
        <Text style={styles.charge}>No Transaction Charges Found</Text>
      </View>
    </ScrollView>
  );
}

export default SavingCharges;

const styles = StyleSheet.create({
  charge: {
    fontSize: 16,
    margin: 20,
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
  },
});
