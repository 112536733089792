import React from 'react';
import SelfRegisterWeb from './SelfRegisterWeb';
import SelfRegisterMobile from './SelfRegisterMobile';

export default function SelfRegister({ navigation }: any) {
  return (
    <>
      <SelfRegisterWeb navigation={navigation} />

      <SelfRegisterMobile navigation={navigation} />
    </>
  );
}
