/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { DataTable, Button, Badge } from 'react-native-paper';
import { numberWithCommas } from '../../components/common/common';
import { StyleSheet, Text, View } from 'react-native';

const fontColor = 'rgb(41, 113, 182)';
const numberOfItemsPerPageList = [2, 3, 4];

function Table({ titles, data, mode, navigation }: any) {
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, data.length);
  React.useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage]);

  return (
    <DataTable>
      <DataTable.Header style={{ backgroundColor: '#f2f7fb' }}>
        {titles.map((x: any, i: any) => (
          <DataTable.Title key={i}>
            <Text style={styles.title}>{x}</Text>
          </DataTable.Title>
        ))}
      </DataTable.Header>

      {mode === 'Loans' ? (
        data.length > 0 ? (
          data.map((x: any, i: any) => (
            <DataTable.Row
              key={i}
              onPress={() => {
                x.status.value === 'Active'
                  ? navigation.navigate('Loan Account Detail', { loanId: x.id, name: 'jane' })
                  : null;
              }}
            >
              <DataTable.Cell>
                <View style={styles.text}>
                  {x.status.value === 'Active'|| x.status.value === 'Approved' ? (
                    <View style={styles.success}>{x.status.value}</View>
                  ) : x.status.value === 'Rejected' ? (
                    <View style={styles.danger}>{x.status.value}</View>
                  ) : x.status.value === 'Submitted and pending approval' ? (
                    <View style={styles.warning}>Pending</View>
                  ) : (
                    x.status.value
                  )}
                </View>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.accountNo}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.productName}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{numberWithCommas(x.originalLoan || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{numberWithCommas(x.loanBalance || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{numberWithCommas(x.amountPaid || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell>
                <Text style={styles.text}>{x.loanType.value}</Text>
              </DataTable.Cell>
            </DataTable.Row>
          ))
        ) : (
          <>
            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.text}>You currently have no Loan accounts</Text>
              </DataTable.Cell>
            </DataTable.Row>
          </>
        )
      ) : mode === 'Share' ? (
        data.length > 0 ? (
          data.map((x: any, i: any) => {
            const myDate = x.timeline.submittedOnDate;
            const submittedYear = myDate.slice(0, 1);
            const submittedMonth = myDate.slice(1, 2);
            const submittedDay = myDate.slice(2, 4);
            const fullSubmittedDate = submittedYear + '-' + submittedMonth + '-' + submittedDay;
            return (
              <DataTable.Row
                key={i}
                onPress={() => {
                  x.status.value === 'Active' || x.status.value === 'Approved'
                    ? navigation.navigate('Shares Account Detail', { accountNo: x.id })
                    : null;
                }}
              >
                <DataTable.Cell>
                  <View style={styles.text}>
                    {x.status.value === 'Active' || x.status.value === 'Approved' ? (
                      <View style={styles.success}>{x.status.value}</View>
                    ) : x.status.value === 'Rejected' ? (
                      <View style={styles.danger}>{x.status.value}</View>
                    ) : x.status.value === 'Submitted and pending approval' ? (
                      <View style={styles.warning}>Pending</View>
                    ) : (
                      x.status.value
                    )}
                  </View>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{x.accountNo}</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{x.productName}</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{fullSubmittedDate}</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{x.totalApprovedShares}</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{x.unitPrice}</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                  <Text style={styles.text}>{x.unitPrice * x.totalApprovedShares}</Text>
                </DataTable.Cell>
              </DataTable.Row>
            );
          })
        ) : (
          <>
            <DataTable.Row>
              <DataTable.Cell>
                <Text style={styles.text}>You currently have no Share accounts</Text>
              </DataTable.Cell>
            </DataTable.Row>
          </>
        )
      ) : data.length > 0 ? (
        data.map((x: any, i: any) => (
          <DataTable.Row
            key={i}
            onPress={() => {
              x.status.value === 'Active'
                ? navigation.navigate('Savings Account Detail', { accountNo: x.id })
                : null;
            }}
          >
            <DataTable.Cell>
              <View style={styles.text}>
                {x.status.value === 'Active' ? (
                  <View style={styles.success}>{x.status.value}</View>
                ) : x.status.value === 'Rejected' ? (
                  <View style={styles.danger}>{x.status.value}</View>
                ) : x.status.value === 'Submitted and pending approval' ? (
                  <View style={styles.warning}>Pending</View>
                ) : (
                  x.status.value
                )}
              </View>
            </DataTable.Cell>
            <DataTable.Cell>
              <Text style={styles.text}>{x.accountNo}</Text>
            </DataTable.Cell>
            <DataTable.Cell>
              <Text style={styles.text}>{x.productName}</Text>
            </DataTable.Cell>
            <DataTable.Cell>
              <Text style={styles.text}>{x.accountType.value}</Text>
            </DataTable.Cell>
            <DataTable.Cell>
              <Text style={styles.text}>{numberWithCommas(x.accountBalance || 0)}</Text>
            </DataTable.Cell>
          </DataTable.Row>
        ))
      ) : (
        <>
          <DataTable.Row>
            <DataTable.Cell>
              <Text style={styles.text}>You currently have no Savings accounts</Text>
            </DataTable.Cell>
          </DataTable.Row>
        </>
      )}

      <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(data.length / numberOfItemsPerPage)}
        onPageChange={(value: any) => setPage(value)}
        label={`${from + 1}-${to} of ${data.length}`}
        showFastPaginationControls
        numberOfItemsPerPageList={numberOfItemsPerPageList}
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        selectPageDropdownLabel="Rows per page"
      />
    </DataTable>
  );
}

export default Table;

const styles = StyleSheet.create({
  title: {
    color: fontColor,
    fontSize: 14,
  },
  success: {
    fontSize: 13,
    color: '#fff',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderRadius: 10,
    backgroundColor: '#4CAF50',
  },
  danger: {
    fontSize: 13,
    color: '#fff',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderRadius: 10,
    backgroundColor: '#f44336',
  },
  warning: {
    fontSize: 13,
    color: '#fff',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderRadius: 10,
    backgroundColor: '#e6e600',
  },
  text: {
    fontSize: 13,
    padding: 10,
    textAlign: 'center',
    fontFamily: 'MontserratRegular',
  },
});
