import React, { useState } from 'react';

import { Modal, StyleSheet, Text, Pressable, View, Image } from 'react-native';
import { TextInput } from 'react-native-paper';

import { AccountService } from '../../components/common/account.service';

import Row, { Col } from '../../components/Row';

export default function ChangePasswordModal() {
  const accountService = new (AccountService as any)();

  const [modalVisible, setModalVisible] = useState(false);
  const [password, setPassword] = useState({
    isValidPassword: true,
  });
  const [repeatPassword, setConfirmPassword] = useState({
    repeatPassword: true,
  });

  // const handleValidPasswords = (val) =>{
  //   if(val.trim().length >= 6){
  //     setPassword({
  //       ...password,
  //       isValidPassword: true,
  //     })
  //   }else{
  //     setPassword({
  //       ...password,
  //       isValidPassword: false,
  //     })
  //   }
  // }

  // const handleRepeatPassword =(val,val2)=>{
  //   if(val !== val2){
  //     setConfirmPassword( {
  //      ...repeatPassword,
  //      repeatPassword:true})
  //   }else{
  //     setConfirmPassword( {
  //       ...repeatPassword,
  //       repeatPassword:false})
  //   }
  // }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const data = { password, repeatPassword, "roles":2 };

    accountService.changePassword(data).then(() => {
      setModalVisible(!modalVisible);
    });
  };

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Row>
              <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                <Image source={require('../../assets/kanzubanking_logo.png')} style={styles.headerLogo} />

                <Text style={styles.text}>Change Password</Text>
              </Col>
            </Row>

            <TextInput
              placeholder="Enter New password"
              label="Password"
              style={styles.input}
              secureTextEntry
              onChangeText={(e: any) => setPassword(e)}
              // onEndEditing={(val) => handleValidPasswords(val.nativeEvent.text)}
            />
            {password.isValidPassword ? null : (
              <Text style={styles.errorMsg}>Password must at least 6 characters</Text>
            )}

            <TextInput
              placeholder="Comfirm  password"
              style={styles.input}
              label="Confirm Password"
              secureTextEntry
              onChangeText={(e: any) => setConfirmPassword(e)}
              // onEndEditing={(val2) => handleRepeatPassword(val2.nativeEvent.text)}
            />
            {password.isValidPassword ? null : (
              <Text style={styles.errorMsg}>Password did not match</Text>
            )}

            <View style={styles.btnStyle}>
              <Pressable style={[styles.button, styles.buttonClose]} onPress={onSubmit}>
                <Text style={styles.textStyle}>Change password</Text>
              </Pressable>

              <Pressable
                style={[styles.button, { backgroundColor: '#FF6347', padding: 10, margin: 5 }]}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <Text style={[{ color: 'white', fontWeight: 'bold', textAlign: 'center' }]}>
                  Cancel
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      <Pressable style={[styles.button, styles.buttonOpen]} onPress={() => setModalVisible(true)}>
        <Text style={styles.textStyle}>Change Password</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    padding: 10,
    alignItems: 'center',
    shadowColor: '#000',
    width: '28%',
    shadowOffset: {
      width: 4,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  button: {
    borderRadius: 4,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#2196F3',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
    padding: 10,
    margin: 5,
  },
  btnStyle: {
    flex: 2,
    flexDirection: 'column',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  headerLogo: {
    height: 100,
    width: 100,
  },
  text: {
    marginTop: 15,
    fontSize: 22,
  },
  input: {
    margin: 12,
  },
  errorMsg: {
    color: '#FF6347',
  },
});
