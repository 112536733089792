import React, { useState } from 'react';

import { View, StyleSheet, Platform } from 'react-native';
import { DrawerItem, DrawerContentScrollView } from '@react-navigation/drawer';
import { Avatar, Title, Drawer } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { AccountService } from '../common/account.service';
import ProfileCtrl from '../../screens/Profiles/Profile.component';

export default function DrawerContent(props: any) {
  const accountService = new (AccountService as any)();
  const profileCtrl = new (ProfileCtrl as any)();

  const [displayName, setDisplayName] = useState('');
  const profileImage = useSelector((state) => (state as any).image);

  const { navigation } = props;

  const getDisplayName = async () => {
    const user = await accountService.getUser();
    if (user) {
      setDisplayName(user.displayName);
      profileCtrl.getImage();
    }
  };

  getDisplayName();

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.drawerContent}>
        <View style={styles.userInfoSection}>
          <Avatar.Image
            source={{
              uri: profileImage,
            }}
            size={50}
          />

          <Title style={styles.title}>{displayName}</Title>
        </View>

        <Drawer.Section style={styles.drawerSection}>
          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="home" color={color} size={size} />
            )}
            label="Home"
            onPress={() => {}}
          />

          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="wallet" color={color} size={size} />
            )}
            label="Accounts"
            onPress={() => navigation.navigate('Accounts')}
          />

          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="tag" color={color} size={size} />
            )}
            label="Recent Transactions"
            // labelStyle={Platform.OS === 'ios' ? null : { fontSize: wp('3') }}
            onPress={() => {
              navigation.navigate('Recent Transactions');
            }}
          />
          {/* <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="cash-plus" color={color} size={size} />
            )}
            label="Charges"
            onPress={() => {}}
          /> */}
        </Drawer.Section>

        <Drawer.Section>
          {/* <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="cog" color={color} size={size} />
            )}
            label="Settings"
            onPress={() => {}}
          /> */}

          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="information" color={color} size={size} />
            )}
            label="About Us"
            onPress={() => {
              navigation.navigate('About Us');
            }}
          />

          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="help-circle" color={color} size={size} />
            )}
            label="Help"
            onPress={() => {
              navigation.navigate('Help');
            }}
          />
          {/* <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="export-variant" color={color} size={size} />
            )}
            label="Share"
            onPress={() => {}}
          /> */}

          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="account-star-outline" color={color} size={size} />
            )}
            label="Profile"
            onPress={() => {
              navigation.navigate('Profile');
            }}
          />

          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="login" color={color} size={size} />
            )}
            label="Logout"
            onPress={() => {
              navigation.navigate('Auth');
            }}
          />
        </Drawer.Section>
      </View>
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
    marginTop: 40,
  },
  userInfoSection: {
    paddingLeft: 20,
  },
  title: {
    marginTop: 20,
    fontWeight: 'bold',
  },
  caption: {
    fontSize: 14,
    lineHeight: 14,
  },
  row: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  paragraph: {
    fontWeight: 'bold',
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 15,
  },
  preference: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});
