import { StyleSheet, Text, View, ImageBackground, Dimensions, Image,ActivityIndicator, } from 'react-native';
import { TextInput, Button, Card } from 'react-native-paper';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useToast } from 'react-native-paper-toast';

import { Constants } from '../../Constants/Constants';

import Row, { Col } from '../../components/Row';

const { BASE_URL } = Constants;

const axios = require('axios');

const ScreenHeight = Dimensions.get('window').height;

function ResetPassword() {
  const toaster = useToast();
  const history = createNativeStackNavigator();

  const [token, setToken] = useState(null);

  const [loader, setLoader] = useState(false);
  const [fsp, setFsp] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  useEffect(() => {
    //const { getToken } = queryString.parse(location.search);
    //setToken(getToken);
    // remove token from url to prevent http referer leakage
    //   history.replace(Location.pathname)
  }, [history]);

  const submit = async () => {
    setLoader(true)
    const data = {
      password,
      repeatPassword,
    };
    const apiHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': fsp,
      },
    };

    await axios
      .post(`${BASE_URL}reset-password?token=${token}`, data, apiHeaders)
      .then((response: any) => {
        setLoader(false)
        if (response.data.invalid === 'Invalid token') {
          toaster.show({
            message: 'Reset Password link is invalid',
            duration: 5000,
            type: 'error',
            position: 'top',
          });
        } else if (response.data.expired === "Token is expired. You can't use this token") {
          toaster.show({
            message: 'Reset Password link has expired',
            duration: 5000,
            type: 'error',
            position: 'top',
          });
        } else {
          
          toaster.show({
            message: 'Password has been reset',
            duration: 5000,
            type: 'success',
            position: 'top',
          });
        }
      })

      .catch(() => {
        setLoader(false);
        toaster.show({
          message: '',
          duration: 5000,
          type: 'error',
          position: 'top',
        });
      });
  };

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          height: ScreenHeight,
        }}
      >
        <Col
          customStyles={{ flex: 2, shadowColor: '#000000', shadowRadius: 10, shadowOpacity: 0.1 }}
        >
          <ImageBackground
            source={require('../../assets/cover.jpg')}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <View style={styles.view}>
              <Card style={styles.card}>
                <Row>
                  <Col customStyles={{ flex: 1, alignItems: 'center' }}>
                    <Image source={require('../../assets/kanzubanking_logo.png')} style={styles.logo} />
                    <Text style={styles.header}>Reset your Password</Text>
                  </Col>
                </Row>

                {loader && (
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <ActivityIndicator size="large" color="blue" />
                </View>
              )}
                <TextInput
                  placeholder="Financial Services Provider"
                  label="Financial Services Provider"
                  style={styles.textInput}
                  onChangeText={(e) => setFsp(e)}
                />

                <TextInput
                  placeholder="Password"
                  label="Password"
                  style={styles.textInput}
                  secureTextEntry
                  onChangeText={(e) => setPassword(e)}
                />

                <TextInput
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  secureTextEntry
                  style={styles.textInput}
                  onChangeText={(e) => setRepeatPassword(e)}
                />
                <Row>
                  <Col customStyles={{ flex: 1 }}>
                    <View style={{ margin: 12 }}>
                      <Button mode="contained" onPress={() => submit()}>
                        Submit
                      </Button>
                    </View>
                  </Col>
                </Row>
              </Card>
            </View>
          </ImageBackground>
        </Col>
      </View>
    </View>
  );
}

export default ResetPassword;

const styles = StyleSheet.create({
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: 500,
    height: 700,
  },
  logo: {
    height: 200,
    width: 200,
    marginTop: 10,
  },
  header: {
    marginTop: 15,
    fontSize: 22,
  },
  textInput: {
    margin: 12,
  },
});
