export const Constants = {
  BASE_URL: 'https://api.kanzubanking.com/fineract-provider/api/v1/',
  MOMO_BASE_URL: 'https://momoapi.kanzubanking.com',
  USSD_BASE_URL: 'https://ussd.kanzubanking.com',
  LOADING_TEXT: 'Loading...',

  AIRTEL_CHARGES:[{"max":499,"min":0,"amount":5},
    {"max":2500,"min":500,"amount":180},
    {"max":5000,"min":2501,"amount":450},
    {"max":15000,"min":5001,"amount":500},
    {"max":30000,"min":15001,"amount":550},
    {"max":45000,"min":30001,"amount":750},
    {"max":60000,"min":45001,"amount":950},
    {"max":125000,"min":60001,"amount":2050},
    {"max":250000,"min":125001,"amount":3450},
    {"max":500000,"min":250001,"amount":5200},
    {"max":1000000,"min":500001,"amount":5150},
    {"max":2000000,"min":1000001,"amount":3750},
    {"max":4000000,"min":2000001,"amount":5250},
    {"max":5000000,"min":4000001,"amount":6750}
  ],

  MTN_CHARGES:[
    {"max":2500,"min":500,"amount":200},
    {"max":5000,"min":2501,"amount":400},
    {"max":15000,"min":5001,"amount":550},
    {"max":30000,"min":15001,"amount":750},
    {"max":45000,"min":30001,"amount":950},
    {"max":60000,"min":45001,"amount":3700},
    {"max":125000,"min":60001,"amount":3900},
    {"max":250000,"min":125001,"amount":4100},
    {"max":500000,"min":250001,"amount":4300},
    {"max":1000000,"min":500001,"amount":6500},
    {"max":7000000,"min":1000001,"amount":9000},
  ],

  AIRTEL_PERCENTAGE: 0.02

  // airtel removes  the charge plus 2% of the amount being paid

};

export default { Constants };
