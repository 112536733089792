import React from 'react';
import ValidateRegisterWeb from './ValidateRegisterWeb';
import ValidateRegisterMobile from './ValidateRegisterMobile';

export default function ValidateRegister({ navigation }: any) {
  return (
    <>
      <ValidateRegisterWeb navigation={navigation} />

      <ValidateRegisterMobile navigation={navigation} />
    </>
  );
}
